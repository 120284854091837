export enum ShippingType {
  DELIVER = 'Deliver',
  COLLECT = 'Collect'
}

export enum OrderRequestStatus {
  VALIDATED = 'validated',
  NOT_VALIDATED = 'notValidated',
  FAILED = 'failed',
  COMPLETED = 'completed',
  VALIDATION_FAILED = 'validationFailed'
}

export enum DeactivationReason {
  FLAGGED = 'Flagged',
  DUPLICATE = 'Duplicate'
}

export enum MaterialFormStatus {
  DEFAULT = 'Default',
  ACTIVE = 'Active',
  DISABLED = 'Disabled'
}

export enum RfqEnabledCountries {
  GB = 'GB',
  CA = 'CA',
  US = 'US',
  BE = 'BE',
  NL = 'NL'
}
