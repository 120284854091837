import {oneWeekDates} from '@hconnect/uikit'
import moment from 'moment'
import queryString from 'query-string'
import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {invalidateHubQuery} from '../../../common/react-query/QueryProvider'
import {useFeaturesState} from '../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'

export const useHandleClose = (
  orders: {payload: {deliveryDate: string}}[],
  setConfirmationStatusScreen: (show: boolean) => void,
  reset: () => void,
  customerId?: string
) => {
  const history = useHistory<{activePopup?: string}>()
  const {getFeature} = useFeaturesState()
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')

  const invalidateData = () => {
    resetOrderIntake()
    invalidateHubQuery('orderRequestsOptions', {
      customerId,
      applyMaterialEnteredDescription,
      applyContractItemDescription
    })
    invalidateHubQuery('failedOrderRequests', customerId)
    setConfirmationStatusScreen(false)
    reset()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => invalidateData, [])

  const {resetOrderIntake} = useBulkCementOrderIntake()

  const handleClose = () => {
    invalidateData()

    const oneWeek = oneWeekDates()
    const maxDeliveryDate = orders.reduce((maxDate, order) => {
      const deliveryDate = moment(order.payload.deliveryDate)
      return deliveryDate > maxDate ? deliveryDate : maxDate
    }, oneWeek[1])

    history.push({
      pathname: '/orders',
      search: queryString.stringify({
        dateFrom: oneWeek[0].toISOString(),
        dateTo: maxDeliveryDate.endOf('day').toISOString()
      })
    })
  }

  return handleClose
}
