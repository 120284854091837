/* eslint-disable complexity */
import {Typography, TimeRangePicker} from '@hconnect/uikit'
import {searchInContent, SelectDropdown} from '@hconnect/uikit/src/lib2'
import {Box, Card, CardContent} from '@material-ui/core'
import {Delete, InfoOutlined as Info} from '@material-ui/icons'
import {Grid, Stack} from '@mui/material'
import {isEmpty, partial} from 'lodash'
import React, {useEffect, useRef} from 'react'
import {Controller, ControllerRenderProps, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {InfoTextWithIcon} from '../../../Molecules/InfoTextWithIcon'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {
  mergeDateRanges,
  selectMaterialOption,
  getMaterialFormStyleByStatus,
  getTodaysCutOffTime
} from '../../../Organisms/OrderIntake/utils'
import {QuantityUomToSymbolComponent} from '../../../Pages/Order/Order.components'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {NumericInputWithControls} from '../../components/Forms/NumericInputWithControls'
import {FullWidthStack} from '../../components/FullWidthStack'
import {
  BottomStack,
  BottomStackDivider,
  CloseButton,
  CloseButtonWrapper,
  TitleStack
} from '../../components/materialFormComponents'
import {DropdownItem, MaterialDropdownItem} from '../../components/SiteDropdown'
import {TimeLeftToOrder} from '../../components/TimeLeftToOrder'
import {BUSINESS_HOURS_CUT_OFF_TIME_THRESHOLD} from '../../declarations/constants'
import {MaterialFormStatus, ShippingType} from '../../declarations/OrderIntake.enums'
import {
  OrderIntakeMaterialOptionPayload,
  ActiveMaterialDeliveryItem,
  QuantityType
} from '../../declarations/types'
import {useBulkOrderFormChangeHandler} from '../../FormChangeHandlers'
import {trackInvalidMaterialsFactory} from '../../trackEvents'
import {HaulerInfoField} from '../containers/Collect/HaulerInfoField'
import {CustomerReferenceField} from '../containers/CustomerReferenceField'
import {DateFilter} from '../containers/DateFilter'
import {filterDuplicateConsecutiveMaterials, filterFlaggedInvalidMaterials} from '../utils'

const selectIdentity = (o?: OrderIntakeMaterialOptionPayload) => o?.material.materialDescription
const selectPlantName = (o?: OrderIntakeMaterialOptionPayload) => o?.plant.plantName

interface Props {
  defaultMaterialOption: OrderIntakeMaterialOptionPayload
  isCustomerReferenceSame: boolean
  orderIndex: number
  status: MaterialFormStatus
  isSuccess: boolean
  removeCollection: () => void
  onActivate?: (item: ActiveMaterialDeliveryItem) => void
}

export const CollectMaterialLegacyForm: React.FC<Props> = ({
  defaultMaterialOption,
  isCustomerReferenceSame,
  orderIndex,
  status,
  isSuccess,
  removeCollection,
  onActivate
}) => {
  const disabled = status !== MaterialFormStatus.DEFAULT
  const classes = useOrderIntakeStyles()
  const {t} = useTranslation()
  const {getFeature} = useFeaturesState()

  const {control, watch, setValue} = useFormContext<BulkOrderFormValues>()
  const ref = useRef<HTMLDivElement>(null)

  const selectedSite = watch('selectedSite') ?? {}
  const orderRequest = watch(`orders.${orderIndex}`) ?? {}
  const selectedMaterial = watch(`selectedMaterial.${orderIndex}`) ?? defaultMaterialOption

  const deliveryDateRange = mergeDateRanges(
    selectedSite.materials[orderRequest.payload.materialEnteredNumber].map(
      (material) => material.dateRange
    )
  )
  const isTimeForOrderOver = watch('isTimeForOrderOver')
  const shouldFlaggedMaterialsBeFiltered = getFeature('OrderIntakeRemoveIrrelevantMaterials')

  useEffect(() => {
    if (isSuccess && ref && ref.current) ref.current.scrollIntoView({block: 'center'})
  }, [isSuccess])

  const filterByValidity = (valid: boolean) => {
    return Object.values(selectedSite.materials)
      .flatMap((item) => item)
      .filter((item) => (valid ? !item.invalid : item.invalid))
  }

  const validMaterialOptions: OrderIntakeMaterialOptionPayload[] =
    filterDuplicateConsecutiveMaterials(filterByValidity(true))

  const filteredInvalidMaterialOptions = shouldFlaggedMaterialsBeFiltered
    ? filterFlaggedInvalidMaterials(filterByValidity(false))
    : filterByValidity(false)

  const invalidMaterialOptions: OrderIntakeMaterialOptionPayload[] =
    filterDuplicateConsecutiveMaterials(filteredInvalidMaterialOptions)

  const validMaterialOptionsFromMaterialEnteredNumber: OrderIntakeMaterialOptionPayload[] =
    Object.values(selectedSite.materials[orderRequest.payload.materialEnteredNumber])
      .flatMap((item) => item)
      .filter((item) => !item.invalid)

  const materialOption = selectMaterialOption(
    orderRequest.payload.deliveryDate,
    validMaterialOptionsFromMaterialEnteredNumber,
    selectedMaterial
  )

  const businessHours = defaultMaterialOption.businessHours
  const cutOffTimeThreshold =
    businessHours?.cutOffTimeThreshold ?? BUSINESS_HOURS_CUT_OFF_TIME_THRESHOLD

  const orderChangeHandlers = useBulkOrderFormChangeHandler()

  const businessDays = defaultMaterialOption.dateRange?.businessDays
    ? defaultMaterialOption.dateRange?.businessDays
    : []
  const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
  const cutOffTimeStamp = todaysCutOffTime?.timestamp

  const timeToLeftProps = {
    deliveryDateRange,
    cutOffTime: cutOffTimeStamp,
    cutOffTimeThreshold,
    onTimerChange: (isTimeOver: boolean) => setValue('isTimeForOrderOver', isTimeOver),
    deliveryDate: orderRequest.payload.deliveryDate,
    timeZone: defaultMaterialOption.businessHours?.timeZone
  }

  const onTimeRangePickerClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    !disabled && onActivate && onActivate('timePicker')
  }

  const handleDateFilterComponentClick = () => {
    onActivate && onActivate('dateFilter')
  }

  const handleHaulerInfoComponentClick = () => {
    onActivate && onActivate('haulerInfo')
  }

  const handleCustomerReferenceInfoComponentClick = () => {
    onActivate && onActivate('poNumber')
  }

  const trackInvalidMaterials = trackInvalidMaterialsFactory(invalidMaterialOptions, {
    customerNumber: defaultMaterialOption.customerNumber,
    siteNumber: selectedSite.shippingAddress.siteNumber,
    shippingType: ShippingType.COLLECT
  })

  const capacity = defaultMaterialOption.truckCapacity
  const {minCapacity, maxCapacity, capacityUom} = capacity
  const unitOfMeasure =
    defaultMaterialOption.material.quantityType !== QuantityType.Load ? capacityUom : 'Loads'
  const decTooltipTitle = t('orderIntake.minTruckCapacity', {
    capacity: minCapacity,
    capacityUom: unitOfMeasure
  })
  const incTooltipTitle = t('orderIntake.maxTruckCapacity', {
    capacity: maxCapacity,
    capacityUom: unitOfMeasure
  })
  const getTooltip = (
    testId: 'dec' | 'inc',
    field: ControllerRenderProps<BulkOrderFormValues, `orders.${number}.payload.capacity.quantity`>
  ) =>
    (testId === 'dec' ? minCapacity : maxCapacity) === field.value
      ? ({
          title: testId === 'dec' ? decTooltipTitle : incTooltipTitle,
          placement: 'bottom'
        } as const)
      : undefined

  return (
    <Card
      variant="outlined"
      data-test-id={`order-request-${orderIndex + 1}`}
      className={getMaterialFormStyleByStatus(status, isSuccess, classes)}
      ref={ref}
    >
      <CardContent component={Stack} spacing={{xs: 1, sm: 2}}>
        <CloseButtonWrapper>
          <TitleStack>
            <Stack direction="row" alignItems="center" paddingRight={4}>
              <Typography customVariant="formLabel" color="secondary">
                {`${orderIndex + 1}. ${t('orderIntake.deliveryFor')}`}
              </Typography>
              <Box onMouseDown={handleDateFilterComponentClick}>
                <DateFilter
                  open={false}
                  isDateChange={orderRequest.isDateChange}
                  businessHours={
                    selectedSite.materials[orderRequest.payload.materialEnteredNumber][0]
                      .businessHours
                  }
                  value={watch(`orders.${orderIndex}.payload.deliveryDate`)}
                  labelClass={classes.deliveryDateLabel}
                  iconClass={classes.deliveryDateIcon}
                  {...(deliveryDateRange
                    ? {
                        dateRange: {
                          from: deliveryDateRange.from,
                          to: deliveryDateRange.to,
                          exceptions: deliveryDateRange.exceptions,
                          fromAfterCutoff: deliveryDateRange.fromAfterCutoff,
                          toAfterCutoff: deliveryDateRange.toAfterCutoff
                        }
                      }
                    : null)}
                />
              </Box>
            </Stack>
            <TimeLeftToOrder {...timeToLeftProps} />
          </TitleStack>
          {orderIndex !== 0 ? (
            <CloseButton
              data-test-id={`delete-card-button-${orderIndex}`}
              onClick={removeCollection}
            >
              <Delete color="secondary" />
            </CloseButton>
          ) : null}
        </CloseButtonWrapper>
        <div>
          <Grid container spacing={{xs: 1, sm: 2}}>
            {!isTimeForOrderOver ? (
              <>
                <Grid item xs={12} md={4}>
                  {validMaterialOptions.length === 1 && isEmpty(invalidMaterialOptions) ? (
                    <DropdownItem
                      title={validMaterialOptions[0].material.materialDescription}
                      description={`${t('orderIntake.plant')} ${
                        validMaterialOptions[0].plant.plantName
                      }`.toUpperCase()}
                      ariaLabel={`Material filter option ${validMaterialOptions[0].material.materialEnteredNumber}`}
                      dataTestId={`material-filter-option-${validMaterialOptions[0].material.materialEnteredNumber}`}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`orders.${orderIndex}.payload.materialEnteredNumber`}
                      render={() => (
                        <SelectDropdown<OrderIntakeMaterialOptionPayload>
                          label={t('orderIntake.material')}
                          isSearchable
                          data-test-id="order-intake-material-dropdown"
                          error={watch(`orders.${orderIndex}.isMaterialHighlighted`)}
                          options={[...validMaterialOptions, ...invalidMaterialOptions]}
                          value={materialOption}
                          description={`${t('orderIntake.plant')} ${materialOption?.plant
                            .plantName}`.toUpperCase()}
                          className={classes.dropdownContainer}
                          innerButtonClassName={classes.dropdownButton}
                          onPopupToggle={trackInvalidMaterials}
                          onChange={(o) => o && orderChangeHandlers.onMaterialChange(orderIndex, o)}
                          filterOption={(t, o) =>
                            searchInContent(t, selectIdentity(o)) ||
                            searchInContent(t, selectPlantName(o))
                          }
                          renderValue={selectIdentity}
                        >
                          {(o, prop) =>
                            o.map((i, idx) => (
                              <MaterialDropdownItem
                                data-test-id={`dropdown-filter-option-${idx}`}
                                selected={selectIdentity(i) === selectIdentity(materialOption)}
                                key={selectIdentity(i)}
                                title={selectIdentity(i) ?? ''}
                                disabled={i.invalid}
                                onClick={() => prop?.onChange(i)}
                                hasDivider={idx + 1 < o.length}
                                topMessage={
                                  idx === validMaterialOptions.length
                                    ? t('orderIntake.orderMaterialByPhone')
                                    : undefined
                                }
                                description={`${t('orderIntake.plant')} ${
                                  i.plant.plantName
                                }`.toUpperCase()}
                                ariaLabel={`Material filter option ${i.material.materialEnteredNumber}`}
                              />
                            ))
                          }
                        </SelectDropdown>
                      )}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  component={Stack}
                  spacing={1}
                  justifyContent={{xs: 'left', sm: 'space-around'}}
                >
                  {defaultMaterialOption.material.quantityType !== QuantityType.Load ? (
                    <Controller
                      control={control}
                      name={`orders.${orderIndex}.payload.capacity.quantity`}
                      rules={{
                        min: capacity.minCapacity,
                        max: capacity.maxCapacity
                      }}
                      render={({field}) => {
                        const isSameValue =
                          minCapacity === maxCapacity && maxCapacity === field.value
                        return (
                          <Stack spacing={1} width="100%">
                            <NumericInputWithControls
                              data-test-id="truck-tonnage-amount"
                              name={field.name}
                              value={field.value}
                              ref={field.ref}
                              increment={capacity.quantityChangeIncrement ?? 1}
                              dontRoundValueToIncrement={!capacity.isRoundToChangeIncrement}
                              decimalsNumber={capacity.numberOfDecimals ?? 0}
                              title={
                                <>
                                  {t('orderIntake.quantity')}{' '}
                                  <QuantityUomToSymbolComponent uom={capacityUom} />
                                </>
                              }
                              disabled={isSameValue}
                              decrementTooltipProps={getTooltip('dec', field)}
                              incrementTooltipProps={getTooltip('inc', field)}
                              min={minCapacity}
                              max={maxCapacity}
                              onChange={partial(orderChangeHandlers.onQuantityChange, orderIndex)}
                            ></NumericInputWithControls>
                            {isSameValue && (
                              <Typography variant="caption" color="secondary">
                                {t('orderIntake.callToOrderLess')}
                              </Typography>
                            )}
                          </Stack>
                        )
                      }}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`orders.${orderIndex}.payload.capacity.quantity`}
                      rules={{
                        min: capacity.minCapacity ? capacity.minCapacity : 1,
                        max: capacity.maxCapacity ? capacity.maxCapacity : 1
                      }}
                      render={({field}) => (
                        <FullWidthStack direction="column" spacing={1}>
                          <NumericInputWithControls
                            data-test-id="order-request-change-load"
                            name={field.name}
                            value={field.value}
                            ref={field.ref}
                            title={t('orderIntake.changeLoad')}
                            decrementTooltipProps={getTooltip('dec', field)}
                            incrementTooltipProps={getTooltip('inc', field)}
                            min={capacity.minCapacity ? capacity.minCapacity : 1}
                            max={capacity.maxCapacity ? capacity.maxCapacity : 1}
                            onChange={partial(orderChangeHandlers.onQuantityChange, orderIndex)}
                          />
                          <Typography variant="caption">
                            <Info style={{height: '11px'}} />
                            {t('required')}
                          </Typography>
                        </FullWidthStack>
                      )}
                    />
                  )}
                </Grid>
                {getFeature('OrderIntakeCollectTimePicker') && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    onClick={onTimeRangePickerClick}
                    data-test-id="time-picker"
                  >
                    <TimeRangePicker
                      startTimeLabel={t('orderIntake.earliest')}
                      endTimeLabel={t('orderIntake.latest')}
                      startTime={orderRequest.payload.deliveryTime.earliest}
                      endTime={orderRequest.payload.deliveryTime.latest}
                      isSingleColumn={
                        orderRequest.payload.deliveryTime.earliest ===
                        orderRequest.payload.deliveryTime.latest
                      }
                      singleColumnLabel={t('orderIntake.timePicker.specificTime')}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Box ml={2}>
                <InfoTextWithIcon
                  text={t('orderIntake.latestDeliveryTimePassed')}
                  data-test-id="order-intake-time-for-order-over"
                />
              </Box>
            )}
          </Grid>
        </div>
        {!isTimeForOrderOver && (
          <BottomStack
            direction={{sx: 'column', sm: 'row'}}
            divider={<BottomStackDivider />}
            spacing={2}
          >
            <Features name="OrderIntakeHaulierInformation">
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`orders.${orderIndex}.payload.haulerInfo`}
                  render={({field}) => {
                    return (
                      <HaulerInfoField
                        data-test-id="hauler-info"
                        haulerInfo={field.value}
                        handleEdit={handleHaulerInfoComponentClick}
                      />
                    )
                  }}
                />
              </Grid>
            </Features>
            {!isCustomerReferenceSame && getFeature('OrderIntakeCustomerReferenceField') ? (
              <Grid item sm={12} md={6}>
                <Box mt={1} style={{height: '100%'}}>
                  <CustomerReferenceField
                    customerReference={orderRequest.payload.customerReference}
                    handleEdit={handleCustomerReferenceInfoComponentClick}
                  />
                </Box>
              </Grid>
            ) : null}
          </BottomStack>
        )}
      </CardContent>
    </Card>
  )
}
