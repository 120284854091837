import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'

interface ShippingLegacyAddressProps {
  name: string
  street: string
  city: string
  postalCode: string
  headline?: string
}

export const ShippingLegacyAddress: React.FC<ShippingLegacyAddressProps> = ({
  name,
  street,
  city,
  postalCode,
  headline
}) => {
  return (
    <Box display="flex" flexDirection="column" maxWidth="100%" data-test-id="shipping-address">
      {headline ? <Typography variant="h4">{headline}</Typography> : null}
      <Typography variant="body2" breakWords>
        {name}
      </Typography>
      <Typography variant="body2" breakWords>
        {street}
      </Typography>
      <Typography variant="body2" breakWords>
        {city}, {postalCode}
      </Typography>
    </Box>
  )
}
