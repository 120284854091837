import {first} from 'lodash'
import {useSelector} from 'react-redux'

import {selectSelectedCustomerFilter} from '../../Organisms/Customers'
import {useFeaturesState} from '../../Organisms/Features'
import {useBusinessLinesByCustomerId} from '../../Roles'
import {AppState} from '../../Root.store'
import {getUserProfile} from '../../UserProfile/UserProfile.selectors'

export const useOrderIntakeData = () => {
  const customerId = useSelector(
    (state: AppState) => selectSelectedCustomerFilter(state)?.customerId
  )
  const userId = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.user_id ?? undefined
  )
  const userFullName = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.name ?? undefined
  )
  const userEmail = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.eMail ?? undefined
  )
  const userProfileCountry = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.country ?? undefined
  )
  const userPrimaryOrganisationalUnit = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.primaryOrganisationalUnit ?? undefined
  )
  const userCompany = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.companyName ?? undefined
  )
  const userMarketId = useSelector(
    (state: AppState) => getUserProfile(state).userProfile?.marketId ?? undefined
  )
  const {data: businessLines} = useBusinessLinesByCustomerId(customerId)

  const {getFeature} = useFeaturesState()

  return {
    customerId,
    userId,
    userPrimaryOrganisationalUnit,
    userFullName,
    userEmail,
    userProfileCountry,
    userCompany,
    userMarketId,
    businessLine: first(businessLines),
    features: {
      isOrderIntakeBackupPhoneValidation: getFeature('OrderIntakeBackupPhoneValidation'),
      orderIntakeCustomerReferenceField: getFeature('OrderIntakeCustomerReferenceField'),
      hasSiteContactNameAndPhone: getFeature('OrderIntakeSiteContactNameAndPhone'),
      isMaterialPersistency: getFeature('OrderIntakeMaterialPersistency'),
      isContactPersistency: getFeature('OrderIntakeContactPersistency')
    }
  }
}
