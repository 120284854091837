import {isBefore, parseISO, sub} from 'date-fns'

import {
  ActiveMaterialDeliveryItem,
  BusinessHours,
  DeliveryTime
} from '../../../../OrderIntake/declarations/types'
import {getTodayTzAwareDateFromTime, isDateToday, parseTime} from '../../../../util/time'

import {MaterialEditorTabs} from './types'

export const getCurrentTab = (activeItem?: ActiveMaterialDeliveryItem) => {
  if (activeItem === 'details') return MaterialEditorTabs.Details
  if (activeItem === 'dateFilter') return MaterialEditorTabs.Date
  if (activeItem === 'haulerInfo') return MaterialEditorTabs.Haulier
  if (activeItem === 'poNumber') return MaterialEditorTabs.Po
  return MaterialEditorTabs.Details
}

export const isCutOffTime = (dateIso: string, cutoffDateTime: Date, timezone?: string): boolean => {
  if (!isDateToday(dateIso, timezone)) return false
  return !isBefore(Date.now(), cutoffDateTime)
}

export const calculateCutoffTime = (
  latestPossible: string,
  cutOffTimeThreshold: string,
  timezone?: string
) => {
  const todayLatest = getTodayTzAwareDateFromTime(latestPossible, timezone)
  const cutOffDateTime = sub(todayLatest, parseTime(cutOffTimeThreshold))
  return cutOffDateTime
}

export const isDeliveryTimeEmpty = (deliveryTime: DeliveryTime) => {
  return !deliveryTime.earliest || !deliveryTime.latest
}

// TODO: finish validation and remove eslint disable
export const validateDeliveryDates = (
  deliveryTime: DeliveryTime,
  deliveryDateIso: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  businessHours: BusinessHours
) => {
  if (isDeliveryTimeEmpty(deliveryTime)) return 'noValueSelected'
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deliveryDate = parseISO(deliveryDateIso)

  return undefined
}
