import {styled} from '@material-ui/core'
import React from 'react'

interface MarkerProps {
  lat: number
  lng: number
  text: string
}

const MarkerWrapper = styled('div')({
  position: 'absolute',
  width: '40px',
  height: '40px',
  marginLeft: '-20px',
  marginTop: '-40px',
  cursor: 'pointer'
})

const Marker: React.FC<MarkerProps> = () => {
  return (
    <MarkerWrapper>
      {
        <svg
          height="40"
          viewBox="0 0 24 24"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
          fill="#D32F2F"
        >
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
        </svg>
      }
    </MarkerWrapper>
  )
}

export default Marker
