import {PhoneNumberTextField, ActionButton} from '@hconnect/uikit'
import {Box, InputAdornment, Tooltip} from '@material-ui/core'
import {AddCircle, Close} from '@material-ui/icons'
import {E164Number} from 'libphonenumber-js'
import {isEmpty} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface BackupPhoneFieldProps {
  label: string
  addButtonLabel: string
  phoneNumberRef: React.Ref<HTMLInputElement>
  name: string
  value: string | E164Number | undefined
  userProfileCountry: string | null | undefined
  onChange: (phoneNumber: string, isValid: boolean) => void
  setInputVisible: (visible: boolean) => void
  hideCleanButton?: boolean
  required?: boolean
  hasError?: boolean
  inputVisible: boolean
  testId?: string
}

export const BackupPhoneField: React.FC<BackupPhoneFieldProps> = ({
  label,
  addButtonLabel,
  phoneNumberRef,
  name,
  value,
  userProfileCountry,
  onChange,
  inputVisible,
  setInputVisible,
  hideCleanButton,
  required = false,
  hasError = false,
  testId
}) => {
  const {t} = useTranslation()
  const toolTip = t('orderIntake.alternativePhoneRemoveTooltip')

  if (!inputVisible) {
    return (
      <Box
        data-test-id="backup-phone-add-button"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        minWidth="50%"
        height="100%"
        pb={3}
      >
        <Box p={0.25} />
        <ActionButton onClick={() => setInputVisible(true)} icon={<AddCircle color="primary" />}>
          {addButtonLabel}
        </ActionButton>
      </Box>
    )
  }
  return (
    <PhoneNumberTextField
      fullWidth
      bordered
      id={name}
      ref={phoneNumberRef}
      name={name}
      data-test-id={testId ? testId : 'order-intake-backupPhone-input'}
      value={value}
      onPhoneChange={onChange}
      options={{
        initialCountry: value ? '' : userProfileCountry ?? '',
        nationalMode: false,
        formatOnDisplay: false
      }}
      required={required}
      label={label}
      error={hasError}
      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      InputLabelProps={{shrink: !isEmpty(value)}}
      InputProps={
        hideCleanButton
          ? undefined
          : {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={toolTip} placement="bottom" arrow>
                    <Close
                      style={{cursor: 'pointer'}}
                      color="secondary"
                      onClick={() => setInputVisible(false)}
                    />
                  </Tooltip>
                </InputAdornment>
              )
            }
      }
    />
  )
}
