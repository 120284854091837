import {Typography, dateFormatter, timeFormatter, Grid, MdRenderer} from '@hconnect/uikit'
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  makeStyles,
  Theme
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import {Stack} from '@mui/material'
import React, {ReactElement, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import confirmationImage from '../../Assets/confirmationPage/confirmation.svg'
import emailImage from '../../Assets/confirmationPage/email.svg'
import useIntersectionObserver from '../../Hooks/OrderIntake/useIntersectionObserver'
import {ShippingType} from '../../OrderIntake/declarations/OrderIntake.enums'
import {useFeaturesState} from '../../Organisms/Features'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(4)}px`
    },
    padding: `${theme.spacing(2)}px`
  },
  confirmationBox: {
    background: '#FBFFF9',
    border: '1px solid #BBDEB3',
    padding: '40px'
  },
  confirmationBoxText: {
    maxWidth: '260px',
    marginTop: `${theme.spacing(2)}px`
  },
  smallConfirmationBox: {
    background: '#FBFFF9',
    border: '1px solid #BBDEB3',
    padding: `${theme.spacing(2)}px`
  },
  smallConfirmationBoxText: {
    color: '#0F9E53'
  },
  confirmationSummaryItem: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`
  },
  additionalInfo: {},
  infoStatus: {
    position: 'absolute',
    top: '20px',
    left: '20px'
  },
  emailSubjectLogo: {
    height: '35px',
    width: '35px',
    backgroundColor: '#11507B',
    color: 'white',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  emailCounter: {
    backgroundColor: 'orange',
    position: 'absolute',
    color: 'white',
    top: '4px',
    right: '6px',
    fontSize: '12px',
    width: '18px',
    height: '18px',
    borderRadius: '10px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  closeButtonGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end'
  },
  closeButtonBox: {
    display: 'flex',
    justifyContent: 'end'
  },
  closeButton: {
    [theme.breakpoints.up('sm')]: {
      right: `${theme.spacing(4)}px`
    },
    right: `${theme.spacing(2)}px`
  }
}))

interface Props {
  summaryItems: ReactElement[] | null
  date: string | null | undefined
  onClose: () => void
  hideTitle?: boolean
  userFullName: string
  mainPhone: string
  backupPhone?: string
  shippingType: ShippingType
  itemsNumber: number
}

const ConfirmationLegacyScreen: React.FC<Props> = ({
  summaryItems,
  date,
  onClose,
  hideTitle,
  userFullName,
  mainPhone,
  shippingType,
  itemsNumber,
  backupPhone = ''
}) => {
  const classes = useStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {getFeature} = useFeaturesState()
  const orderIntakeCollectSendingQR = getFeature('OrderIntakeCollectSendingQR')
  const isSlotManagementEnabled = getFeature(
    shippingType === ShippingType.DELIVER ? 'OrderIntakeSlotsDeliver' : 'OrderIntakeSlotsCollect'
  )
  const bannerRef = useRef<HTMLDivElement>(null)
  const intersectionObserver = useIntersectionObserver(bannerRef, {})

  const [showSmallConfirmationBox, setShowSmallConfirmationBox] = useState(false)

  useEffect(() => {
    if (intersectionObserver) {
      const isBannerVisible = !!intersectionObserver?.isIntersecting
      if (!isBannerVisible) setShowSmallConfirmationBox(true)
    }
  }, [intersectionObserver])

  return (
    <Grid
      container
      data-test-id="generic-confirmation-screen"
      className={classes.container}
      spacing={2}
    >
      <Grid item xs={12} md={8}>
        <Stack spacing={2}>
          {!hideTitle && (
            <Box>
              <Collapse in={!showSmallConfirmationBox}>
                <div ref={bannerRef}>
                  <Grid container className={classes.confirmationBox}>
                    <Grid item xs={12} md={4}>
                      <img
                        src={confirmationImage}
                        data-test-id="order-intake-no-valid-ship-to-image"
                        style={{textAlign: 'center', maxWidth: 350}}
                        alt={t('orderIntake.confirmationScreen.receiveRequestSuccessfully')}
                      />
                    </Grid>
                    <Grid item xs={12} md={1} />
                    <Grid item xs={12} md={7}>
                      <Box className={classes.confirmationBoxText}>
                        <Typography variant="h3" color="textPrimary">
                          {t('orderIntake.confirmationScreen.receiveRequestSuccessfully')}
                        </Typography>
                        <Typography variant="body2" color="textPrimary" style={{marginTop: '15px'}}>
                          {t('orderIntake.confirmationScreen.receiveRequestSuccessfullyText')}
                        </Typography>
                        {shippingType === ShippingType.COLLECT && orderIntakeCollectSendingQR && (
                          <Typography variant="body2" color="textPrimary">
                            {t(
                              'orderIntake.confirmationScreen.receiveRequestSuccessfullyTextCollect'
                            )}
                          </Typography>
                        )}
                        {isSlotManagementEnabled ? (
                          <MdRenderer
                            body={t('orderIntake.confirmationScreen.confirmationLink')}
                            paragraphVariant="body2"
                          />
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Collapse>

              {showSmallConfirmationBox && (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  className={classes.smallConfirmationBox}
                >
                  <CheckIcon style={{color: '#0F9E53'}} />
                  <Typography
                    variant="h4"
                    color="secondary"
                    className={classes.smallConfirmationBoxText}
                  >
                    {t('orderIntake.confirmationScreen.receiveRequestSuccessfully')}
                  </Typography>
                </Stack>
              )}
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Typography variant="h3">{t('orderIntake.confirmationScreen.reviewOrder')}</Typography>
          </Box>

          {summaryItems}
        </Stack>
      </Grid>

      <Grid item xs={12} md={4} className={classes.additionalInfo}>
        <Box>
          {shippingType === ShippingType.COLLECT && orderIntakeCollectSendingQR && (
            <Box marginBottom={4} marginTop={2} padding={2}>
              <Typography variant="h4">{t('orderIntake.qrCodeSent')}</Typography>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={2}
              >
                <Typography variant="body2">
                  {t('orderIntake.confirmationScreen.qrCodeSentText', {count: itemsNumber})}
                </Typography>
                <Box position="relative">
                  <img
                    src={emailImage}
                    style={{textAlign: 'center', maxWidth: 50}}
                    alt={t('orderIntake.confirmationScreen.qrCodeSentText', {count: itemsNumber})}
                  />
                  <Box className={classes.emailCounter}>{itemsNumber}</Box>
                </Box>
              </Box>

              <Divider />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridColumnGap={15}
                marginTop={2}
              >
                <Box>
                  <Box className={classes.emailSubjectLogo}>
                    <Typography variant="body2">HC</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="body2" color="secondary">
                    {t('orderIntake.confirmationScreen.subject')}
                  </Typography>
                  <Typography variant="h4">
                    {t('orderIntake.confirmationScreen.qrCodeForOrder')}
                  </Typography>
                  <Box display="flex">
                    <Typography variant="body2" color="secondary">
                      {t('orderIntake.confirmationScreen.from')}
                    </Typography>
                    <Typography variant="body2">
                      {t('orderIntake.confirmationScreen.fromValue')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box mb={2}>
            <Typography variant="h3" color="textPrimary">
              {t('orderIntake.confirmationScreen.additionalInfo')}
            </Typography>
          </Box>
          <Card variant="outlined">
            <CardContent>
              <Box>
                <Typography variant="body2" color="secondary">
                  {t('orderIntake.confirmationScreen.placedOn')}
                </Typography>
                {date ? (
                  <Typography variant="body2" color="textPrimary">
                    {t('orderIntake.confirmationScreen.placedOnValue', {
                      date: dateFormatter(date, language),
                      time: timeFormatter(date, language),
                      interpolation: {escapeValue: false}
                    })}
                  </Typography>
                ) : null}
              </Box>

              <Box>
                <Typography variant="body2" color="secondary">
                  {t('orderIntake.confirmationScreen.by')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {userFullName}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {t('orderIntake.mainPhone')}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {mainPhone}
                </Typography>
                {backupPhone && mainPhone !== backupPhone && (
                  <>
                    <Typography variant="body2" color="secondary">
                      {t('orderIntake.backupPhone')}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {backupPhone}
                    </Typography>
                  </>
                )}
              </Box>

              <Box>
                <Typography variant="body2" color="secondary">
                  {t('orderIntake.confirmationScreen.cancellation')}
                </Typography>
                <Typography color="textPrimary">
                  {t('orderIntake.changesOrCancellationsDisclaimer')}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.closeButtonGrid}>
        <Box className={classes.closeButtonBox}>
          <Button
            data-test-id="order-intake-confirmation-screen-close-text-button"
            color="secondary"
            className={classes.closeButton}
            onClick={() => {
              onClose()
            }}
          >
            {t('orderIntake.close')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export {ConfirmationLegacyScreen}
