import {Typography, dateFormatter, timeFormatter, Grid, ActionButton} from '@hconnect/uikit'
import {Box, Dialog, DialogTitle} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderRequestStatus} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {DeliveryTime, QuantityType, ShippingAddress} from '../../../OrderIntake/declarations/types'
import {Features} from '../../../Organisms/Features'
import {OrderLegacyStatus} from '../OrderLegacyStatus'

interface DeliveryOrderSummaryItemProps {
  orderRequestStatus: OrderRequestStatus
  itemIndex: string
  quantity: number
  quantityType: QuantityType
  quantityUom: string
  deliveryDate: string
  deliveryTime: DeliveryTime
  materialDescription: string
  shippingAddress?: ShippingAddress
  driverInstructions?: string
}

export const DeliveryOrderSummaryLegacyItem: React.FC<DeliveryOrderSummaryItemProps> = ({
  orderRequestStatus,
  itemIndex,
  quantity,
  quantityType,
  quantityUom,
  deliveryDate,
  deliveryTime,
  materialDescription,
  shippingAddress,
  driverInstructions
}) => {
  const [showDriverIntructions, setShowDriverIntructions] = React.useState(false)
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const isTimesEqual = deliveryTime.earliest === deliveryTime.latest

  const handleShowDriverIntructions = () => {
    setShowDriverIntructions(true)
  }

  const handleHideDriverIntructions = () => {
    setShowDriverIntructions(false)
  }

  return (
    <Box data-test-id="delivery-order-summary-item">
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="h4">{itemIndex}</Typography>
        <OrderLegacyStatus status={orderRequestStatus} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="caption" color="secondary">
            {t('orderIntake.confirmationScreen.typeDeliveryTime')}
          </Typography>
          <Typography variant="h4">{materialDescription}</Typography>

          {quantityType !== QuantityType.Load && (
            <Typography variant="body2">1 {t('orderIntake.truck')}</Typography>
          )}
          <Typography variant="body2">
            {quantity}
            {quantityType === QuantityType.Load
              ? ` ${t('orderIntake.loads', {count: quantity})} `
              : ` ${quantityUom}`}
          </Typography>
          <Box mt={2} />
          <Typography variant="h4">{dateFormatter(deliveryDate, language)}</Typography>
          <Typography variant="body2">
            {isTimesEqual
              ? timeFormatter(moment(deliveryTime.earliest, 'HH:mm:ss'), language)
              : `${timeFormatter(moment(deliveryTime.earliest, 'HH:mm:ss'), language)} -
                  ${timeFormatter(moment(deliveryTime.latest, 'HH:mm:ss'), language)}`}
          </Typography>
        </Grid>

        {shippingAddress && (
          <Grid item xs={12} md={6}>
            <Typography variant="caption" color="secondary">
              {t('orderIntake.deliveryAddress')}
            </Typography>

            <Box
              display="flex"
              flexDirection="column"
              maxWidth="100%"
              data-test-id="shipping-address"
            >
              <Typography variant="h4" breakWords>
                {shippingAddress.siteName}
              </Typography>
              <Typography variant="h4" breakWords>
                {shippingAddress.street}
              </Typography>
              <Typography variant="h4" breakWords>
                {shippingAddress.city}, {shippingAddress.postalCode}
              </Typography>
            </Box>

            <Features name="DriverInstructions">
              {driverInstructions ? (
                <Box mt={2}>
                  <ActionButton
                    onClick={handleShowDriverIntructions}
                    data-test-id="confirmation-hauler-btn"
                  >
                    {t('orderIntake.confirmationScreen.showDriverInstructions')}
                  </ActionButton>
                  <Dialog onClose={handleHideDriverIntructions} open={showDriverIntructions}>
                    <div data-test-id="confirmation-hauler-dialog">
                      <DialogTitle>{t('orderIntake.driverInstructions')}</DialogTitle>
                      <Box
                        data-test-id="driver-instructions"
                        paddingLeft={4}
                        paddingRight={4}
                        paddingBottom={2}
                      >
                        {driverInstructions}{' '}
                      </Box>
                    </div>
                  </Dialog>
                </Box>
              ) : null}
            </Features>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
