import {OrderRequest, OrderRequestResponse} from './types'

export const RESET_ORDER_INTAKE = 'RESET_ORDER_INTAKE'
export const TOGGLE_RESET_ORDER_INTAKE = 'TOGGLE_RESET_ORDER_INTAKE'
export const SET_DELIVERIES = 'SET_DELIVERIES'
export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const SET_ORDER_SUMMARY = 'SET_ORDER_SUMMARY'

interface ResetOrderIntake {
  type: typeof RESET_ORDER_INTAKE
}

interface ToggleResetOrderIntake {
  type: typeof TOGGLE_RESET_ORDER_INTAKE
}

interface SetDeliveries {
  type: typeof SET_DELIVERIES
  payload: OrderRequest[]
}

interface SetCollections {
  type: typeof SET_COLLECTIONS
  payload: OrderRequest[]
}

interface SetOrderSummary {
  type: typeof SET_ORDER_SUMMARY
  payload: OrderRequestResponse
}

export type OrderIntakeActionTypes =
  | ResetOrderIntake
  | ToggleResetOrderIntake
  | SetDeliveries
  | SetCollections
  | SetOrderSummary
