import {BorderedTextField, NoBorderIconButton} from '@hconnect/uikit'
import {Box, MenuItem, Select, Theme} from '@material-ui/core'
import {Checkbox, FormControlLabel} from '@mui/material'
import {makeStyles} from '@material-ui/core/styles'
import {Delete} from '@material-ui/icons'
import {Stack, Grid, Typography} from '@mui/material'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {NumericInputWithControls} from '../../components/Forms/NumericInputWithControls'
import {RequestForQuoteFormValues} from '../../RequestForQuoteFormValues'
const MAX_CHARS = 500

export const BAGGED_MATERIALS_BUSINESS_LINE_UNITS = ['bags', 'pallets']
export const OTHER_BUSINESS_LINE_UNITS = ['tons', 'loads']

type Props = {
  materialIndex: number // the index of the form
  canRemove: boolean
  isRMC: boolean
  isBaggedMaterial: boolean
  isUSUser: boolean
  removeMaterial: () => void
  onChange: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  unitContainer: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '7px',
    height: '66px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

export const RequestForQuoteMaterialForm: React.FC<Props> = ({
  materialIndex,
  isRMC,
  isBaggedMaterial,
  isUSUser,
  removeMaterial,
  onChange,
  canRemove
}) => {
  const {t} = useTranslation()
  const c = useStyles()
  const methods = useFormContext<RequestForQuoteFormValues>()
  const materialPath = `materials.${materialIndex}` as const
  const material = methods.getValues(materialPath)

  const validateInput = (inputValue: string) => {
    const validInputPattern = /["\t\n]/g

    const found = inputValue && inputValue.match(validInputPattern)
    if (found && found.length > 0) return false

    return true
  }

  const removeInvalidCharacters = (inputValue: string) => {
    return inputValue.replace(/\t+/g, ' ').replace(/\s{2,}/g, ' ')
  }

  const getUnitValuesList = () => {
    return isBaggedMaterial
      ? BAGGED_MATERIALS_BUSINESS_LINE_UNITS.map((unit) => {
          return (
            <MenuItem
              key={`orderIntake.requestForQuote.unitValues.${unit}-${materialIndex}`}
              value={unit}
            >
              {t(`orderIntake.requestForQuote.unitValues.${unit}`)}
            </MenuItem>
          )
        })
      : OTHER_BUSINESS_LINE_UNITS.map((unit) => {
          return (
            <MenuItem
              key={`orderIntake.requestForQuote.unitValues.${unit}-${materialIndex}`}
              value={unit}
            >
              {t(`orderIntake.requestForQuote.unitValues.${unit}`)}
            </MenuItem>
          )
        })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <Controller
          control={methods.control}
          name={`materials.${materialIndex}.name`}
          rules={{
            maxLength: MAX_CHARS,
            required: true,
            validate: (value) => {
              return validateInput(value ?? '')
            }
          }}
          render={({field, fieldState}) => {
            return (
              <Stack spacing={1} width="100%">
                <BorderedTextField
                  data-test-id="request-for-quote-material"
                  label={t('orderIntake.requestForQuote.material')}
                  placeholder={t('orderIntake.requestForQuote.material')}
                  required={true}
                  value={field.value ?? ''}
                  onChange={(e) => {
                    field.onChange(removeInvalidCharacters(e.target.value))
                  }}
                  onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                  inputProps={{maxLength: MAX_CHARS}}
                  error={!!fieldState.error}
                />
                {!!fieldState.error && (
                  <Typography variant="caption" component="span" color="error">
                    {t('orderIntake.requestForQuote.emptyTextOrInvalidCharacters')}
                  </Typography>
                )}
              </Stack>
            )
          }}
        />
        {isRMC && (
          <Box marginLeft={1} marginBottom={1}>
            <Controller
              control={methods.control}
              name={`materials.${materialIndex}.isWithoutAdditives`}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={material.isWithoutAdditives}
                      onChange={field.onChange}
                      data-test-id="request-for-quote-material-additives"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {t('orderIntake.requestForQuote.noAdditives')}
                    </Typography>
                  }
                />
              )}
            />
          </Box>
        )}
      </Grid>

      <Grid item xs={12} md={2}>
        <Controller
          control={methods.control}
          name={`materials.${materialIndex}.quantity.unitOfMeasure`}
          rules={{
            required: true
          }}
          render={({field}) => (
            <Box className={c.unitContainer}>
              <Typography variant="caption">{t('orderIntake.requestForQuote.unit')}</Typography>
              {isRMC && (
                <Typography>
                  {isUSUser
                    ? t('orderIntake.requestForQuote.unitValues.cy')
                    : t('orderIntake.requestForQuote.unitValues.m3')}
                </Typography>
              )}
              {!isRMC && (
                <Select
                  data-test-id="request-for-quote-material-unit"
                  value={material.quantity.unitOfMeasure}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                  disableUnderline
                >
                  {getUnitValuesList()}
                </Select>
              )}
            </Box>
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Controller
          control={methods.control}
          name={`materials.${materialIndex}.quantity.amount`}
          rules={{
            required: true,
            min: 1,
            validate: (value) => {
              return !Number.isNaN(value)
            }
          }}
          render={({field}) => (
            <Stack spacing={1} width="100%" height="66px">
              <NumericInputWithControls
                data-test-id="request-for-quote-material-quantity"
                name={field.name}
                value={field.value}
                ref={field.ref}
                decimalsNumber={1}
                dontRoundValueToIncrement={true}
                allowNaN
                title={t('orderIntake.requestForQuote.quantity')}
                min={1}
                onChange={(e) => {
                  field.onChange(e)
                  onChange()
                }}
              />
            </Stack>
          )}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        {canRemove && (
          <NoBorderIconButton onClick={removeMaterial}>
            <Delete color="secondary" />
          </NoBorderIconButton>
        )}
      </Grid>
    </Grid>
  )
}
