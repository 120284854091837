import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {api} from '../../App.global'
import {BusinessLineType} from '../../common/types'
import {ORDER_REQUESTS_API_PATH} from '../../Organisms/OrderIntake/OrderIntake.action'
import {ShippingType} from '../declarations/OrderIntake.enums'
import {
  OptimizedOption,
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOptions
} from '../declarations/types'

import {mapOptimizedOptionsToLegacy} from './mapper'
import * as options from './options'
import {ErrorCodesForOption} from './options'

export const OptionsKeys = {
  all: () => ['OrderRequests/options'] as const,
  byUserId: (customerId?: string) => [...OptionsKeys.all(), customerId] as const
}

export const useOptionsBase = (
  applyMaterialEnteredDescription: boolean,
  applyContractItemDescription: boolean,
  customerId?: string
) => {
  return useQuery(
    OptionsKeys.byUserId(customerId),
    ({queryKey}) => {
      return api
        .get<OptimizedOption[]>(`${ORDER_REQUESTS_API_PATH}/options`, {
          params: {customerId: queryKey[1]}
        })
        .then((d) =>
          mapOptimizedOptionsToLegacy(
            d.data,
            applyMaterialEnteredDescription,
            applyContractItemDescription
          )
        )
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!customerId
    }
  )
}

const byShippingTypePredicate = (t: ShippingType) => (s: OrderIntakeMaterialOptionPayload) =>
  s.shippingType === t

const shippingTypeFactory = (t?: ShippingType) => {
  const shippingTypePredicate = t ? byShippingTypePredicate(t) : () => true
  return (configuration?: options.Configuration, customerId?: string) => {
    const {data, ...rest} = useOptionsBase(
      configuration?.applyMaterialEnteredDescription ?? false,
      configuration?.applyContractItemDescription ?? false,
      customerId
    )
    const result = useMemo(
      () => (data ? options.fromOptionPayload(data?.filter(shippingTypePredicate)) : undefined),
      [data]
    )
    return {
      ...rest,
      data: result
    }
  }
}

export const useCollectionOptions = shippingTypeFactory(ShippingType.COLLECT)
export const useDeliveryOptions = shippingTypeFactory(ShippingType.DELIVER)
export const useDefaultOptions = shippingTypeFactory()

export type InvalidOrderIntakeOptions = {
  errorCode: ErrorCodesForOption
}
export const isInvalidOrderIntakeOption = function (o: unknown): o is InvalidOrderIntakeOptions {
  if (typeof o !== 'object' && o !== null) return false
  return typeof (o as unknown as ErrorCodesForOption)['errorCode'] === 'string'
}

const getDefaultOrderRequest = (
  shippingType: ShippingType,
  data?: OrderIntakeOptions,
  configuration?: options.Configuration,
  siteNumber?: string
) => {
  try {
    return data
      ? options.createDefaultOrderRequest(shippingType, data, configuration, siteNumber)
      : undefined
  } catch (e: unknown) {
    const message = (e as Error)?.message as ErrorCodesForOption
    if (!message) return
    return {errorCode: message} as InvalidOrderIntakeOptions
  }
}
export const useDefaultCollection = (
  customerId?: string,
  configuration?: options.Configuration,
  siteNumber?: string
) => {
  const {data, ...rest} = useCollectionOptions(configuration, customerId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useMemo(
    () => getDefaultOrderRequest(ShippingType.COLLECT, data, configuration, siteNumber),
    [data, siteNumber]
  )
  return {...rest, data: result}
}
export const useDefaultDelivery = (
  customerId?: string,
  configuration?: options.Configuration,
  siteNumber?: string
) => {
  const {data, ...rest} = useDeliveryOptions(configuration, customerId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useMemo(
    () => getDefaultOrderRequest(ShippingType.DELIVER, data, configuration, siteNumber),
    [data, siteNumber]
  )
  return {...rest, data: result}
}

export const useDefaultBusinessLine = (
  customerId?: string,
  configuration?: options.Configuration
) => {
  const {data, ...rest} = useDefaultOptions(configuration, customerId)
  // There are obsolete customers which contain no options, we need to handle those
  let businessLine: BusinessLineType | undefined
  if (data && Object.values(data).length > 0) {
    businessLine = options.getBusinessLine(data)
  }

  return {...rest, data: businessLine}
}
