import {HCThemeType} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import Button, {ButtonProps} from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BusinessLineType} from '../../common/types'
import {ShippingType} from '../declarations/OrderIntake.enums'

const useStyles = makeStyles((theme: HCThemeType) => {
  return {
    root: {
      width: '100%',
      border: `1px ${theme.palette.primary.main} solid`,
      borderRadius: '4px',
      padding: theme.spacing(2),
      justifyContent: 'left',
      textTransform: 'none',
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(1, 125, 209, 0.02)',
      '&:hover': {
        boxShadow: theme.shadows[2],
        backgroundColor: 'rgba(1, 125, 209, 0.02)'
      }
    }
  }
})

type Props = {
  businessLine?: BusinessLineType
  shippingType?: ShippingType
} & ButtonProps
export const AddAnotherLegacyButton: React.FC<Props> = ({businessLine, shippingType, ...props}) => {
  const {t} = useTranslation()
  const c = useStyles()
  let buttonTxt = 'orderIntake.addDelivery'
  if (businessLine === BusinessLineType.AGG) {
    buttonTxt = 'orderIntake.addMaterial'
  }
  if (shippingType === ShippingType.COLLECT) {
    buttonTxt = 'orderIntake.addCollection'
  }
  return (
    <Button className={c.root} startIcon={<Add />} data-test-id="add-delivery-button" {...props}>
      {t(buttonTxt)}
    </Button>
  )
}
