import {Typography, Grid, MdRenderer} from '@hconnect/uikit'
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Paper,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {Stack} from '@mui/material'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import confirmationImage from '../../Assets/confirmationPage/confirmation.svg'
import confirmationErrorImage from '../../Assets/confirmationPage/confirmationError.svg'
import emailImage from '../../Assets/confirmationPage/email.svg'
import {ShippingType} from '../../OrderIntake/declarations/OrderIntake.enums'
import {useFeaturesState} from '../../Organisms/Features'
import {MaterialSectionHeader} from './VisualComponents'
import {ShippingAddress} from '../declarations/types'
import {ShippingAddress as ShippingAddressComponent} from './ShippingAddress'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(4)}px`
    },
    padding: `${theme.spacing(2)}px`
  },
  confirmationBox: {
    background: '#FBFFF9',
    border: '1px solid #BBDEB3',
    padding: '24px',
    borderRadius: '8px'
  },
  confirmationBoxText: {},
  smallConfirmationBox: {
    background: '#FBFFF9',
    border: '1px solid #BBDEB3',
    padding: `${theme.spacing(2)}px`,
    borderRadius: '8px'
  },
  smallConfirmationBoxText: {
    color: '#0F9E53'
  },
  confirmationSummaryItem: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`
  },
  additionalInfo: {},
  infoStatus: {
    position: 'absolute',
    top: '20px',
    left: '20px'
  },
  emailSubjectLogo: {
    height: '35px',
    width: '35px',
    backgroundColor: '#11507B',
    color: 'white',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  emailCounter: {
    backgroundColor: 'orange',
    position: 'absolute',
    color: 'white',
    top: '4px',
    right: '6px',
    fontSize: '12px',
    width: '18px',
    height: '18px',
    borderRadius: '10px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  closeButtonGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end'
  },
  closeButtonBox: {
    display: 'flex'
  },
  closeButton: {
    [theme.breakpoints.up('sm')]: {
      right: `${theme.spacing(4)}px`
    },
    right: `${theme.spacing(2)}px`
  },
  paperStyle: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 8
    },
    borderRadius: 0,
    padding: 32
  },
  backToListButton: {
    marginTop: '20px',
    width: '100%',
    '& button': {
      width: '100%'
    }
  }
}))

interface Props {
  summaryItems: ReactElement[] | null
  date: string | null | undefined
  onClose: () => void
  showError: boolean
  userFullName: string
  mainPhone: string
  backupPhone?: string
  shippingType: ShippingType
  deliveryAddress: ShippingAddress
  itemsNumber: number
}

const ConfirmationScreen: React.FC<Props> = ({
  summaryItems,
  onClose,
  showError,
  userFullName,
  mainPhone,
  shippingType,
  itemsNumber,
  backupPhone = '',
  deliveryAddress
}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {getFeature} = useFeaturesState()
  const orderIntakeCollectSendingQR = getFeature('OrderIntakeCollectSendingQR')
  const isSlotManagementEnabled = getFeature(
    shippingType === ShippingType.DELIVER ? 'OrderIntakeSlotsDeliver' : 'OrderIntakeSlotsCollect'
  )

  return (
    <Grid container style={{padding: 0}}>
      <Grid item xs={12}>
        <Grid
          container
          className={classes.container}
          justifyContent="space-between"
          data-test-id="order-intake-form-container"
          style={{padding: 0}}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Paper
                style={isMobile ? {borderRadius: 0, background: '#F5F8FB', padding: 16} : {}}
                className={classes.paperStyle}
              >
                {' '}
                <Stack spacing={2}>
                  <Box>
                    <Grid
                      container
                      className={classes.confirmationBox}
                      data-test-id={
                        !showError ? 'order-processing-card' : 'order-processing-card-error'
                      }
                    >
                      <Grid item xs={12} md={10}>
                        <Box className={classes.confirmationBoxText}>
                          <Typography variant="h3" color="textPrimary">
                            {showError
                              ? t('orderIntake.confirmationScreen.receiveRequestFailed')
                              : t('orderIntake.confirmationScreen.receiveRequestSuccessfully')}
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            {showError
                              ? t('orderIntake.confirmationScreen.receiveRequestFailedText')
                              : t('orderIntake.confirmationScreen.receiveRequestSuccessfullyText')}
                          </Typography>
                          {shippingType === ShippingType.COLLECT && orderIntakeCollectSendingQR && (
                            <Typography variant="body2" color="textPrimary">
                              {t(
                                'orderIntake.confirmationScreen.receiveRequestSuccessfullyTextCollect'
                              )}
                            </Typography>
                          )}
                          {isSlotManagementEnabled ? (
                            <MdRenderer
                              body={t('orderIntake.confirmationScreen.confirmationLink')}
                              paragraphVariant="body2"
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <img
                          src={showError ? confirmationErrorImage : confirmationImage}
                          data-test-id="order-intake-no-valid-ship-to-image"
                          style={{float: 'right', maxWidth: 100}}
                          alt={t('orderIntake.confirmationScreen.receiveRequestSuccessfully')}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <MaterialSectionHeader>
                      {t('orderIntake.confirmationScreen.listOfOrderedItems')}
                    </MaterialSectionHeader>
                  </Box>

                  {summaryItems}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} style={{paddingLeft: 0, paddingRight: 16}}>
              <Paper className={classes.paperStyle}>
                <Box>
                  {shippingType === ShippingType.COLLECT && orderIntakeCollectSendingQR && (
                    <Box marginBottom={4} marginTop={2} padding={2}>
                      <Typography variant="h4">{t('orderIntake.qrCodeSent')}</Typography>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginBottom={2}
                      >
                        <Typography variant="body2">
                          {t('orderIntake.confirmationScreen.qrCodeSentText', {count: itemsNumber})}
                        </Typography>
                        <Box position="relative">
                          <img
                            src={emailImage}
                            style={{textAlign: 'center', maxWidth: 50}}
                            alt={t('orderIntake.confirmationScreen.qrCodeSentText', {
                              count: itemsNumber
                            })}
                          />
                          <Box className={classes.emailCounter}>{itemsNumber}</Box>
                        </Box>
                      </Box>

                      <Divider />
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gridColumnGap={15}
                        marginTop={2}
                      >
                        <Box>
                          <Box className={classes.emailSubjectLogo}>
                            <Typography variant="body2">HC</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="secondary">
                            {t('orderIntake.confirmationScreen.subject')}
                          </Typography>
                          <Typography variant="h4">
                            {t('orderIntake.confirmationScreen.qrCodeForOrder')}
                          </Typography>
                          <Box display="flex">
                            <Typography variant="body2" color="secondary">
                              {t('orderIntake.confirmationScreen.from')}
                            </Typography>
                            <Typography variant="body2">
                              {t('orderIntake.confirmationScreen.fromValue')}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <Box mb={2}>
                    <Typography variant="h3" color="textPrimary">
                      {t('orderIntake.confirmationScreen.additionalInfo')}
                    </Typography>
                  </Box>

                  <Box mb={1}>
                    <Typography variant="body2" color="secondary">
                      {shippingType === ShippingType.DELIVER
                        ? t('orderIntake.deliverTo')
                        : t('orderIntake.collectionAddress')}
                    </Typography>
                    <ShippingAddressComponent
                      showInLine={false}
                      name={deliveryAddress.siteName}
                      street={deliveryAddress.street}
                      postalCode={deliveryAddress.postalCode}
                      city={deliveryAddress.city}
                    />
                  </Box>

                  <Box mb={1}>
                    <Typography variant="body2" color="secondary">
                      {t('orderIntake.confirmationScreen.contactPerson')}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {userFullName}
                    </Typography>
                  </Box>

                  <Box mb={1}>
                    <Typography variant="body2" color="secondary">
                      {t('orderIntake.mainPhone')}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {mainPhone}
                    </Typography>
                    {backupPhone && mainPhone !== backupPhone && (
                      <>
                        <Typography variant="body2" color="secondary">
                          {t('orderIntake.backupPhone')}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          {backupPhone}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>

                <Box mb={1}>
                  <Typography variant="body2" color="secondary">
                    {t('orderIntake.confirmationScreen.cancellation')}
                  </Typography>
                  <Typography color="textPrimary">
                    {t('orderIntake.changesOrCancellationsDisclaimer')}
                  </Typography>
                </Box>

                <Button
                  data-test-id="order-intake-confirmation-screen-close-text-button"
                  color="primary"
                  className={classes.backToListButton}
                  onClick={() => {
                    onClose()
                  }}
                >
                  {t('orderIntake.confirmationScreen.backToOrdersList')}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export {ConfirmationScreen}
