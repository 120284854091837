import {NoBorderIconButton} from '@hconnect/uikit'
import {Divider, Hidden, Stack, styled} from '@mui/material'
import React from 'react'

export const CloseButton = styled(NoBorderIconButton)`
  position: absolute;
  right: -7px;
  top: -7px;
`
export const CloseButtonWrapper = styled('div')`
  position: relative;
`

export const TitleStack = styled(Stack)`
  flex-direction: row;
  align-items: center;

  ${({theme}) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const BottomStack = styled(Stack)`
  flex-direction: row;

  ${({theme}) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
export const BottomStackDivider = () => (
  <Hidden smDown>
    <Divider variant="middle" orientation={'vertical'} flexItem />
  </Hidden>
)
