import {useFeaturesState} from '../../Organisms/Features'
import {useRolesByCustomerId} from '../../Roles'

import {evaluateFeatureFlag} from './evaluationFeatureFlags'

export const useIsFeatureEnabledForCurrentCustomer = (
  customerId?: string,
  featureFlagName = 'OrderIntake'
) => {
  const {normalized} = useFeaturesState()

  const featureFlag = normalized[featureFlagName]
  const {data: rolesBySelectedCustomer} = useRolesByCustomerId(customerId)

  const isFeatureEnabledForCurrentCustomer = evaluateFeatureFlag(
    rolesBySelectedCustomer,
    featureFlag?.constraints ?? [],
    customerId ?? ''
  )
  return isFeatureEnabledForCurrentCustomer
}
