import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import {Grid, Paper, Button, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

const OrderIntakeContainer = styled('div')`
  display: flex;
  padding: ${({theme}) => theme.spacing(4)}px ${({theme}) => theme.spacing(6)}px;
  justify-content: space-between;
  padding: ${({theme}) => theme.spacing(4)};
`

const ArrowRightStyled = styled(ArrowRightAlt)`
  margin-right: 5px;
`

type Props = {
  onOpenOrderSummary: () => void
  disabled: boolean
  isMobile: boolean
}

export const ShowOrderSummarySection: React.FC<Props> = ({onOpenOrderSummary, disabled, isMobile}) => {
  const {t} = useTranslation()

  return (
    <Grid item xs={12} style={isMobile ? {paddingTop: 0} : {}}>  
      <Paper style={isMobile ? {borderRadius: 0} : {}}>  
        <OrderIntakeContainer>
          <Button
            data-test-id="order-intake-show-summary"
            variant="contained"
            onClick={onOpenOrderSummary}
            fullWidth
            disabled={disabled}
          >
            <ArrowRightStyled />
            {t('orderIntake.submitAndNext')}
          </Button>
        </OrderIntakeContainer>
      </Paper>
    </Grid>
  )
}
