import {Typography} from '@hconnect/uikit'
import {Box, makeStyles, Theme} from '@material-ui/core'
import {AxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePostOrderRequestCollection} from '../../../common/react-query/hooks/mutations/usePostOrderRequestCollection'
import {BusinessLineType} from '../../../common/types'
import {useBranding} from '../../../Organisms/Branding'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {OrderRequest, OrderRequestResponse} from '../../declarations/types'
import {CollectOrderSummaryLegacyItem} from './CollectOrderSummaryLegacyItem'
import {ProcessingOrderLegacyCard} from '../ProcessingOrderLegacyCard'

interface Props {
  collection: OrderRequest
  itemOrder: string
  businessLine: BusinessLineType
  onError: (error: AxiosError) => void
  customErrorMessage?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    background: theme.palette.error.main,
    padding: theme.spacing(2)
  }
}))

export const CollectionProcessingOrderLegacyCard: React.FC<Props> = ({
  collection,
  itemOrder,
  businessLine,
  onError,
  customErrorMessage
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {dispatchPhoneNumber} = useBranding()
  const {setOrderSummary} = useBulkCementOrderIntake()
  const [orderSummary, setOrderSummaryLocal] = useState<OrderRequestResponse>()
  const {mutate, isLoading, isError} = usePostOrderRequestCollection()

  useEffect(() => {
    mutate(
      {orderIntakePayload: collection.payload},
      {
        onSuccess: (data) => {
          setOrderSummaryLocal(data)
          setOrderSummary(data)
        },
        onError: (error) => {
          onError(error as AxiosError)
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  return (
    <>
      {isLoading || isError ? (
        <ProcessingOrderLegacyCard
          itemIndex={itemOrder}
          isLoading={isLoading}
          loadingLabel={t('orderIntake.processingOrder')}
          date={collection.payload.deliveryDate}
          time={collection.payload.deliveryTime}
          materialNumber={collection.payload.materialNumber}
          businessLine={businessLine}
          hasError={isError}
          errorMessage={
            <Box className={classes.errorContainer}>
              <Typography variant="h3" customColor="textErrorContrastText">
                {t('orderIntake.orderFailed')}
              </Typography>
              {customErrorMessage ? (
                <Typography variant="body2" customColor="textErrorContrastText">
                  {customErrorMessage}
                </Typography>
              ) : null}
              <Typography variant="h3" customColor="textErrorContrastText">
                {dispatchPhoneNumber
                  ? t('orderIntake.contactByPhone', {phoneNumber: dispatchPhoneNumber})
                  : t('orderIntake.contactByPhoneWithoutNumber')}
              </Typography>
              <Typography variant="body2" customColor="textErrorContrastText">
                {t('orderIntake.errorMessage')}
              </Typography>
            </Box>
          }
        />
      ) : orderSummary ? (
        <Box
          key={`Collection-Item-Order-Confirmation-${orderSummary.siteNumber}-${orderSummary.requestData.material.materialNumber}-${itemOrder}`}
        >
          <CollectOrderSummaryLegacyItem
            itemIndex={itemOrder}
            plant={orderSummary.requestData.plant}
            shippingAddress={orderSummary.requestData.shippingAddress}
            orderRequestStatus={orderSummary.status}
            quantity={orderSummary.requestData.quantity}
            quantityType={collection.payload.capacity.quantityType}
            quantityUom={orderSummary.requestData.quantityUom}
            collectionDate={orderSummary.requestData.deliveryDate}
            collectionTime={orderSummary.requestData.deliveryTime}
            materialDescription={orderSummary.requestData.material.materialDescription}
            haulerInfo={orderSummary.requestData.haulerInfo}
          />
        </Box>
      ) : null}
    </>
  )
}
