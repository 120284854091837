import {Typography, BorderedTextField, TextButton, PageTitle, ActionButton} from '@hconnect/uikit'
import {Box, Dialog, DialogContent, DialogActions, Divider} from '@material-ui/core'
import {AddCircle} from '@material-ui/icons'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {useFormContext, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {OrderIntakeOption} from '../../declarations/types'

interface DriverInstructionsPopoverProps {
  isOpen: boolean
  handleClose: () => void
}

const DRIVER_INSTRUCTIONS_MAX_LENGTH = 200

export const DriverInstructionsPopover: React.FC<DriverInstructionsPopoverProps> = ({
  isOpen,
  handleClose
}) => {
  const {t} = useTranslation()
  const classes = useOrderIntakeStyles()
  const {register, setValue, watch} = useFormContext<BulkOrderFormValues>()
  const [driverInstructions, setDriverInstructions] = useState<string | undefined>(
    watch('driverInstructions') ?? undefined
  )
  const [inputInvalid, setInputInvalid] = useState<boolean>(false)

  const selectedSite: OrderIntakeOption = watch('selectedSite')
  const [readOnlyDriversInstructions, setReadOnlyDriversInstructions] = useState<
    string | undefined
  >(selectedSite?.driverInstructions ?? '')

  useEffect(() => {
    setReadOnlyDriversInstructions(selectedSite?.driverInstructions)
    setShowDriverInstructionsInput(!selectedSite?.driverInstructions)
  }, [selectedSite])

  useEffect(() => {
    setValue('driverInstructions', selectedSite?.driverInstructions)
  }, [selectedSite?.driverInstructions, setValue, readOnlyDriversInstructions])

  const isReadOnlyDriversInstructionsAvailable = readOnlyDriversInstructions
    ? readOnlyDriversInstructions.length > 0
    : false
  const [showDriverInstructionsInput, setShowDriverInstructionsInput] = useState<boolean>(
    !isReadOnlyDriversInstructionsAvailable
  )
  const customerPrefix = ' Customer: '
  const additionalInstructionsMaxLength = readOnlyDriversInstructions
    ? DRIVER_INSTRUCTIONS_MAX_LENGTH - readOnlyDriversInstructions.length - customerPrefix.length
    : DRIVER_INSTRUCTIONS_MAX_LENGTH - customerPrefix.length

  const handleSave = () => {
    const joinedDriverInstructions = `${readOnlyDriversInstructions}${customerPrefix}${driverInstructions}`
    let finalDriverInstructions = isReadOnlyDriversInstructionsAvailable
      ? driverInstructions
        ? joinedDriverInstructions
        : readOnlyDriversInstructions
      : `${customerPrefix}${driverInstructions}`

    if (!isReadOnlyDriversInstructionsAvailable && !driverInstructions) {
      finalDriverInstructions = ''
    }

    setValue('driverInstructions', finalDriverInstructions)
    handleClose()
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const validInputPattern = /["\nçÇ#!.]/g
    const found = inputValue.match(validInputPattern)
    setDriverInstructions(inputValue)

    if (found && found.length > 0) {
      setInputInvalid(true)
    } else {
      setInputInvalid(false)
    }
  }

  return (
    <Dialog
      data-test-id="driver-instruction-dialog"
      open={isOpen}
      onClose={() => {
        setShowDriverInstructionsInput(false)
        handleClose()
      }}
    >
      <Box className={classes.driverInstructionsDialog}>
        <Controller
          {...register('driverInstructions')}
          rules={{
            maxLength: 200
          }}
          render={({field}) => (
            <>
              <DialogContent>
                <PageTitle data-test-id="driver-instruction-popover-title">
                  {t('orderIntake.addDriverInstruction')}
                </PageTitle>
                <Box p={0.8} />
                <Divider color="onLight" />
                <Box p={2} />
                <Box display="flex" flexDirection="column">
                  {isReadOnlyDriversInstructionsAvailable && (
                    <>
                      <Typography variant="caption" color="secondary">
                        {t('orderIntake.driverInstructionsFromContract')}
                      </Typography>
                      <Box p={0.25} />
                      <Typography variant="body1" color="textPrimary">
                        {readOnlyDriversInstructions}
                      </Typography>
                      <Box p={2} />
                    </>
                  )}

                  {!showDriverInstructionsInput && additionalInstructionsMaxLength > 0 && (
                    <Box display="flex" justifyContent="flex-start">
                      <ActionButton
                        data-test-id="driver-instruction-add-more-details-button"
                        onClick={() => setShowDriverInstructionsInput(true)}
                        icon={<AddCircle color="primary" />}
                      >
                        {t('orderIntake.addMoreDetails')}
                      </ActionButton>
                    </Box>
                  )}

                  {showDriverInstructionsInput && (
                    <>
                      <Typography variant="caption" color="secondary">
                        {t('orderIntake.informationAboutDestination')}
                      </Typography>
                      <Box p={0.25} />
                      <BorderedTextField
                        data-test-id="driver-instruction-text-field"
                        name={field.name}
                        label={t('orderIntake.driverInstructions')}
                        onChange={onChange}
                        value={driverInstructions}
                        placeholder={t('orderIntake.driverInstructions')}
                        multiline
                        minRows={4}
                        maxRows={6}
                        onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                        inputProps={{
                          maxLength: isReadOnlyDriversInstructionsAvailable
                            ? additionalInstructionsMaxLength
                            : DRIVER_INSTRUCTIONS_MAX_LENGTH
                        }}
                        helperText={`${driverInstructions?.length ?? 0} / ${
                          isReadOnlyDriversInstructionsAvailable
                            ? additionalInstructionsMaxLength
                            : DRIVER_INSTRUCTIONS_MAX_LENGTH
                        }`}
                        error={inputInvalid}
                      />
                    </>
                  )}

                  <Box p={0.25} />
                  <Typography
                    data-test-id="driver-instructions-invalid-characters-error-message"
                    variant="caption"
                    color="error"
                    style={{visibility: inputInvalid ? 'visible' : 'hidden'}}
                  >
                    {t('orderIntake.driverInstructionsInvalidCharacters')}
                  </Typography>
                </Box>
              </DialogContent>
              <Box p={2} />
              <DialogActions>
                <TextButton
                  variant="text"
                  onClick={() => {
                    isReadOnlyDriversInstructionsAvailable && setShowDriverInstructionsInput(false)
                    handleClose()
                  }}
                >
                  {t('orderIntake.cancel')}
                </TextButton>
                <TextButton
                  data-test-id="driver-instruction-save-button"
                  disabled={inputInvalid}
                  variant="outlined"
                  onClick={handleSave}
                >
                  {t('orderIntake.save')}
                </TextButton>
              </DialogActions>
            </>
          )}
        />
      </Box>
    </Dialog>
  )
}
