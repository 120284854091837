import {BorderedTextField} from '@hconnect/uikit/src'
import {Grid} from '@material-ui/core'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {isValidCustomerReference} from '../../../../Organisms/OrderIntake/utils'

import testIds from './dataTestIds'
import {MaterialOrderLegacyForm} from './types'

export const PoTab: React.FC = () => {
  const {t} = useTranslation()
  const methods = useFormContext<MaterialOrderLegacyForm>()

  return (
    <Grid>
      <Controller
        control={methods.control}
        name="customerReference"
        rules={{
          maxLength: 35,
          validate: (value) => {
            return isValidCustomerReference(value)
          }
        }}
        render={({field, fieldState: {error}}) => (
          <BorderedTextField
            fullWidth
            data-test-id={testIds.po.poNumber}
            name={field.name}
            label={t('orderIntake.customerReference')}
            placeholder={t('orderIntake.customerReferencePlaceholder')}
            onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
            value={field.value}
            onChange={field.onChange}
            inputProps={{maxLength: 35}}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </Grid>
  )
}
