import {isEqual} from 'lodash'

import {selectMaterialOption} from '../../Organisms/OrderIntake/utils'
import {DEFAULT_CUSTOMER_REFERENCE} from '../declarations/constants'
import {OrderIntakeOption, OrderRequest} from '../declarations/types'

export const updateOrderRequestByDeliveryDateChange = (
  orderRequest: OrderRequest,
  selectedSite: OrderIntakeOption,
  deliveryDate: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isOrderIntakeCollectTimePicker: boolean
): OrderRequest => {
  const materialOptions = selectedSite.materials[orderRequest.payload.materialEnteredNumber]

  const originalDeliverMaterialOption = selectMaterialOption(
    orderRequest.payload.deliveryDate,
    materialOptions
  )
  const newDeliverMaterialOption = selectMaterialOption(deliveryDate, materialOptions)

  let customerReference: string | undefined = orderRequest.payload.customerReference
  let contractNumber = orderRequest.payload.contractNumber
  let contractItemPositionNumber = orderRequest.payload.contractItemPositionNumber

  if (!isEqual(originalDeliverMaterialOption, newDeliverMaterialOption)) {
    customerReference = newDeliverMaterialOption?.customerReference
    contractNumber = newDeliverMaterialOption?.material.contractNumber ?? ''
    contractItemPositionNumber = newDeliverMaterialOption?.material.contractItemPositionNumber ?? ''
  }

  const isDateNotAvailable = !newDeliverMaterialOption
  const deliveryTime = orderRequest.payload.deliveryTime

  return {
    ...orderRequest,
    isDateNotAvailable,
    isDateChange: false,
    payload: {
      ...orderRequest.payload,
      customerReference: customerReference || DEFAULT_CUSTOMER_REFERENCE,
      contractNumber,
      contractItemPositionNumber,
      deliveryDate,
      deliveryTime
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default function (
  orderRequest$: OrderRequest,
  selectedSite: OrderIntakeOption,
  deliveryDate: string,
  isOrderIntakeCollectTimePicker = false
) {
  const orderRequest = updateOrderRequestByDeliveryDateChange(
    orderRequest$,
    selectedSite,
    deliveryDate,
    isOrderIntakeCollectTimePicker
  )
  const isOrderRequestConfirmed = true
  return {orderRequest, isOrderRequestConfirmed}
}
