import React, {forwardRef, useEffect, useState} from 'react'

import {roundInt, roundFloat} from '../../../util/numbers'

import {InputWithControls, Props as InputWithControlsProps} from './InputWithControls'

type Props = {
  name: string
  title?: React.ReactNode
  value?: number
  'data-test-id'?: string
  decimalsNumber?: number
  increment?: number
  dontRoundValueToIncrement?: boolean
  allowNaN?: boolean
  onChange?: (value: number) => void
} & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'value' | 'onChange' | 'title' | 'name' | 'dataTestId'
> &
  Pick<InputWithControlsProps, 'decrementTooltipProps' | 'incrementTooltipProps'>

export const NumericInputWithControls = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      title,
      value,
      onChange,
      'data-test-id': dataTestId,
      decimalsNumber,
      dontRoundValueToIncrement,
      allowNaN,
      increment,
      decrementTooltipProps,
      incrementTooltipProps,
      ...props
    },
    ref
  ) => {
    const [internalStringValue, setInternalStringValue] = useState(
      value?.toFixed(decimalsNumber) ?? ''
    )

    const [internalValue, setInternalValue] = useState<number>(value ?? 0)
    const getMinMaxValue = (property: 'min' | 'max' | 'threshold') => {
      const propertyValue = props[property]
      if (internalValue === undefined || propertyValue === undefined)
        return property === 'max' ? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER
      const type = typeof propertyValue === 'number'
      return type ? propertyValue : Number.parseInt(propertyValue)
    }
    const minValue = getMinMaxValue('min')
    const maxValue = getMinMaxValue('max')
    useEffect(() => {
      if (internalStringValue !== (internalValue?.toFixed(decimalsNumber) ?? '')) {
        const numericValue = decimalsNumber
          ? Number.parseFloat(internalStringValue)
          : Number.parseInt(internalStringValue)
        if (Number.isNaN(numericValue) && !allowNaN) {
          return
        }
        setInternalValue(numericValue)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalStringValue])

    useEffect(() => {
      if (value === undefined) {
        setInternalStringValue('')
      }
    }, [value])

    useEffect(() => {
      if (internalValue === value) return
      if (internalValue < minValue) return
      if (internalValue > maxValue) return

      onChange && onChange(internalValue)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue])

    const checkDecimalNumber = (stringValue: string) => {
      if (!stringValue) return ''

      const regex = new RegExp(`(\\.[\\d]{${decimalsNumber}}).`, 'g')
      const value = stringValue.replace(regex, '$1')

      const roundedValue = decimalsNumber ? parseFloat(value) : parseInt(value)
      return roundedValue.toString()
    }

    const getDecimalNumberByIncrement = () => {
      if (decimalsNumber && decimalsNumber > 0)
        return dontRoundValueToIncrement
          ? internalValue.toFixed(decimalsNumber)
          : roundFloat(internalValue, increment ?? 1, decimalsNumber)
      else if (increment && increment > 1) return roundInt(internalValue, increment)

      return internalValue.toFixed(decimalsNumber)
    }

    return (
      <InputWithControls
        canShowTimeFormat={false}
        isDecimal={(decimalsNumber ?? 0) > 0}
        name={name}
        title={title}
        ref={ref}
        onDecrement={() =>
          setInternalStringValue(
            internalValue - 1 >= minValue
              ? (internalValue - (increment ?? 1)).toFixed(decimalsNumber)
              : minValue.toFixed(decimalsNumber)
          )
        }
        decrementProps={{
          disabled: internalValue <= minValue || props.disabled
        }}
        decrementTooltipProps={decrementTooltipProps}
        onIncrement={() =>
          setInternalStringValue(
            internalValue + 1 <= maxValue
              ? (internalValue + (increment ?? 1)).toFixed(decimalsNumber)
              : maxValue.toFixed(decimalsNumber)
          )
        }
        incrementProps={{
          disabled: internalValue >= maxValue || props.disabled
        }}
        incrementTooltipProps={incrementTooltipProps}
        data-test-id={dataTestId}
        value={internalStringValue}
        onChange={(e) => {
          setInternalStringValue(
            (decimalsNumber ?? 0) > 0
              ? checkDecimalNumber(e.currentTarget.value)
              : e.currentTarget.value
          )
        }}
        blurOnEnter={true}
        inputProps={{
          ...props,
          onBlur: () => {
            if (!internalStringValue) setInternalStringValue(minValue.toFixed(decimalsNumber))
            else setInternalStringValue(getDecimalNumberByIncrement())

            if (internalValue < minValue) {
              setInternalStringValue(minValue.toFixed(decimalsNumber))
            } else if (internalValue > maxValue) {
              setInternalStringValue(maxValue.toFixed(decimalsNumber))
            }
          },
          type: 'number'
        }}
      />
    )
  }
)
