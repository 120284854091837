// eslint-disable-next-line import/no-default-export
export default {
  tabs: {
    dateTime: 'order-intake-editor-dateTime-tab',
    haulier: 'order-intake-editor-haulier-tab',
    po: 'order-intake-editor-po-tab',
    details: 'order-intake-editor-details-tab'
  },
  tabPanel: {
    dateTime: 'order-intake-editor-dateTime-tabPanel',
    haulier: 'order-intake-editor-haulier-tabPanel',
    po: 'order-intake-editor-po-tabPanel',
    details: 'order-intake-editor-details-tabPanel'
  },
  submitBtn: 'order-intake-editor-submit',
  backBtn: 'order-intake-editor-back',
  closeBtn: 'order-intake-editor-close',
  deleteBtn: 'order-intake-editor-delete',
  dateTime: {
    shipTo: 'order-intake-editor-dateTime-shipTo',
    datePicker: 'order-intake-editor-dateTime-datePicker',
    timePicker: 'order-intake-editor-dateTime-timePicker'
  },
  haulier: {
    driverName: 'hauler-info-driver-name-text-field',
    phoneNumber: 'order-intake-driverPhoneNumber-input',
    licensePlate: 'hauler-info-truck-licence-plate-text-field',
    trailerLicensePlate: 'hauler-info-trailer-licence-plate-text-field',
    clearBtn: 'hauler-info-clear-button'
  },
  po: {
    poNumber: 'customer-reference-text-field-summary'
  },
  confirmDialog: 'order-intake-editor-confirmDialog'
} as const
