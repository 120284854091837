import {Typography} from '@hconnect/uikit'
import {Box, CardContent} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderIntakeMaterialOptionPayload} from '../../OrderIntake/declarations/types'

interface Props {
  invalidMaterialOptions: OrderIntakeMaterialOptionPayload[]
}

export const SummaryNoValidMaterials: React.FC<Props> = ({invalidMaterialOptions}) => {
  const {t} = useTranslation()

  return (
    <CardContent>
      <Typography variant="h4">
        {t('orderIntake.invalidMaterials.listOfNoValidMaterials')}
      </Typography>
      <Box p={0.5} />
      {invalidMaterialOptions.map((item) => (
        <Typography variant="body1" key={item.material.materialDescription}>
          {item.material.materialDescription}
        </Typography>
      ))}
    </CardContent>
  )
}
