import {Box} from '@material-ui/core'
import {FC} from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error';

import {OrderRequestStatus} from '../../OrderIntake/declarations/OrderIntake.enums'

interface Props {
  status: OrderRequestStatus
}

const OrderStatus: FC<Props> = ({status}) => {
  const getIndicatorIcon = (status: OrderRequestStatus) => {
    switch (status) {
      case OrderRequestStatus.NOT_VALIDATED:
        return (
          <CheckCircleIcon
            htmlColor="#31ca72"
            fontSize="inherit"
        />
        )
        case OrderRequestStatus.FAILED:
          return (
            <ErrorIcon
              htmlColor="#DA0901"
              fontSize="inherit"
          />
          )
    }
  }

  return (
    <Box display="flex" alignItems="center" data-test-id="order-status-with-icon">
        {getIndicatorIcon(status)}
    </Box>
  )
}

export {OrderStatus}
