import {Typography} from '@material-ui/core'
import {Box, styled} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {ConfigurableTimeSlot, SlotConfiguration} from '../declarations/types'

const SlotConfigurationBox = styled(Box)<{isBlockingDelivery: boolean}>(({isBlockingDelivery}) => ({
  backgroundColor: `${getBackgroundColorOfItemStatus(isBlockingDelivery)}`,
  border: `${getBorderColorOfItemStatus(isBlockingDelivery)} 1px solid`,
  color: `${getTextColorOfItemStatus(isBlockingDelivery)}`,
  fontSize: '10px',
  fontWeight: 600,
  borderRadius: '4px',
  margin: '2px',
  padding: '3px 4px',
  with: '320px'
}))

const SlotLegendItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '7px'
})

const SlotLegendItemStatus = styled(Box)<{isBlockingDelivery: boolean}>(({isBlockingDelivery}) => ({
  background: `${getBorderColorOfItemStatus(isBlockingDelivery)}`,
  borderRadius: '4px',
  width: '8px',
  height: '8px',
  marginRight: '5px'
}))

const getBorderColorOfItemStatus = (isBlockingDelivery: boolean) => {
  switch (isBlockingDelivery) {
    case false:
      return '#5AC367'
    case true:
      return '#EC544E'
    default:
      return 'grey'
  }
}

const getBackgroundColorOfItemStatus = (isBlockingDelivery: boolean) => {
  switch (isBlockingDelivery) {
    case false:
      return '#C2FBC9'
    case true:
      return '#ECD4D3'
    default:
      return 'grey'
  }
}

const getTextColorOfItemStatus = (isBlockingDelivery: boolean) => {
  switch (isBlockingDelivery) {
    case false:
      return '#2F7124'
    case true:
      return '#951818'
    default:
      return 'grey'
  }
}

interface Props {
  slotsConfiguration: SlotConfiguration[]
  slots: ConfigurableTimeSlot[]
  showLegend: boolean
}

const SlotsStatus: FC<Props> = ({slotsConfiguration, slots, showLegend}) => {
  const {t} = useTranslation()

  const getSlotStatus = (slots: ConfigurableTimeSlot[], slotConfigId: string) => {
    const slot = slots.find((slot) => slot.slotConfig.id === slotConfigId)

    if (slot) return slot.slotStatus.isBlockingDelivery
    return false
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      data-test-id="slots-configuration"
    >
      <Box sx={{display: 'flex'}}>
        {slotsConfiguration &&
          slotsConfiguration.map((slotConfig) => (
            <SlotConfigurationBox
              key={slotConfig.id}
              isBlockingDelivery={getSlotStatus(slots, slotConfig.id)}
            >
              {`${slotConfig.slotStart} - ${slotConfig.slotEnd}`}
            </SlotConfigurationBox>
          ))}
      </Box>
      {showLegend && (
        <Box sx={{display: 'flex'}}>
          <SlotLegendItem>
            <SlotLegendItemStatus isBlockingDelivery={false} />
            <Typography style={{color: '#002E4D', fontSize: '12px'}}>
              {t('orderIntake.slotStatus.available')}
            </Typography>
          </SlotLegendItem>

          <SlotLegendItem>
            <SlotLegendItemStatus isBlockingDelivery={true} />
            <Typography style={{color: '#002E4D', fontSize: '12px'}}>
              {t('orderIntake.slotStatus.full')}
            </Typography>
          </SlotLegendItem>
        </Box>
      )}
    </Box>
  )
}

export {SlotsStatus}
