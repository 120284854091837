import {Box, Typography} from '@material-ui/core'
import React, {useState, useCallback} from 'react'
import {ControllerRenderProps, UseFormStateReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {GoogleMap} from '../components/GoogleMap'
import {SearchBox} from '../components/MapSearchBox'
import Marker from '../components/Marker'
import {RequestForQuoteFormValues} from '../RequestForQuoteFormValues'

interface GoogleMapWithSearchboxProps {
  mapCoordinatesField: ControllerRenderProps<
    RequestForQuoteFormValues,
    'projectDetails.projectDestination.mapCoordinates'
  >
  mapCoordinatesFormState: UseFormStateReturn<RequestForQuoteFormValues>
}

export const GoogleMapWithSearchbox: React.FC<GoogleMapWithSearchboxProps> = ({
  mapCoordinatesField,
  mapCoordinatesFormState
}) => {
  const {t} = useTranslation()

  const [mapApiLoaded, setMapApiLoaded] = useState(false)
  const [mapInstance, setMapInstance] = useState<any>()
  const [mapApi, setMapApi] = useState<any>()
  const [markerCoords, setMarkerCoords] = useState<{lat: number; lng: number} | null>(null)

  const apiHasLoaded = useCallback((map: any, maps: any) => {
    setMapApiLoaded(true)
    setMapInstance(map)
    setMapApi(maps)
  }, [])

  const handleMapClick = ({lat, lng}) => {
    setMarkerCoords({lat, lng})
    const payLoadMapCoordinates = `Lat: ${lat}, Lng: ${lng}`
    mapCoordinatesField.onChange(payLoadMapCoordinates)
  }

  return (
    <>
      {mapApiLoaded && mapInstance && mapApi && <SearchBox map={mapInstance} mapApi={mapApi} />}
      <GoogleMap
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => apiHasLoaded(map, maps)}
        onClick={handleMapClick}
      >
        {markerCoords && (
          <Marker key={markerCoords.lat} text="" lat={markerCoords.lat} lng={markerCoords.lng} />
        )}
      </GoogleMap>
      <Box mt={1} />
      {mapCoordinatesFormState.errors.projectDetails?.projectDestination?.mapCoordinates ? (
        <Typography variant="caption" component="span" color="error">
          {t('orderIntake.requestForQuote.projectAddressMapError')}
        </Typography>
      ) : markerCoords?.lat ? (
        <Typography variant="caption" color="secondary">
          {`${t(
            'orderIntake.requestForQuote.projectAddressMapCoordinates'
          )} Lat ${markerCoords?.lat}, Lng ${markerCoords?.lng}`}
        </Typography>
      ) : (
        <Typography variant="caption" color="secondary">
          {t('orderIntake.requestForQuote.projectAddressMapRequired')}
        </Typography>
      )}
    </>
  )
}
