import {trackEvent} from '../common/analytics'

import {DeactivationReason} from './declarations/OrderIntake.enums'
import {OrderIntakeMaterialOptionPayload} from './declarations/types'

type InvalidMaterialTrackedProperties = {
  customerNumber?: string
  siteNumber?: string
  shippingType?: string
}

export const trackInvalidMaterialsFactory =
  (
    invalidMaterialOptions: OrderIntakeMaterialOptionPayload[],
    props: InvalidMaterialTrackedProperties
  ) =>
  (v: boolean) => {
    if (!v) return
    if (invalidMaterialOptions.length === 0) return
    if (!props.customerNumber || !props.siteNumber) return
    const flaggedInvalidMaterials = invalidMaterialOptions.filter(
      (item) => item.deactivationReason === (DeactivationReason.FLAGGED as string)
    )
    if (flaggedInvalidMaterials.length > 0)
      trackEvent('hubOrderIntakeInvalidMaterialOptions', {
        ...props,
        deactivationReason: DeactivationReason.FLAGGED,
        numberOfInvalidMaterialOptions: flaggedInvalidMaterials.length
      })

    const duplicatedInvalidMaterials = invalidMaterialOptions.filter(
      (item) => item.deactivationReason === (DeactivationReason.DUPLICATE as string)
    )
    if (duplicatedInvalidMaterials.length > 0)
      trackEvent('hubOrderIntakeInvalidMaterialOptions', {
        ...props,
        deactivationReason: DeactivationReason.DUPLICATE,
        numberOfInvalidMaterialOptions: duplicatedInvalidMaterials.length
      })
  }
