import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {QuantityType} from '../declarations/types'

export const useStyles = makeStyles({
  summaryDate: {
    fontWeight: 500
  }
})

interface Props {
  label: string
  plant: string | null | undefined
  id: string
  dateAndTime: string
  materialDescription: string | null | undefined
  quantity: number
  quantityType: QuantityType
}

export const SummaryLegacyItem: React.FC<Props> = ({
  label,
  plant,
  dateAndTime,
  materialDescription,
  quantity,
  quantityType
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Box data-test-id={'order-intake-summary-item'}>
      <Typography variant="caption" customColor="textPrimarySoft">
        {label}
      </Typography>
      {plant && (
        <Typography
          variant="caption"
          customColor="textPrimarySoft"
          data-test-id={'order-intake-summary-item-plant'}
        >
          {plant}
        </Typography>
      )}

      <Typography variant="body1" className={classes.summaryDate}>
        {dateAndTime}
      </Typography>
      {materialDescription ? (
        <Typography variant="body1">
          {quantityType === QuantityType.Load
            ? `${quantity} ${t('orderIntake.loads', {count: quantity})} `
            : `1 ${t('orderIntake.truck')} `}
          {materialDescription}
        </Typography>
      ) : null}
    </Box>
  )
}
