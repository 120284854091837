import {useEffect, useState} from 'react'

type ActivePopup = 'MaterialEditor' | 'OrderSummary' | undefined

export const useActivePopup = (setActiveOrderBox: () => void, activeMaterialForm?: string) => {
  const [activePopup, setActivePopup] = useState<ActivePopup>(undefined)
  useEffect(() => {
    if (activePopup === 'MaterialEditor' && activeMaterialForm) return
    if (activePopup === undefined && activeMaterialForm === undefined) return

    setActivePopup(activeMaterialForm === undefined ? undefined : 'MaterialEditor')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMaterialForm])
  useEffect(() => {
    if (activePopup === 'MaterialEditor') return
    setActiveOrderBox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup])
  return [activePopup, setActivePopup] as const
}
