import {Typography} from '@hconnect/uikit'
import {Box, styled} from '@mui/material'
import React from 'react'

const ShippingAddressHeadline = styled(Typography)({
  color: '#54708C',
  fontSize: 18,
  fontWeight: 600,
  marginBottom: 8
})

interface ShippingAddressProps {
  showInLine: boolean
  name: string
  street: string
  city: string
  postalCode: string
  headline?: string
}

export const ShippingAddress: React.FC<ShippingAddressProps> = ({
  showInLine,
  name,
  street,
  city,
  postalCode,
  headline
}) => {
  return (
    <Box>
      {headline ? (
        showInLine ? (
          <Typography variant='caption'>{headline}</Typography>
        ) : (
          <ShippingAddressHeadline>{headline}</ShippingAddressHeadline>
        )
      ) : null}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        maxWidth="100%"
        alignItems="center"
        data-test-id="shipping-address"
      >
        {showInLine && <Typography variant="body1" style={{fontWeight: 500}}>{name} {street} {city}, {postalCode}</Typography>}
        {!showInLine && (
          <>
            <Box flexDirection="column">
              <Typography variant="body2" breakWords>
                {name}
              </Typography>
              <Typography variant="body2" breakWords>
                {street}
              </Typography>
              <Typography variant="body2" breakWords>
                {city}, {postalCode}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
