import {Typography, Grid} from '@hconnect/uikit'
import {Box, Button, makeStyles, Theme} from '@material-ui/core'
import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import confirmationImage from '../../../Assets/confirmationPage/requestForQuoteConfirmation.svg'
import confirmationErrorImage from '../../../Assets/confirmationPage/requestForQuoteConfirmationError.svg'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(
        4
      )}px`
    },
    padding: `${theme.spacing(2)}px`
  },
  contentContainer: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  closeButtonGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end'
  },
  closeButtonBox: {
    display: 'flex',
    justifyContent: 'end'
  },
  closeButton: {
    [theme.breakpoints.up('sm')]: {
      right: `${theme.spacing(4)}px`
    },
    right: `${theme.spacing(2)}px`
  },
  errorMessagesContainer: {
    marginTop: `${theme.spacing(2)}px`
  }
}))

interface Props {
  showError: boolean
  errorMessages: string[]
  onClose: (resetForm: boolean) => void
}

export const RequestForQuoteFormConfirmation: React.FC<Props> = ({
  onClose,
  showError,
  errorMessages
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Grid
      container
      data-test-id="generic-confirmation-screen"
      className={classes.container}
      spacing={2}
    >
      <Grid item xs={12} md={8} className={classes.contentContainer}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            src={showError ? confirmationErrorImage : confirmationImage}
            data-test-id="order-intake-no-valid-ship-to-image"
            style={{textAlign: 'center', maxWidth: 350}}
            alt={t('orderIntake.confirmationScreen.receiveRequestSuccessfully')}
          />
        </Box>

        <Typography>
          {showError
            ? t('orderIntake.requestForQuote.confirmationError')
            : t('orderIntake.requestForQuote.confirmation')}
        </Typography>

        {errorMessages && errorMessages.length > 0 && (
          <Box className={classes.errorMessagesContainer}>
            {errorMessages.map((errorMessage, index) => {
              return (
                <Typography
                  component="div"
                  variant="body2"
                  key={`orderIntake.confirmationScreen.errorMessage-${index}`}
                  color="error"
                >
                  {errorMessage}
                </Typography>
              )
            })}
          </Box>
        )}

        <Stack spacing={2}></Stack>
      </Grid>

      <Grid item xs={12} className={classes.closeButtonGrid}>
        <Box className={classes.closeButtonBox}>
          <Button
            data-test-id="order-intake-confirmation-screen-close-text-button"
            color="secondary"
            className={classes.closeButton}
            onClick={() => {
              onClose(!showError)
            }}
          >
            {t('orderIntake.close')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
