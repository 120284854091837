import {Typography} from '@hconnect/uikit'
import {Box, IconButton} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {QuantityType} from '../declarations/types'
import {QuantityUomToSymbolComponent} from '../../Pages/Order/Order.components'
import EditIcon from '@material-ui/icons/Edit'

export const useStyles = makeStyles({
  summaryDate: {
    fontWeight: 400
  },
  summaryLoad: {
    marginRight: 20,
    fontWeight: 400
  }
})

interface Props {
  id: string
  index: number
  dateAndTime: string
  quantity: number
  quantityType: QuantityType
  showQuantity: boolean
  capacityUom: string | undefined
  onEditItem: (index: number) => void
}

export const SummaryItem: React.FC<Props> = ({
  index,
  dateAndTime,
  quantity,
  quantityType,
  showQuantity,
  capacityUom,
  onEditItem
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const handleEditButtonClick = () => {
    onEditItem && onEditItem(index)
  }

  return (
    <Box
      data-test-id={'order-intake-summary-item'}
      display="flex"
      flexDirection="row"
      marginTop={1}
    >
      {showQuantity && (
        <Typography variant="body1" className={classes.summaryLoad} color="secondary">
          {`${quantity}`}
          {quantityType === QuantityType.Load ? (
            ` ${t('orderIntake.loads', {count: quantity})} `
          ) : (
            <QuantityUomToSymbolComponent uom={capacityUom} />
          )}
        </Typography>
      )}
      <Typography variant="body1" className={classes.summaryDate} color="secondary">
        {dateAndTime}
      </Typography>

      <Box marginLeft="auto">
        <IconButton
          size="small"
          style={{border: 'none', boxShadow: 'none'}}
          onClick={handleEditButtonClick}
        >
          <EditIcon color="secondary" />
        </IconButton>
      </Box>
    </Box>
  )
}
