import {Typography, InlineCheckbox, BorderedTextField, MdRenderer} from '@hconnect/uikit'
import {Box, FormControlLabel, Button} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/BlockOutlined'
import CloseIcon from '@material-ui/icons/Close'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import {ButtonBase, Grid, Hidden, styled} from '@mui/material'
import {isEmpty, some} from 'lodash'
import React from 'react'
import {Controller, useFormContext, useFormState} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {usePersistedOrderRequests} from '../../../common/react-query/hooks/queries/usePersistedOrderRequests'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {OrderPlacementDisclaimer} from '../../../OrderIntake/components/OrderPlacementDisclaimer'
import {ShippingAddress} from '../../../OrderIntake/components/ShippingAddress'
import {SummaryGroup} from '../../../OrderIntake/components/SummaryGroup'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {groupMaterials} from '../../../Organisms/OrderIntake/utils'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {SummaryNoValidMaterials} from '../../components/SummaryNoValidMaterials'
import {ShippingType} from '../../declarations/OrderIntake.enums'
import {QuantityType} from '../../declarations/types'
import {useBulkOrderFormChangeHandler} from '../../FormChangeHandlers'
import {useOrderIntakeData} from '../../hooks'
import {useCollectionOptions, useDeliveryOptions} from '../../Options'
import {
  isInvalidOrderIntakeOption,
  useDefaultCollection,
  useDefaultDelivery
} from '../../Options/query'
import {getMaterialOptionsByInvalidity} from '../utils'
import {MaterialSectionHeader} from '../../components/VisualComponents'

const CloseButton = styled(ButtonBase)`
  position: absolute;
  right: 0;
  top: 0;
  color: ${({theme}) => theme.palette.primary.main};
  z-index: 1000;
`

const getConfirmationMailFeatureKey = (shippingType: ShippingType) =>
  shippingType === ShippingType.DELIVER
    ? 'OrderIntakeShowConfirmationMailDelivery'
    : 'OrderIntakeShowConfirmationMailCollect'

const ArrowRightStyled = styled(ArrowRightAlt)`
  margin-right: 5px;
`

type Props = {
  shippingType: ShippingType
  quantityType: QuantityType
  customerId?: string
  onCloseSummaryBox?: () => void
  onCustomerRefChange: (onCustomerRefChange: string) => void
  onEmailCcChange: (emailAddress: string) => void
  onEditItem: (index: number) => void
}

// eslint-disable-next-line complexity
export const OrderSummarySection: React.FC<Props> = ({
  customerId,
  shippingType,
  quantityType,
  onEmailCcChange,
  onCloseSummaryBox,
  onEditItem
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {userEmail} = useOrderIntakeData()
  const {getFeature} = useFeaturesState()

  const isSlotsManagementEnabled = getFeature('OrderIntakeSlotsCollect')
  const nextDayCollect = getFeature('OrderIntakeChangeOrderDefaultDayCollect')
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')

  const configuration = {
    isSlotsManagementEnabled,
    nextDayCollect,
    applyMaterialEnteredDescription,
    applyContractItemDescription
  }

  const {data: deliverOptions} = useDeliveryOptions(configuration, customerId)
  const {data: collectOptions} = useCollectionOptions(configuration, customerId)

  const methods = useFormContext<BulkOrderFormValues>()
  const classes = useOrderIntakeStyles()

  const {isValid, isValidating, isSubmitting} = useFormState({
    control: methods.control
  })

  const siteNumber = methods.watch('selectedSite.shippingAddress.siteNumber')
  const {data: defaultDelivery} = useDefaultDelivery(customerId, configuration, siteNumber)
  const {data: defaultCollect} = useDefaultCollection(customerId, configuration, siteNumber)

  const {selectedSite, isOrderRequestConfirmed, isConfirmationMailChecked, orders} = methods.watch()
  const {isFetching: isOrderRequestsCacheLoading} = usePersistedOrderRequests(
    selectedSite.shippingAddress.siteNumber
  )
  const options = shippingType === ShippingType.DELIVER ? deliverOptions : collectOptions
  const {onConfirmOrderRequest} = useBulkOrderFormChangeHandler()

  if (!options) return null

  const invalidMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, true)
  const validMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, false)
  const noValidMaterial =
    (invalidMaterialOptions.length >= 1 && isEmpty(validMaterialOptions)) ||
    (shippingType === ShippingType.DELIVER
      ? isInvalidOrderIntakeOption(defaultDelivery)
      : isInvalidOrderIntakeOption(defaultCollect))

  const isConfirmationMailDisabled = !selectedSite.canDisableEmailSending
  const isTimeForOrderOver = methods.watch('isTimeForOrderOver')
  const isNoDatesAvailable = some(orders, 'isNoDatesAvailable')
  const isSlotManagementEnabled = getFeature(
    shippingType === ShippingType.DELIVER ? 'OrderIntakeSlotsDeliver' : 'OrderIntakeSlotsCollect'
  )
  const emailCc = methods.watch('contact.carbonCopyEmail')

  return (
    <>
      <Hidden smUp>
        <Grid item sm={12}>
          <CloseButton
            data-test-id="order-intake-summary-close"
            aria-label="Close"
            onClick={onCloseSummaryBox}
          >
            <CloseIcon />
          </CloseButton>
        </Grid>
      </Hidden>

      <Box>
        <MaterialSectionHeader>{t('orderIntake.orderSummary')}</MaterialSectionHeader>
        <Box>
          {noValidMaterial ? (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <ErrorIcon color="error" style={{fontSize: 14}} />
              <Box p={0.25} />
              <Typography
                variant="caption"
                customColor="textError"
                data-test-id="order-intake-no-valid-materials"
              >
                {t('orderIntake.invalidMaterials.deliveryNoPossibleForDestination')}
              </Typography>
            </Box>
          ) : null}
        </Box>
        {!noValidMaterial ? (
          <>
            {!isOrderRequestsCacheLoading ? (
              <Box>
                <SummaryGroup
                  groupedMaterial={groupMaterials(
                    orders,
                    selectedSite,
                    options,
                    language,
                    shippingType === ShippingType.COLLECT &&
                      !getFeature('OrderIntakeCollectTimePicker'),
                    '|'
                  )}
                  shippingType={shippingType}
                  quantityType={quantityType}
                  onEditItem={onEditItem}
                />
              </Box>
            ) : null}
            <Box mt={3}>
              <ShippingAddress
                showInLine={false}
                headline={
                  shippingType === ShippingType.DELIVER
                    ? t('orderIntake.deliverTo')
                    : t('orderIntake.collectionAddress')
                }
                name={selectedSite.shippingAddress.siteName}
                street={selectedSite.shippingAddress.street}
                postalCode={selectedSite.shippingAddress.postalCode}
                city={selectedSite.shippingAddress.city}
              />
            </Box>
          </>
        ) : (
          <SummaryNoValidMaterials
            invalidMaterialOptions={
              invalidMaterialOptions?.length > 0 ? invalidMaterialOptions : validMaterialOptions
            }
          />
        )}
      </Box>
      {!noValidMaterial && (
        <>
          <Box marginTop={2}>
            <Controller
              control={methods.control}
              name={'contact.carbonCopyEmail'}
              rules={{
                pattern: {
                  message: t('orderIntake.invalidEmailAddress'),
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                },
                validate: (value) => {
                  return (
                    value?.toLowerCase() !== userEmail?.toLowerCase() ||
                    t('orderIntake.emailCcMustBeDifferent')
                  )
                }
              }}
              render={({field, fieldState}) => {
                return (
                  <BorderedTextField
                    data-test-id="email-cc-text-field"
                    name={field.name}
                    label={t('orderIntake.emailCc')}
                    required={!!fieldState.error}
                    requiredLabel={fieldState.error ? fieldState.error.message : undefined}
                    placeholder={t('orderIntake.recipientEmailAddress')}
                    value={field.value ?? ''}
                    onChange={(e) => onEmailCcChange(e.target.value)}
                    onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                    error={!!fieldState.error}
                    InputLabelProps={{shrink: !isEmpty(emailCc)}}
                  />
                )
              }}
            />
          </Box>
          <Features name={getConfirmationMailFeatureKey(shippingType)}>
            <Box marginTop={2}>
              <Controller
                control={methods.control}
                name="isConfirmationMailChecked"
                render={({field}) => (
                  <FormControlLabel
                    control={
                      <Box display="flex" mr={0.5}>
                        <InlineCheckbox
                          name={field.name}
                          checked={isConfirmationMailChecked}
                          disabled={isConfirmationMailDisabled || isOrderRequestsCacheLoading}
                          onChange={field.onChange}
                          data-test-id="order-intake-confirmation-mail"
                        />
                      </Box>
                    }
                    label={
                      <Typography variant="body2">
                        {t('orderIntake.sendConfirmationEmail')}
                      </Typography>
                    }
                  />
                )}
              />

              {isConfirmationMailDisabled ? (
                <Box marginTop={1}>
                  <Typography variant="body2">
                    {t('orderIntake.noLongerReceiveConfirmationEmail')}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Features>
        </>
      )}

      {!noValidMaterial ? (
        <Box display="flex" flexDirection="column">
          {!isOrderRequestConfirmed && (
            <Box marginTop={2}>
              <OrderPlacementDisclaimer
                onClick={onConfirmOrderRequest}
                data-test-id="order-intake-confirm-button"
              />
            </Box>
          )}
        </Box>
      ) : null}

      {isSlotManagementEnabled ? (
        <Box pt={2}>
          <MdRenderer body={t('orderIntake.disclaimer')} paragraphVariant="caption" />
        </Box>
      ) : null}
      <Box mt={2} mb={2}>
        <Button
          type="submit"
          data-test-id="order-intake-submit-button"
          disabled={
            !isValid ||
            isValidating ||
            isSubmitting ||
            !isOrderRequestConfirmed ||
            noValidMaterial ||
            isTimeForOrderOver ||
            isNoDatesAvailable ||
            isOrderRequestsCacheLoading
          }
          className={classes.orderButton}
          color="primary"
        >
          <ArrowRightStyled />
          {t('orderIntake.placeOrder')}
        </Button>
      </Box>
    </>
  )
}
