import {TFunction} from 'i18next'
import {RfqEnabledCountries} from '../../declarations/OrderIntake.enums'
import {LabelValueCodeType, LabelValueType} from '../../declarations/types'
import {CheckboxGroupConfig} from './CheckBoxGroup'

export const servicesConfig: CheckboxGroupConfig = {value: 'value', label: 'label'}

export const getCountries = (t: TFunction): LabelValueType[] => [
  {label: t('orderIntake.requestForQuote.countryValues.canada'), value: RfqEnabledCountries.CA},
  {label: t('orderIntake.requestForQuote.countryValues.uk'), value: RfqEnabledCountries.GB},
  {label: t('orderIntake.requestForQuote.countryValues.usa'), value: RfqEnabledCountries.US},
  {label: t('orderIntake.requestForQuote.countryValues.belgium'), value: RfqEnabledCountries.BE},
  {label: t('orderIntake.requestForQuote.countryValues.netherlands'), value: RfqEnabledCountries.NL}
]

export const getProjectTypes = (t: TFunction, selectedCountry: LabelValueType): LabelValueType[] =>
  selectedCountry?.value === 'US' || selectedCountry?.value === 'CA'
    ? [
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.industrial'),
          value: 'Industrial'
        },
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.institutional'),
          value: 'Institutional'
        },
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.multiFamily'),
          value: 'MultiFamily'
        },
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.singleFamily'),
          value: 'SingleFamily'
        },
        {label: t('orderIntake.requestForQuote.projectTypeValues.roads'), value: 'Roads'},
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.transportation'),
          value: 'Transportation'
        },
        {label: t('orderIntake.requestForQuote.projectTypeValues.utilities'), value: 'Utilities'},
        {label: t('orderIntake.requestForQuote.projectTypeValues.other'), value: 'Other'}
      ]
    : [
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.residential'),
          value: 'Residential'
        },
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.publicNonResidential'),
          value: 'PublicNonResidential'
        },
        {label: t('orderIntake.requestForQuote.projectTypeValues.commercial'), value: 'Commercial'},
        {label: t('orderIntake.requestForQuote.projectTypeValues.industrial'), value: 'Industrial'},
        {
          label: t('orderIntake.requestForQuote.projectTypeValues.infrastructure'),
          value: 'Infrastructure'
        },
        {label: t('orderIntake.requestForQuote.projectTypeValues.collects'), value: 'Collects'}
      ]

export const getServices = (t: TFunction): LabelValueType[] => [
  {label: t('orderIntake.requestForQuote.servicesValues.pump'), value: 'Pump'},
  {
    label: t('orderIntake.requestForQuote.servicesValues.recyclingMuckaway'),
    value: 'RecyclingMuckaway'
  },
  {
    label: t('orderIntake.requestForQuote.servicesValues.commercialTesting'),
    value: 'CommercialTesting'
  }
]

export const getBusinessLines = (isOnlyMqpRole: boolean, t: TFunction): LabelValueCodeType[] =>
  isOnlyMqpRole
    ? [
        {
          label: t('orderIntake.requestForQuote.businessLineValues.aggregates'),
          value: 'Aggregates',
          code: '60'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.asphalt'),
          value: 'Asphalt',
          code: '70'
        }
      ]
    : [
        {
          label: t('orderIntake.requestForQuote.businessLineValues.aggregates'),
          value: 'Aggregates',
          code: '60'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.stabilizedSand'),
          value: 'StabilizedSand',
          code: '61'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.asphalt'),
          value: 'Asphalt',
          code: '70'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.readyMixedConcrete'),
          value: 'ReadyMixedConcrete',
          code: '30'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.concreteProducts'),
          value: 'ConcreteProducts',
          code: '31'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.baggedMaterial'),
          value: 'BaggedMaterial',
          code: '15'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.cement'),
          value: 'Cement',
          code: '10'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.office'),
          value: 'Office',
          code: '91'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.recycling'),
          value: 'Recycling',
          code: '93'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.otherServices'),
          value: 'OtherServices',
          code: '94'
        },
        {
          label: t('orderIntake.requestForQuote.businessLineValues.construction'),
          value: 'Construction',
          code: '92'
        }
      ]
