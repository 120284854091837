import {HCThemeType} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/ArrowBackIos'
import ArrowRight from '@material-ui/icons/ArrowForwardIos'
import {Tooltip, TooltipProps} from '@mui/material'
import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  formatValueToTwelveHoursFormat,
  isTwelveHoursFormat
} from '../TimeScroller/TimeScroller.utils'

const specialKeys = ['Control', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Escape', 'Delete']
const isSpecialKey = (e: React.KeyboardEvent<HTMLInputElement>) =>
  e.ctrlKey || e.metaKey || specialKeys.some((c) => c === e.key) || 'Key'.indexOf(e.key) !== -1

export const useTimeSelectInput = makeStyles((theme: HCThemeType) => {
  const backgroundColor = theme.palette.grey[50]
  const hide = {display: 'none !important'}
  return {
    root: {
      backgroundColor,
      boxSizing: 'content-box',
      position: 'relative',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'stretch',
      maxHeight: '75px'
    },
    showError: {
      border: '1px solid red'
    },
    highlight: {
      border: '1px solid orange'
    },
    buttonWrapper: {
      flex: '2 1 auto',
      position: 'relative',
      display: 'flex'
    },
    buttonContent: {
      minWidth: '22px',
      height: 'calc( 100% - 2px )',
      display: 'flex',
      flex: '5 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 0.2s ease-out',
      backgroundColor,
      color: theme.palette.text.primary,
      border: 'none',
      '&:first-child': {
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3
      },
      '&:last-child': {
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        transition: 'background-color 0.2s ease-in'
      },
      '&:disabled': {
        color: theme.palette.text.disabled,
        backgroundColor,
        pointerEvents: 'none'
      }
    },
    content: {
      flex: '2 1 auto',
      padding: `${theme.spacing(1)}px 0`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden'
    },
    title: {
      lineHeight: `${theme.spacing(2)}px`,
      color: theme.palette.text.primarySoft,
      maxWidth: '100%',
      minWidth: 0,
      '& label': {
        minWidth: 0,
        maxWidth: '100%',
        padding: 0,
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: theme.typography.caption.fontSize
      }
    },
    input: {
      border: 'none',
      background: 'none',
      textAlign: 'center',
      minWidth: 0,
      width: '100%',
      fontSize: 18,
      maxWidth: 75,
      padding: 0,
      fontWeight: 500,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      '&::-webkit-calendar-picker-indicator': hide,
      '&::-webkit-inner-spin-button': {...hide, '-webkit-appearance': 'none'},
      // hide webkit-list-button is not possible, because on safari ios it is defined with style=block !important;
      '&::-webkit-list-button': {
        width: '0 !important',
        height: '0 !important',
        position: 'absolute'
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '&:focus': {
        outline: 'none'
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield'
      }
    },
    timeFormatButton: {
      cursor: 'pointer',
      alignSelf: 'center',
      fontSize: 14
    },
    disabledTimeFormatButton: {
      pointerEvents: 'none',
      opacity: 0.4
    }
  }
})

export type Props = {
  onDecrement: (e: React.UIEvent) => void
  decrementProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  decrementTooltipProps?: Omit<TooltipProps, 'children'>
  onIncrement: (e: React.UIEvent) => void
  incrementProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  incrementTooltipProps?: Omit<TooltipProps, 'children'>
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  canShowTimeFormat: boolean
  showError?: boolean
  highlight?: boolean
  name: string
  value: string
  onChange: (e) => void
  disabledTimeFormat?: boolean
  title?: React.ReactNode
  isDecimal?: boolean
  'data-test-id'?: string
  children?: React.ReactNode
  blurOnEnter?: boolean
}

export const InputWithControls = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      children,
      onDecrement,
      decrementProps = {},
      decrementTooltipProps,
      onIncrement,
      incrementProps = {},
      incrementTooltipProps,
      inputProps = {},
      canShowTimeFormat,
      showError,
      title,
      value,
      onChange,
      name,
      isDecimal,
      'data-test-id': dataTestId,
      blurOnEnter
    },
    ref
  ) => {
    const c = useTimeSelectInput()

    const {
      i18n: {language}
    } = useTranslation()

    const isTwelveHours = isTwelveHoursFormat(language)
    const showTimeFormatSwitcher = canShowTimeFormat && isTwelveHours

    const [internalValue, setInternalValue] = useState<string>(
      formatValueToTwelveHoursFormat(value, language)
    )

    useEffect(() => {
      if (showTimeFormatSwitcher) setInternalValue(formatValueToTwelveHoursFormat(value, language))
    }, [value])

    let decrementButton = (
      <div className={c.buttonWrapper}>
        <button
          {...decrementProps}
          type="button"
          data-test-id={`${dataTestId}-left-btn`}
          className={c.buttonContent}
          onClick={(e) => {
            e.preventDefault()
            onDecrement(e)
          }}
        >
          <ArrowLeft />
        </button>
      </div>
    )
    if (decrementTooltipProps) {
      decrementButton = (
        <Tooltip
          {...decrementTooltipProps}
          title={
            <span data-test-id={`${dataTestId}-tooltip-inc`}>{decrementTooltipProps.title}</span>
          }
        >
          {decrementButton}
        </Tooltip>
      )
    }
    let incrementButton = (
      <div className={c.buttonWrapper}>
        <button
          {...incrementProps}
          type="button"
          className={c.buttonContent}
          data-test-id={`${dataTestId}-right-btn`}
          onClick={(e) => {
            e.preventDefault()
            if (incrementProps.disabled) return
            onIncrement(e)
          }}
        >
          <ArrowRight />
        </button>
      </div>
    )
    if (incrementTooltipProps) {
      incrementButton = (
        <Tooltip
          {...incrementTooltipProps}
          title={
            <span data-test-id={`${dataTestId}-tooltip-inc`}>{incrementTooltipProps.title}</span>
          }
        >
          {incrementButton}
        </Tooltip>
      )
    }
    return (
      <div className={classNames(c.root, showError ? c.showError : '')} data-test-id={dataTestId}>
        {decrementButton}
        <div className={c.content}>
          <div className={c.title}>
            <label
              htmlFor={name}
              title={typeof title === 'string' ? title : undefined}
              data-test-id={`${dataTestId}`}
            >
              {title}
            </label>
          </div>
          <input
            list={`${name}-datalist`}
            data-test-id={`${dataTestId}-input`}
            ref={ref}
            type={isDecimal ? 'number' : 'text'}
            step={isDecimal ? 'any' : undefined}
            className={c.input}
            name={name}
            value={showTimeFormatSwitcher ? internalValue : value}
            {...inputProps}
            onChange={(e) => {
              if (showTimeFormatSwitcher) setInternalValue(e.target.value)
              onChange(e)
            }}
            onKeyDown={(e) => {
              if (isSpecialKey(e)) return
              if (e.key === 'ArrowUp') {
                e.preventDefault()
                onIncrement(e)
                return
              }
              if (e.key === 'ArrowDown') {
                e.preventDefault()
                onDecrement(e)
                return
              }
              if (e.key === 'Enter') {
                blurOnEnter && (e.target as HTMLInputElement)?.blur()
                e.preventDefault()
              }
              inputProps.onKeyDown && inputProps.onKeyDown(e)
            }}
          />
          {children}
        </div>
        {incrementButton}
      </div>
    )
  }
)
