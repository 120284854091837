import {User} from '@hconnect/apiclient'
import {useTranslation, Typography} from '@hconnect/uikit'
import {Box, Tabs, Tab, Tooltip} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {TabContext, TabPanel} from '@material-ui/lab'
import {Alert, Stack} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {
  formatOutputTime,
  formatTime,
  getDuration,
  isCurrentTimezone,
  isWholeDay
} from '../../../../src/util/time'
import {trackEvent} from '../../../common/analytics'
import {
  BusinessHours,
  ConfigurableTimeSlot,
  DefaultDeliveryWindow,
  DeliveryTime,
  SlotConfiguration,
  TimeSelectorTrackingEventType
} from '../../../OrderIntake/declarations/types'
import {CustomerStateType, selectCustomers} from '../../../Organisms/Customers'
import {AppState} from '../../../Root.store'
import {getUserProfile} from '../../../UserProfile/UserProfile.selectors'
import {SlotsStatus} from '../SlotsStatus'
import {
  DATA_BASE_TIME_FORMAT,
  existsNonFullSlots,
  existsNonFreeSlots,
  timeFormatter
} from '../TimeScroller/TimeScroller.utils'

import {SpecificTimePicker} from './SpecificTimePicker'
import {useTimePickerStyles} from './styles'
import {TimeRangePicker} from './TimeRangePicker'

enum PickerType {
  TimeRange = 'TimeRange',
  SpecificTime = 'SpecificTime',
  WholeDay = 'WholeDay'
}

type Props = {
  isSlotManagementEnabled: boolean
  hasFixedTime?: boolean
  name: string
  interval: Interval
  value: DeliveryTime
  deliveryDate: string
  minInterval: string
  timezone?: string
  minFixIntervalTime?: string
  differentTimeZoneWarning: string
  timeChangeInterval: number
  businessHours: BusinessHours
  defaultDeliveryWindow: DefaultDeliveryWindow
  onChange: (value: DeliveryTime) => void
  onError: (isError: boolean) => void
  configurableSlots?: ConfigurableTimeSlot[]
  slotsConfiguration?: SlotConfiguration[]
  filterTimeSlots?: (d: Date, index: number, dates: Date[]) => boolean
  onTimeSelectorTrack?: (eventType: TimeSelectorTrackingEventType) => void
  'data-test-id'?: string
  cardId?: string
}

// eslint-disable-next-line complexity
export const TimePicker: React.FC<Props> = ({
  isSlotManagementEnabled,
  hasFixedTime,
  value,
  deliveryDate,
  interval,
  minInterval,
  onChange,
  onError,
  filterTimeSlots,
  minFixIntervalTime,
  differentTimeZoneWarning,
  timezone,
  timeChangeInterval,
  businessHours,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultDeliveryWindow,
  configurableSlots = [],
  slotsConfiguration = [],
  onTimeSelectorTrack,
  'data-test-id': dataTestId = 'TimePicker',
  cardId
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const c = useTimePickerStyles()
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )
  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const {selectedCustomer} = customersState
  const minDuration = getDuration(minInterval)

  const [activeTab, setActiveTab] = useState(
    value.earliest === value.latest && hasFixedTime
      ? PickerType.SpecificTime
      : isWholeDay(interval, value)
      ? PickerType.WholeDay
      : PickerType.TimeRange
  )

  useEffect(() => {
    if (isSlotManagementEnabled) {
      const exists = checkIsLimitedSlotAvailability(value)
      setIsLimitedSlotAvailability(exists)
    }
  }, [value])

  useEffect(() => {
    if (isSlotManagementEnabled) {
      setHighlightEarliest(false)
      setHighlightLatest(false)
      setShowError(false)
      setIsNotAvailableSlots(false)

      const exists = checkIsLimitedSlotAvailability(value)
      setIsLimitedSlotAvailability(exists)
    }
  }, [deliveryDate])

  const [isNotAvailableSlots, setIsNotAvailableSlots] = useState(false)
  const [isLimitedSlotAvailability, setIsLimitedSlotAvailability] = useState(false)

  const [highlightEarliest, setHighlightEarliest] = useState(false)
  const [highlightLatest, setHighlightLatest] = useState(false)
  const [showError, setShowError] = useState(false)

  const setWholeDay = () => {
    onChange({
      earliest: formatOutputTime(interval.start),
      latest: formatOutputTime(interval.end)
    })
  }

  const timeRangePickerValueChange = (value: DeliveryTime) => {
    if (isWholeDay(interval, value)) setActiveTab(PickerType.WholeDay)
    else if (activeTab === PickerType.WholeDay) setActiveTab(PickerType.TimeRange)

    if (isSlotManagementEnabled) {
      setHighlightEarliest(false)
      setHighlightLatest(false)
      validateDeliveryTimeWithSlots(value)
    }

    onChange(value)
  }

  const validateDeliveryTimeWithSlots = (value: DeliveryTime) => {
    const isAvalableSlot = existsNonFullSlots(
      value.earliest,
      value.latest,
      minInterval,
      slotsConfiguration,
      configurableSlots
    )

    setIsNotAvailableSlots(!isAvalableSlot)
    setShowError(!isAvalableSlot)
    onError(!isAvalableSlot)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkIsLimitedSlotAvailability = (value: DeliveryTime) => {
    return existsNonFreeSlots(slotsConfiguration, configurableSlots)
  }

  const adjustDeliveryTimeWithSlots = () => {
    let newDeliveryTime = {...value}
    const checkLatestPossible = existsNonFullSlots(
      value.earliest,
      businessHours.latestPossible,
      minInterval,
      slotsConfiguration,
      configurableSlots
    )
    if (checkLatestPossible) {
      newDeliveryTime.latest = formatTime(
        businessHours.latestPossible,
        timezone ?? '',
        DATA_BASE_TIME_FORMAT
      )
    } else {
      const checkEarliestPossible = existsNonFullSlots(
        businessHours.earliestPossible,
        value.latest,
        minInterval,
        slotsConfiguration,
        configurableSlots
      )
      if (checkEarliestPossible) {
        newDeliveryTime.earliest = formatTime(
          businessHours.earliestPossible,
          timezone ?? '',
          DATA_BASE_TIME_FORMAT
        )
      } else {
        newDeliveryTime = {
          earliest: formatTime(
            businessHours.earliestPossible,
            timezone ?? '',
            DATA_BASE_TIME_FORMAT
          ),
          latest: formatTime(businessHours.latestPossible, timezone ?? '', DATA_BASE_TIME_FORMAT)
        }
      }
    }

    if (newDeliveryTime.earliest !== value.earliest) setHighlightEarliest(true)

    if (newDeliveryTime.latest !== value.latest) setHighlightLatest(true)

    onChange(newDeliveryTime)
    validateDeliveryTimeWithSlots(newDeliveryTime)
    trackEvent('hubOrderIntakeSlotAdjustTime', {
      country: userProfile?.country,
      customerId: selectedCustomer?.customerId,
      initiallyRequestedEarliestTime: value.earliest,
      initiallyRequestedLatestTime: value.latest,
      adjustedEarliestTime: newDeliveryTime.earliest,
      adjustedLatestTime: newDeliveryTime.latest,
      cardId: cardId
    })
  }

  const timeSelectorTrack = (eventType: TimeSelectorTrackingEventType) => {
    onTimeSelectorTrack && onTimeSelectorTrack(eventType)
  }

  const getTimeRangePicker = () => {
    return (
      <TimeRangePicker
        value={value}
        onChange={timeRangePickerValueChange}
        onError={onError}
        interval={interval}
        minDuration={minDuration}
        filterTimeSlots={filterTimeSlots}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filterEarliest={(d: Date) => {
          return true
        }}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filterLatest={(d: Date) => {
          return true
        }}
        timeChangeInterval={timeChangeInterval}
        data-test-id={dataTestId}
        onTimeSelectorTrack={timeSelectorTrack}
        highlightEarliest={highlightEarliest}
        highlightLatest={highlightLatest}
        showError={showError}
      />
    )
  }

  return (
    <>
      <TabContext value={activeTab} data-test-id={dataTestId}>
        {!isCurrentTimezone(timezone) && (
          <Box mb={2}>
            <Alert
              severity="warning"
              data-test-id={`${dataTestId}-tz-alert`}
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {differentTimeZoneWarning}
            </Alert>
          </Box>
        )}
        <Box data-test-id={`${dataTestId}-tabs`}>
          <Tabs
            value={activeTab}
            onChange={(_, value: PickerType) => {
              if (value === PickerType.WholeDay) setWholeDay()
              setActiveTab(value)
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered={false}
            color="primary"
            className={c.tabs}
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}
          >
            <Tab
              data-test-id="time-picker-timerange-tab"
              value={PickerType.TimeRange}
              className={c.buttonTab}
              label={t('orderIntake.timePicker.timeRange')}
            />
            <Tab
              data-test-id="time-picker-wholeday-tab"
              value={PickerType.WholeDay}
              className={c.buttonTab}
              label={t('orderIntake.wholeDay')}
            />
            {hasFixedTime && (
              <Tab
                data-test-id="time-picker-fixtime-tab"
                value={PickerType.SpecificTime}
                className={c.buttonTab}
                label={t('orderIntake.timePicker.specificTime')}
              />
            )}
          </Tabs>
        </Box>

        <TabPanel
          className={c.panel}
          value={PickerType.TimeRange}
          data-test-id={`${dataTestId}-tabPanel-timeRangePicker`}
        >
          {getTimeRangePicker()}
        </TabPanel>
        <TabPanel
          className={c.panel}
          value={PickerType.WholeDay}
          data-test-id={`${dataTestId}-tabPanel-wholeDay`}
        >
          {getTimeRangePicker()}
        </TabPanel>
        <TabPanel
          className={c.panel}
          value={PickerType.SpecificTime}
          data-test-id={`${dataTestId}-tabPanel-specificPicker`}
        >
          <SpecificTimePicker
            value={value.earliest}
            interval={interval}
            onChange={(value) => onChange({earliest: value, latest: value})}
            minFixIntervalTime={minFixIntervalTime}
            data-test-id={dataTestId}
          />
        </TabPanel>
        <Stack mt={2} spacing={2}>
          {isSlotManagementEnabled && (
            <Typography variant="body2" data-test-id={`${dataTestId}-note`}>
              <b>{t('orderIntake.timePicker.note')}: </b>
              {t('orderIntake.timePicker.noteDescription', {
                from: timeFormatter(moment(interval.start, 'HH:mm'), language),
                to: timeFormatter(moment(interval.end, 'HH:mm'), language)
              })}
            </Typography>
          )}

          {!isSlotManagementEnabled && (
            <Typography variant="body2" data-test-id={`${dataTestId}-note`}>
              <b>{t('orderIntake.timePicker.note')}: </b>
              {t('orderIntake.timePicker.noteDescription', {
                from: timeFormatter(moment(interval.start, 'HH:mm'), language),
                to: timeFormatter(moment(interval.end, 'HH:mm'), language)
              })}
            </Typography>
          )}

          {isSlotManagementEnabled && (isNotAvailableSlots || isLimitedSlotAvailability) && (
            <Box className={c.notAvailableSlotsWrapper}>
              {isNotAvailableSlots && isSlotManagementEnabled && (
                <Box className={c.notAvailableSlots}>
                  <Typography className={c.notAvailableSlotsText}>
                    {t('orderIntake.timePicker.noSlotAvailability')}
                  </Typography>
                  <Typography
                    className={c.notAvailableSlotsAdjust}
                    onClick={adjustDeliveryTimeWithSlots}
                  >
                    {t('orderIntake.timePicker.adjust')}
                  </Typography>

                  <Tooltip
                    style={{flex: 1}}
                    classes={{tooltip: c.tooltipContent}}
                    title={
                      <SlotsStatus
                        slots={configurableSlots}
                        slotsConfiguration={slotsConfiguration}
                        showLegend={true}
                      />
                    }
                  >
                    <Box display="flex" justifyContent="end">
                      <InfoOutlinedIcon />
                    </Box>
                  </Tooltip>
                </Box>
              )}

              {isLimitedSlotAvailability && !isNotAvailableSlots && isSlotManagementEnabled && (
                <Box className={c.limitedSlotsAvailability}>
                  <Typography className={c.limitedSlotsAvailabilityText}>
                    {t('orderIntake.timePicker.limitedSlotsAvailability')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Stack>
      </TabContext>
    </>
  )
}
