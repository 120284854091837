import {SalesOfficeData} from './declarations/types'

export interface RequestForQuoteFormValues {
  customerName: string
  customerNumber: string
  phoneNumber: string
  projectDetails: RequestForQuoteProjectDetails
  projectType: string
  businessLine: string
  materials: RequestForQuoteMaterial[]
  services: string[]
  poNumber: string
  comment: string
  selectedCountry?: string
  plant?: string
  selectedRegion?: SalesOfficeData
  selectedSupplier?: string
  attachment?: RequestForQuoteAttachment
  isSmartRockEnabled: boolean
  salesOfficeEmail: string
  qcTechOfficeEmail?: string
  supplier?: string
}

export interface MapCoordinates {
  latitude: number
  longitude: number
}

export type PersistedRequestForQuoteFormValues = Omit<
  RequestForQuoteFormValues,
  | 'projectDetails'
  | 'poNumber'
  | 'comment'
  | 'attachment'
  | 'isSmartRockEnabled'
  | 'salesOfficeEmail'
  | 'qcTechOfficeEmail'
>

export interface RequestForQuoteAttachment {
  fileName: string
  fileContent: string
  fileHash: string
  fileExtension: string
}

export interface RequestForQuoteMaterial {
  id: string
  name: string
  quantity: RequestForQuoteMaterialQuantity
  isWithoutAdditives: boolean
}

export interface RequestForQuoteMaterialQuantity {
  amount?: number
  unitOfMeasure: string
}

export enum RequestForQuoteFormStatusType {
  Tender = 0,
  Let = 1
}

export enum RequestForQuoteFormAddressAvailabilityType {
  AddressAvailable = 0,
  AddressNotAvailable = 1
}

export interface RequestForQuoteProjectDetails {
  projectStartDate?: string | null
  projectEndDate?: string | null
  projectDestination: RequestForQuoteProjectDestination
  projectStatus: RequestForQuoteFormStatusType
}

export interface RequestForQuoteProjectDestination {
  mapCoordinates?: MapCoordinates
  name: string
  street: string
  zip: string
  city: string
}

export interface RequestForQuoteResponse {
  detail: string
  status: number
  title: string
  type: string
}
