import {ActionButton, Typography} from '@hconnect/uikit'
import {Box, Button, makeStyles} from '@material-ui/core'
import {Add} from '@mui/icons-material'
import {Hidden} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(() => ({
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  referenceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column-reverse',
    alignItems: 'flex-start'
  }
}))

interface CustomerReferenceFieldProps {
  customerReference: string
  handleEdit: () => void
}

export const CustomerReferenceField: React.FC<CustomerReferenceFieldProps> = ({
  customerReference,
  handleEdit
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [customerReferenceValue, setCustomerReferenceValue] = useState<string | undefined>(
    customerReference
  )

  useEffect(() => {
    setCustomerReferenceValue(customerReference)
  }, [customerReference])

  const buttonContent = (
    <Typography customVariant="formLabel" color="primary">
      {customerReferenceValue
        ? t('orderIntake.customerReferenceInfo.changeCustomerReferenceInfo')
        : t('orderIntake.customerReferenceInfo.setCustomerReferenceInfo')}
    </Typography>
  )
  return (
    <Box data-test-id="customer-reference-info" className={classes.referenceWrapper}>
      {customerReferenceValue ? (
        <Box>
          <Typography variant="subtitle1" breakLine data-test-id="customer-reference-info-text">
            {t('orderIntake.customerReferenceInfo.customerReference')}
          </Typography>

          <Typography variant="body2" breakLine data-test-id="customer-reference-info-text">
            {customerReferenceValue ?? ''}
          </Typography>
        </Box>
      ) : null}
      <Hidden smDown>
        <Box className={classes.buttonWrapper}>
          <Button
            variant="text"
            className={classes.link}
            data-test-id="customer-reference-info-link-button"
            onClick={handleEdit}
          >
            {buttonContent}
          </Button>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box marginTop={1}>
          <ActionButton
            onClick={handleEdit}
            data-test-id="customer-reference-info-link-button"
            icon={<Add color="primary" />}
          >
            {buttonContent}
          </ActionButton>
        </Box>
      </Hidden>
    </Box>
  )
}
