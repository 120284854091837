import {Typography, dateFormatter, timeFormatter, Grid} from '@hconnect/uikit'
import {Box, Card, CardContent, LinearProgress} from '@material-ui/core'
import {Error as ErrorIcon} from '@material-ui/icons'
import moment from 'moment'
import React, {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import {BusinessLineType} from '../../common/types'
import {DeliveryTime} from '../../OrderIntake/declarations/types'

interface Props {
  isLoading: boolean
  itemIndex: string
  loadingLabel: string
  hasError: boolean
  date: string
  time: DeliveryTime
  materialNumber: string
  businessLine: BusinessLineType
  errorMessage?: ReactElement
}

const ProcessingOrderLegacyCard: FC<Props> = ({
  itemIndex,
  isLoading,
  loadingLabel,
  hasError,
  date,
  time,
  materialNumber,
  businessLine,
  errorMessage
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const isTimesEqual = time.earliest === time.latest
  return (
    <Box data-test-id={!hasError ? 'order-processing-card' : 'order-processing-card-error'}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="h4">{itemIndex}</Typography>
        {hasError ? <ErrorIcon color="error" /> : null}
      </Box>

      <Card variant="outlined">
        <CardContent>
          {isLoading ? <Typography>{loadingLabel}</Typography> : null}
          {!isLoading && hasError ? (
            <Grid container justify="space-between" spacing={2}>
              <Grid item>
                <Typography customVariant="formLabel" color="secondary">
                  {t('orderIntake.requestedDate')}
                </Typography>
                <Typography>{dateFormatter(date, language)}</Typography>
              </Grid>
              <Grid item>
                <Typography customVariant="formLabel" color="secondary">
                  {isTimesEqual ? t('orderIntake.specificTime') : t('orderIntake.requestedTime')}
                </Typography>
                <Typography>
                  {isTimesEqual
                    ? timeFormatter(moment(time.earliest, 'HH:mm:ss'), language)
                    : `${timeFormatter(moment(time.earliest, 'HH:mm:ss'), language)} -
                  ${timeFormatter(moment(time.latest, 'HH:mm:ss'), language)}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography customVariant="formLabel" color="secondary">
                  {businessLine === BusinessLineType.AGG
                    ? t('orderIntake.aggregatesType')
                    : t('orderIntake.cementType')}
                </Typography>
                <Typography>{materialNumber}</Typography>
              </Grid>
            </Grid>
          ) : null}
        </CardContent>
        {hasError ? errorMessage : null}
        {isLoading ? <LinearProgress data-test-id="processing-order-loader" /> : null}
      </Card>
    </Box>
  )
}

export {ProcessingOrderLegacyCard}
