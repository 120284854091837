import {useState, useEffect} from 'react'
import countriesData from '../../util/countriesGeoLocation.json'

interface Country {
  country: string
  alpha2: string
  alpha3: string
  numeric: number
  latitude: number
  longitude: number
}

const useCountryCoordinates = (countryCode: string) => {
  const [coordinates, setCoordinates] = useState<{latitude: number; longitude: number} | null>(null)

  useEffect(() => {
    const country = countriesData.find((country: Country) => country.alpha2 === countryCode)
    if (country) {
      setCoordinates({latitude: country.latitude, longitude: country.longitude})
    }
  }, [countryCode])

  return coordinates
}

export default useCountryCoordinates
