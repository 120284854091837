import {ActionButton, Typography} from '@hconnect/uikit'
import {Box, Button, makeStyles} from '@material-ui/core'
import {Add} from '@mui/icons-material'
import {Hidden} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HaulerInfo} from '../../../../OrderIntake/declarations/types'
import {isEmptyObject} from '../../utils'

const useStyles = makeStyles(() => ({
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  referenceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column-reverse',
    alignItems: 'flex-start'
  }
}))

interface HaulerInfoFieldProps {
  haulerInfo: HaulerInfo | undefined
  handleEdit: () => void
}

export const HaulerInfoField: React.FC<HaulerInfoFieldProps> = ({haulerInfo, handleEdit}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const isEmptyHaulier = isEmptyObject(haulerInfo)
  const buttonContent = (
    <Typography customVariant="formLabel" color="primary">
      {!isEmptyHaulier
        ? t('orderIntake.haulerInfo.changeHaulerInfo')
        : t('orderIntake.haulerInfo.setHaulerInfo')}
    </Typography>
  )
  return (
    <Box data-test-id="hauler-info" className={classes.referenceWrapper}>
      <Typography variant="subtitle1" breakLine data-test-id="hauler-info-text">
        {t('orderIntake.haulerInfo.hauler')}
      </Typography>
      {!isEmptyHaulier && haulerInfo && (
        <Box>
          <Typography variant="body2" breakLine data-test-id="hauler-info-text">
            {haulerInfo.driverName ? (
              <span>{`${t('orderIntake.haulerInfo.driver')}: ${haulerInfo.driverName}`}</span>
            ) : null}
            {haulerInfo.driverPhoneNumber ? (
              <span>{` (${haulerInfo.driverPhoneNumber})`}</span>
            ) : null}
            {haulerInfo.truckLicensePlate ? (
              <span>{`, ${t('orderIntake.haulerInfo.truckLicensePlate')}: ${
                haulerInfo.truckLicensePlate
              }`}</span>
            ) : null}
            {haulerInfo.trailerLicensePlate ? (
              <span>{`, ${t('orderIntake.haulerInfo.trailerLicensePlate')}: ${
                haulerInfo.trailerLicensePlate
              }`}</span>
            ) : null}
          </Typography>
        </Box>
      )}
      <Hidden smDown>
        <Box className={classes.buttonWrapper}>
          <Button
            variant="text"
            className={classes.link}
            data-test-id="hauler-info-link-button"
            onClick={handleEdit}
          >
            {buttonContent}
          </Button>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box marginTop={1}>
          <ActionButton
            onClick={handleEdit}
            data-test-id="hauler-info-link-button"
            icon={isEmptyHaulier ? <Add color="primary" /> : ''}
          >
            {buttonContent}
          </ActionButton>
        </Box>
      </Hidden>
    </Box>
  )
}
