import {Typography} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useOrderIntakeStyles} from '../../Hooks/OrderIntake/useOrderIntakeStyles'
import {ShippingType} from '../../OrderIntake/declarations/OrderIntake.enums'
import {
  GroupedMaterialDeliveries,
  MaterialDelivery,
  QuantityType
} from '../../OrderIntake/declarations/types'
import {QuantityUomToSymbolComponent} from '../../Pages/Order/Order.components'
import {SummaryLegacyItem} from './SummaryLegacyItem'

interface SummaryLegacyGroupProps {
  groupedMaterial: GroupedMaterialDeliveries[]
  shippingType: ShippingType
  quantityType: QuantityType
}

export const SummaryLegacyGroup: React.FC<SummaryLegacyGroupProps> = ({
  groupedMaterial,
  shippingType,
  quantityType
}) => {
  const classes = useOrderIntakeStyles()
  const {t} = useTranslation()

  const allSummaryItems = groupedMaterial.flatMap((item) => item.summaryItems)

  const renderSummaryGroup = (group: GroupedMaterialDeliveries, index: number) => {
    return (
      <React.Fragment key={`Material group ${group?.headline?.description || ''}-${index}`}>
        <Typography variant="h4" className={index > 0 ? classes.summaryHeadlineSeparator : ''}>
          {quantityType !== QuantityType.Load && `${t('orderIntake.approximately')} `}
          {`${group.headline.quantity} `}
          {quantityType === QuantityType.Load ? (
            ` ${t('orderIntake.loads', {count: group.headline.quantity})} `
          ) : (
            <QuantityUomToSymbolComponent uom={group.headline.capacityUom} />
          )}
          {` ${group.headline.description}`}
        </Typography>
        {group.summaryItems.map((materialDelivery: MaterialDelivery, index: number) =>
          renderSummaryItem(materialDelivery, index)
        )}
      </React.Fragment>
    )
  }

  const renderSummaryItem = (materialDelivery: MaterialDelivery, index: number) => {
    const indexInAllSummaryItems = allSummaryItems.findIndex(
      (item) => item.id === materialDelivery.id
    )
    const label =
      shippingType === ShippingType.DELIVER
        ? `${(indexInAllSummaryItems + 1).toString()}. ${t('orderIntake.delivery')}`
        : `${(indexInAllSummaryItems + 1).toString()}. ${t(
            'orderIntake.collect.collectSummaryHeadline'
          )} `

    return (
      <SummaryLegacyItem
        key={`Delivery Summary Item ${materialDelivery.id}-${index}`}
        id={materialDelivery.id}
        label={label}
        plant={shippingType === ShippingType.COLLECT ? materialDelivery.plant : ''}
        materialDescription={materialDelivery.materialDescription}
        dateAndTime={materialDelivery.dateAndTime}
        quantity={materialDelivery.quantity}
        quantityType={quantityType}
      />
    )
  }

  return (
    <>
      {groupedMaterial.map((group: GroupedMaterialDeliveries, index: number) =>
        renderSummaryGroup(group, index)
      )}
    </>
  )
}
