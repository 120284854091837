import {HCThemeType} from '@hconnect/uikit'
import {makeStyles, Button, Box} from '@material-ui/core'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import CloseIcon from '@material-ui/icons/Close'
import DateRange from '@material-ui/icons/DateRange'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import PostAddOutlined from '@material-ui/icons/PostAddOutlined'
import {TabContext} from '@material-ui/lab'
import {Divider, Grid, Hidden, Typography} from '@mui/material'
import classnames from 'classnames'
import React, {useEffect, useReducer, useState} from 'react'
import {FormProvider, FormState, useForm, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Dictionary} from '../../../../common/types'
import {useConfirmation} from '../../../../OrderIntake/components/ConfirmationDialog/ConfirmationService'
import {useFeaturesState} from '../../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {BulkOrderFormValues} from '../../../BulkOrdersFormValues'
import {ShippingType} from '../../../declarations/OrderIntake.enums'
import {
  ActiveMaterialDeliveryItem,
  DeliveryTime,
  HaulerInfo,
  TimeSelectorTrackingEventType
} from '../../../declarations/types'

import {
  ApplyButtonContainer,
  CloseButton,
  CollapsedTab,
  CollapsedTabs,
  ContentLegacyBox,
  MobileTab,
  MobileTabs,
  TabPanel
} from './components'
import testIds from './dataTestIds'
import {DateTimeLegacyTab} from './DateTimeLegacyTab'
import {HaulierInfoLegacyTab} from './HaulierInfoLegacyTab'
import {PoTab} from './PoTab'
import {MaterialEditorTabs, MaterialOrderLegacyForm} from './types'
import {getCurrentTab} from './utils'

const useStyles = makeStyles((theme: HCThemeType) => {
  const borderBox = `1px solid ${theme.palette.grey[200]}`
  return {
    tabButton: {
      textTransform: 'none'
    },
    applyButtonContainer: {
      backgroundColor: theme.palette.grey[50],
      borderTop: borderBox,
      flexGrow: 0,
      padding: theme.spacing(2),
      position: 'sticky',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      bottom: -1 * theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(1)
      }
    },
    closeButtonContainer: {
      alignSelf: 'end',
      padding: 5
    }
  }
})

const getIndicatorClassName = (
  formState: FormState<MaterialOrderLegacyForm>,
  path: keyof MaterialOrderLegacyForm
) => {
  if (formState.errors[path]) return 'error'
  if (formState.dirtyFields[path]) return 'dirty'
  return undefined
}
const Indicator: React.FC<{state: FormState<MaterialOrderLegacyForm>; path: keyof MaterialOrderLegacyForm}> = (
  p
) => <span className={classnames('indicator', getIndicatorClassName(p.state, p.path))}></span>

type Props = {
  index: number // the index of the form
  shippingType: ShippingType
  showPoTab: boolean
  activeItem?: ActiveMaterialDeliveryItem
  closeActiveItem: () => void
  onDateChange: (date: string) => void
  onTimeChange: (date: DeliveryTime) => void
  onHaulerInfoChange?: (haulerInfo?: HaulerInfo) => void
  onCustomerReferenceChange?: (customerReference: string) => void
  onChange: (data: MaterialOrderLegacyForm) => void
  onTimeSelectorTrack?: (events: Dictionary<number>) => void
}

// eslint-disable-next-line complexity
export const MaterialOrderLegacyEditor: React.FC<Props> = ({
  index,
  activeItem,
  showPoTab,
  closeActiveItem,
  onDateChange,
  onTimeChange,
  onHaulerInfoChange,
  onCustomerReferenceChange,
  onChange,
  shippingType,
  onTimeSelectorTrack
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {getFeature} = useFeaturesState()
  const confirm = useConfirmation()
  const [activeTab, setActiveTab] = useState(getCurrentTab(activeItem))
  useEffect(() => {
    setActiveTab(getCurrentTab(activeItem))
  }, [activeItem])
  const parentForm = useFormContext<BulkOrderFormValues>()

  const orderPath = `orders.${index}` as const
  const selectedSite = parentForm.watch('selectedSite')
  const order = parentForm.getValues(orderPath)

  const [timeSelectorEvents, setTimeSelectorEvents] = useState<Dictionary<number>>()

  const methods = useForm<MaterialOrderLegacyForm>({
    mode: 'onChange',
    defaultValues: {
      dateTime: {
        deliveryDate: order?.payload.deliveryDate,
        deliveryTime: order?.payload.deliveryTime
      },
      haulerInfo: {
        driverName: order?.payload.haulerInfo?.driverName,
        driverPhoneNumber: order?.payload.haulerInfo?.driverPhoneNumber,
        trailerLicensePlate: order?.payload.haulerInfo?.trailerLicensePlate,
        truckLicensePlate: order?.payload.haulerInfo?.truckLicensePlate
      },
      customerReference: order?.payload.customerReference
    }
  })
  const defaultMaterial = selectedSite.materials[order?.payload.materialEnteredNumber][0]
  const [isItemOpen, hideItem] = useReducer(() => false, true)
  useEffect(() => hideItem(), [])

  const {slotConfiguration} = useBulkCementOrderIntake()

  const submit = methods.handleSubmit((data) => {
    closeActiveItem()
    // to call more fieldArray methods one after another is problem. we should do it at once.
    // needs rework when more are handled by single submit
    if (methods.formState.dirtyFields.dateTime?.deliveryTime) {
      onTimeChange(data.dateTime.deliveryTime)
    }
    if (methods.formState.dirtyFields.dateTime?.deliveryDate) {
      onDateChange(data.dateTime.deliveryDate)
    }

    if (methods.formState.dirtyFields.haulerInfo) {
      onHaulerInfoChange && onHaulerInfoChange(data.haulerInfo)
    }

    if (methods.formState.dirtyFields.customerReference) {
      onCustomerReferenceChange && onCustomerReferenceChange(data.customerReference)
    }

    onTimeSelectorTrack && timeSelectorEvents && onTimeSelectorTrack(timeSelectorEvents)

    onChange(data)
  })

  const handleCloseActiveItem = () => {
    if (methods.formState.isDirty) {
      void confirm({
        'data-test-id': testIds.confirmDialog,
        title: t('orderIntake.materialOrder.confirmDialog.title'),
        submitButtonTitle: t('orderIntake.materialOrder.confirmDialog.submitButton'),
        cancelButtonTitle: t('orderIntake.materialOrder.confirmDialog.cancelButton'),
        variant: 'discard'
      }).then(() => {
        closeActiveItem()
      })
    } else {
      closeActiveItem()
    }
  }

  const timeSelectorTrack = (eventType: TimeSelectorTrackingEventType) => {
    const eventsCopy = {...timeSelectorEvents}
    const count = eventsCopy[eventType] ?? 0
    eventsCopy[eventType] = count + 1

    setTimeSelectorEvents(eventsCopy)
  }

  const getDateTimeError = () => {
    return getIndicatorClassName(methods.formState, 'dateTime') === 'error'
  }

  if (!order) return null
  return (
    <form onSubmit={submit}>
      <FormProvider {...methods}>
        <TabContext value={activeTab}>
          <Hidden mdDown>
            <CollapsedTabs
              value={activeTab}
              onChange={(e, value) => setActiveTab(value as MaterialEditorTabs)}
            >
              <CollapsedTab
                value={MaterialEditorTabs.Date}
                selected={activeTab === MaterialEditorTabs.Date}
                data-test-id={testIds.tabs.dateTime}
                label={
                  <Typography className={classes.tabButton} variant="body2" color="text.primary">
                    <DateRange />{' '}
                    {activeTab === MaterialEditorTabs.Date &&
                      t('orderIntake.materialOrder.dateTime')}
                    <Indicator state={methods.formState} path="dateTime" />
                  </Typography>
                }
              />
              {shippingType === ShippingType.COLLECT &&
                getFeature('OrderIntakeHaulierInformation') && (
                  <CollapsedTab
                    value={MaterialEditorTabs.Haulier}
                    selected={activeTab === MaterialEditorTabs.Haulier}
                    data-test-id={testIds.tabs.haulier}
                    label={
                      <Typography
                        className={classes.tabButton}
                        variant="body2"
                        color="text.primary"
                      >
                        <LocalShippingOutlinedIcon />{' '}
                        {activeTab === MaterialEditorTabs.Haulier &&
                          t('orderIntake.materialOrder.haulierInfo')}
                        <Indicator state={methods.formState} path="haulerInfo" />
                      </Typography>
                    }
                  />
                )}
              {showPoTab && (
                <CollapsedTab
                  value={MaterialEditorTabs.Po}
                  selected={activeTab === MaterialEditorTabs.Po}
                  data-test-id={testIds.tabs.po}
                  label={
                    <Typography className={classes.tabButton} variant="body2" color="text.primary">
                      <PostAddOutlined />{' '}
                      {activeTab === MaterialEditorTabs.Po && t('orderIntake.materialOrder.po')}
                      <Indicator state={methods.formState} path="customerReference" />
                    </Typography>
                  }
                />
              )}
            </CollapsedTabs>
          </Hidden>
          <Hidden mdUp>
            <MobileTabs>
              <MobileTab
                selected={activeTab === MaterialEditorTabs.Date}
                onClick={() => setActiveTab(MaterialEditorTabs.Date)}
                data-test-id={testIds.tabs.dateTime}
                label={
                  <Typography className={classes.tabButton} variant="body2" color="text.primary">
                    {t('orderIntake.materialOrder.dateTime')}
                    <Indicator state={methods.formState} path="dateTime" />
                  </Typography>
                }
              />
              {shippingType === ShippingType.COLLECT &&
                getFeature('OrderIntakeHaulierInformation') && (
                  <MobileTab
                    selected={activeTab === MaterialEditorTabs.Haulier}
                    onClick={() => setActiveTab(MaterialEditorTabs.Haulier)}
                    data-test-id={testIds.tabs.haulier}
                    label={
                      <Typography
                        className={classes.tabButton}
                        variant="body2"
                        color="text.primary"
                      >
                        {t('orderIntake.materialOrder.haulierInfo')}
                        <Indicator state={methods.formState} path="haulerInfo" />
                      </Typography>
                    }
                  />
                )}
              {showPoTab && (
                <MobileTab
                  selected={activeTab === MaterialEditorTabs.Po}
                  onClick={() => setActiveTab(MaterialEditorTabs.Po)}
                  data-test-id={testIds.tabs.po}
                  label={
                    <Typography className={classes.tabButton} variant="body2" color="text.primary">
                      {t('orderIntake.materialOrder.po')}
                      <Indicator state={methods.formState} path="customerReference" />
                    </Typography>
                  }
                />
              )}
            </MobileTabs>

            <Grid item xs={12} marginTop="-10px" marginBottom="10px">
              <Divider orientation="horizontal" />
            </Grid>
          </Hidden>
          <ContentLegacyBox>
            <Box className={classes.closeButtonContainer}>
              <CloseButton
                data-test-id={testIds.closeBtn}
                aria-label="Close"
                onClick={handleCloseActiveItem}
              >
                <CloseIcon />
              </CloseButton>
            </Box>
            <TabPanel value={MaterialEditorTabs.Date} data-test-id={testIds.tabPanel.dateTime}>
              <DateTimeLegacyTab
                selectedSite={selectedSite}
                slotConfigurations={slotConfiguration}
                order={order}
                shippingType={shippingType}
                initiallyOpenedItem={isItemOpen ? activeItem : undefined}
                defaultMaterialOption={defaultMaterial}
                onTimeSelectorTrack={timeSelectorTrack}
              />
            </TabPanel>
            <TabPanel value={MaterialEditorTabs.Haulier} data-test-id={testIds.tabPanel.haulier}>
              <HaulierInfoLegacyTab />
            </TabPanel>
            <TabPanel value={MaterialEditorTabs.Po} data-test-id={testIds.tabPanel.po}>
              <PoTab />
            </TabPanel>
            <ApplyButtonContainer>
              <Button
                type="submit"
                data-test-id={testIds.submitBtn}
                fullWidth
                color="primary"
                disabled={!methods.formState.isValid || getDateTimeError()}
              >
                <ArrowRightAlt />
                {t('orderIntake.materialOrder.apply')}
              </Button>
            </ApplyButtonContainer>
          </ContentLegacyBox>
        </TabContext>
      </FormProvider>
    </form>
  )
}
