import * as React from 'react'

import {ConfirmationDialog, ConfirmationOptions} from './ConfirmationDialog'

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
  // eslint-disable-next-line @typescript-eslint/unbound-method
>(Promise.reject)

export const useConfirmation = () => React.useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({children}) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = {resolve, reject}
    })
  }

  const handleCancel = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        {...confirmationState}
      />
    </>
  )
}
