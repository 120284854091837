import {Typography, ActionButton} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

// TODO container should not be dependent on Deliver module
import {DriverInstructionsPopover} from '../../Deliver/DriverInstructionsPopover'

interface DriverInstructionsProps {
  instruction: string | null | undefined
  disabled?: boolean
}

export const DriverInstructions: React.FC<DriverInstructionsProps> = ({instruction, disabled}) => {
  const {t} = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" data-test-id="driver-instructions">
      <Typography variant="caption" breakLine data-test-id="driver-instructions-text">
        {instruction ? instruction : t('orderIntake.deliveryAccessClear')}
      </Typography>
      <Box display="flex" justifyContent="flex-start">
        <ActionButton
          data-test-id="driver-instructions-link-button"
          onClick={(e) => {
            e.preventDefault()
            setIsModalOpen(true)
          }}
          disabled={disabled}
        >
          {instruction ? t('orderIntake.change') : t('orderIntake.somethingWeShouldKnow')}
        </ActionButton>
      </Box>
      <DriverInstructionsPopover
        data-test-id="driver-instruction-popover"
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </Box>
  )
}
