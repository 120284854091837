import {Typography} from '@hconnect/uikit'
import {Box, makeStyles, Theme} from '@material-ui/core'
import {AxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePostOrderRequestDelivery} from '../../../common/react-query/hooks/mutations/usePostOrderRequestDelivery'
import {BusinessLineType} from '../../../common/types'
import {
  OrderRequest,
  OrderRequestResponse,
  ShippingAddress
} from '../../../OrderIntake/declarations/types'
import {useBranding} from '../../../Organisms/Branding'
import {useFeaturesState} from '../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {ProcessingOrderLegacyCard} from '../ProcessingOrderLegacyCard'

import {DeliveryOrderSummaryLegacyItem} from './DeliveryOrderSummaryLegacyItem'

interface Props {
  delivery: OrderRequest
  itemOrder: string
  businessLine: BusinessLineType
  shippingAddress?: ShippingAddress
  onError: (error: AxiosError) => void
  customErrorMessage?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    background: theme.palette.error.main,
    padding: theme.spacing(2)
  }
}))

export const DeliveryProcessingOrderLegacyCard: React.FC<Props> = ({
  delivery,
  itemOrder,
  businessLine,
  shippingAddress,
  onError,
  customErrorMessage
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {dispatchPhoneNumber} = useBranding()
  const {setOrderSummary} = useBulkCementOrderIntake()
  const [orderSummary, setOrderSummaryLocal] = useState<OrderRequestResponse>()
  const {mutate, isLoading, isError} = usePostOrderRequestDelivery()

  const {getFeature} = useFeaturesState()
  const isConfigurableSlots = getFeature('OrderIntakeUseConfigurableSlots')
  const isAutoConfirmed = getFeature('OrderIntakeSlotsDeliverAutoConfirmedOrderingWhenFree')

  useEffect(() => {
    mutate(
      {orderIntakePayload: delivery.payload, isConfigurableSlots, isAutoConfirmed},
      {
        onSuccess: (data) => {
          setOrderSummaryLocal(data)
          setOrderSummary(data)
        },
        onError: (error) => {
          onError(error as AxiosError)
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery])
  return (
    <>
      {isLoading || isError ? (
        <ProcessingOrderLegacyCard
          itemIndex={itemOrder}
          isLoading={isLoading}
          loadingLabel={t('orderIntake.processingOrder')}
          date={delivery.payload.deliveryDate}
          time={delivery.payload.deliveryTime}
          materialNumber={delivery.payload.materialNumber}
          businessLine={businessLine}
          hasError={isError}
          errorMessage={
            <Box className={classes.errorContainer}>
              <Typography variant="h3" customColor="textErrorContrastText">
                {t('orderIntake.orderFailed')}
              </Typography>
              {customErrorMessage ? (
                <Typography variant="body2" customColor="textErrorContrastText">
                  {customErrorMessage}
                </Typography>
              ) : null}
              <Typography variant="h3" customColor="textErrorContrastText">
                {dispatchPhoneNumber
                  ? t('orderIntake.contactByPhone', {phoneNumber: dispatchPhoneNumber})
                  : t('orderIntake.contactByPhoneWithoutNumber')}
              </Typography>
              <Typography variant="body2" customColor="textErrorContrastText">
                {t('orderIntake.errorMessage')}
              </Typography>
            </Box>
          }
        />
      ) : orderSummary ? (
        <Box
          key={`Delivery-Item-Order-Confirmation-${orderSummary.siteNumber}-${orderSummary.requestData.material.materialNumber}-${itemOrder}`}
        >
          <DeliveryOrderSummaryLegacyItem
            itemIndex={itemOrder}
            orderRequestStatus={orderSummary.status}
            quantity={orderSummary.requestData.quantity}
            quantityType={delivery.payload.capacity.quantityType}
            quantityUom={orderSummary.requestData.quantityUom}
            deliveryDate={orderSummary.requestData.deliveryDate}
            deliveryTime={orderSummary.requestData.deliveryTime}
            materialDescription={orderSummary.requestData.material.materialDescription}
            shippingAddress={shippingAddress}
            driverInstructions={orderSummary.requestData.additionalDriverInfo.driverInstructions}
          />
        </Box>
      ) : null}
    </>
  )
}
