import {Box, Button, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {format} from 'date-fns'
import {identity} from 'lodash'
import moment from 'moment'
import React, {useLayoutEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {convertToMinutes, getTimeSlots, parseTime} from '../../../util/time'
import {isTwelveHoursFormat, timeFormatter} from '../TimeScroller/TimeScroller.utils'

import {useSpecificTimePickerStyles} from './styles'

type Props = {
  interval: Interval
  value: string
  onChange: (value: string) => void
  minFixIntervalTime?: string
  filterTimeSlots?: (d: Date, index: number, dates: Date[]) => boolean
  'data-test-id'?: string
}

export const SpecificTimePicker: React.FC<Props> = ({
  value,
  interval,
  minFixIntervalTime,
  onChange,
  filterTimeSlots,
  'data-test-id': dataTestId = 'specificTimePicker'
}) => {
  const {
    i18n: {language}
  } = useTranslation()

  const c = useSpecificTimePickerStyles()
  const hourContainerRef = useRef<HTMLDivElement>()
  const slots = getTimeSlots(interval, convertToMinutes(minFixIntervalTime)).filter(
    filterTimeSlots ?? identity
  )

  const isTwelveHours = isTwelveHoursFormat(language)

  const getHourText = (h: number) => {
    const hourText = h.toString().padStart(2, '0')

    if (isTwelveHours) {
      return timeFormatter(moment(hourText, 'HH:mm:ss'), language)
    }

    return hourText + ':00'
  }

  const getMinutesText = (m: Date) => {
    if (isTwelveHours) {
      return timeFormatter(moment(m, 'HH:mm:ss'), language).replace('AM', '').replace('PM', '')
    }

    return format(m, 'HH:mm')
  }

  const currentTime = parseTime(value)
  const hourBuckets = slots.reduce((result, item) => {
    return result.add(item.getHours())
  }, new Set<number>())
  useLayoutEffect(() => {
    hourContainerRef.current?.querySelector('.active')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }, [])
  const scrollLeft = () => hourContainerRef.current?.scrollBy({left: -100, behavior: 'smooth'})
  const scrollRight = () => hourContainerRef.current?.scrollBy({left: 100, behavior: 'smooth'})
  return (
    <Grid container spacing={3} data-test-id={dataTestId}>
      <Grid item xs={12}>
        {/* when upgrade to mui, we can use ref normal way */}
        <Box {...{ref: hourContainerRef}} className={classNames(c.hourViewBoxContainer, 'left')}>
          <div className={classNames(c.hourGradient, 'left')}></div>
          <Button
            onClick={scrollLeft}
            className={classNames(c.hourScrollButton, 'left')}
            data-test-id={`${dataTestId}-leftBtn`}
          >
            {'<'}
          </Button>
          {Array.from(hourBuckets).map((h) => {
            const hourText = h.toString().padStart(2, '0')
            return (
              <Box
                key={h}
                tabIndex={0}
                className={classNames(c.hourViewBox, {active: h === currentTime.hours})}
                onClick={() => onChange(`${hourText}:00:00`)}
                data-test-id={`${dataTestId}-time-bucket`}
              >
                <Typography>{getHourText(h)}</Typography>
              </Box>
            )
          })}
          <div className={classNames(c.hourGradient, 'right')}></div>
          <Button
            onClick={scrollRight}
            className={classNames(c.hourScrollButton, 'right')}
            data-test-id={`${dataTestId}-rightBtn`}
          >
            {'>'}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={c.slotViewContainer} data-test-id={`${dataTestId}-slots`}>
          {slots
            .filter((s) => s.getHours() === currentTime.hours)
            .map((m) => (
              <Box
                tabIndex={0}
                key={m.toTimeString()}
                className={classNames(c.slotViewBox, {
                  active:
                    m.getHours() === currentTime.hours && m.getMinutes() === currentTime.minutes
                })}
                onClick={() => onChange(format(m, 'HH:mm:ss'))}
              >
                <Typography variant="h4">{getMinutesText(m)}</Typography>
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  )
}
