import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import * as React from 'react'

const useStyles = makeStyles((theme) => ({
  infoButtonStyle: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  discardButtonStyle: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  }
}))

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  submitButtonTitle?: string
  cancelButtonTitle?: string
  title?: string
  variant?: 'discard' | 'info'
  'data-test-id'?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  submitButtonTitle,
  cancelButtonTitle,
  title,
  variant,
  onSubmit,
  onCancel,
  'data-test-id': dataTestId = 'confirmationDialog'
}) => {
  const classes = useStyles()

  const getSubmitIcon = () => {
    switch (variant) {
      case 'discard':
        return <DeleteOutlineIcon />
      case 'info':
        return <CheckIcon />
    }
  }

  const getSubmitClassName = () => {
    switch (variant) {
      case 'discard':
        return classes.discardButtonStyle
      case 'info':
        return classes.infoButtonStyle
    }
  }

  return (
    <Dialog open={open} data-test-id={dataTestId}>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2" component="span" color="textPrimary">
            {title}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          size="small"
          startIcon={<CloseIcon />}
          data-test-id={`${dataTestId}-cancelBtn`}
        >
          <Typography variant="caption" component="span" color="textPrimary">
            {cancelButtonTitle}
          </Typography>
        </Button>

        <Button
          color="secondary"
          size="small"
          className={getSubmitClassName()}
          onClick={onSubmit}
          startIcon={getSubmitIcon()}
          data-test-id={`${dataTestId}-submitBtn`}
        >
          <Typography variant="caption" component="span" color="textPrimary">
            {submitButtonTitle}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
