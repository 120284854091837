import {first} from 'lodash'
import {useFormContext, UseFormReturn} from 'react-hook-form'

import {trackEvent} from '../../common/analytics'
import {Dictionary} from '../../common/types'
import {useFeaturesState} from '../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {checkEmptyTimeZone, getCurrentTimeStamp} from '../../Organisms/OrderIntake/utils'
import {BulkOrderFormValues} from '../BulkOrdersFormValues'
import {
  DeliveryTime,
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOptions,
  OrderRequest,
  TimeSelectorTrackingOrderEvent
} from '../declarations/types'

import dateChangeSpec from './DateChangeSpecification'
import materialChangeSpec from './MaterialChangeSpecification'
import siteChangeSpec from './SiteChangeSpecification'

export const useBulkOrderFormChangeHandlerWithMethods = (
  methods: UseFormReturn<BulkOrderFormValues, object>
) => {
  const {watch, setValue, trigger} = methods
  const {getFeature} = useFeaturesState()

  const {slotConfiguration} = useBulkCementOrderIntake()

  return {
    onMaterialChange: (orderIndex: number, item: OrderIntakeMaterialOptionPayload) => {
      const {orderRequest, isOrderRequestConfirmed} = materialChangeSpec(
        watch(`orders.${orderIndex}`),
        watch('selectedSite'),
        item,
        slotConfiguration
      )

      // update form
      setValue(`orders.${orderIndex}`, orderRequest)
      setValue('isOrderRequestConfirmed', isOrderRequestConfirmed)
      setValue(`selectedMaterial.${orderIndex}`, item)
      trackEvent('hubOrderIntakeMaterialChanged')
    },
    onDateChange: (orderIndex: number, deliveryDate: string) => {
      const {isOrderRequestConfirmed, orderRequest} = dateChangeSpec(
        watch(`orders.${orderIndex}`),
        watch('selectedSite'),
        deliveryDate,
        !getFeature('OrderIntakeCollectTimePicker')
      )
      setValue(`orders.${orderIndex}`, orderRequest)
      setValue('isOrderRequestConfirmed', isOrderRequestConfirmed)
    },
    onGlobalCustomerRefChange: (customerRef: string) => {
      const orders = watch('orders')
      orders.forEach((o, idx) => setValue(`orders.${idx}.payload.customerReference`, customerRef))
      void trigger('orders')
    },
    onGlobalEmailCcChange: (emailAddress: string) => {
      setValue('contact.carbonCopyEmail', emailAddress)
      void trigger('contact')
    },
    onSiteChange: (
      siteNumber: string,
      siteOptions: OrderIntakeOptions,
      defaultOrderRequest: OrderRequest
    ) => {
      const formData = watch()
      if (siteNumber === formData.selectedSite.shippingAddress.siteNumber) return
      const result = siteChangeSpec(
        formData,
        watch('orders'),
        siteOptions,
        siteNumber,
        defaultOrderRequest,
        slotConfiguration
      )
      setValue('selectedSite', result.selectedSite)
      setValue('orders', result.orders)
      setValue('isOrderRequestConfirmed', result.isOrderRequestConfirmed)

      setValue('contact.mainPhone', result.selectedSite.contact.mainPhone)
      setValue('contact.backupPhone', result.selectedSite.contact.backupPhone)
      void trigger('contact')

      trackEvent('hubOrderIntakeShiptoChanged')
    },
    onConfirmOrderRequest: () => {
      setValue('isOrderRequestConfirmed', true)
      setValue(
        'orders',
        watch('orders').map((o) => ({...o, isMaterialHighlighted: false, isDateChange: false}))
      )
      trackEvent('hubOrderIntakePersistencyConfirmed')
    },
    onTimeChange: (orderIndex: number, orderTime: DeliveryTime) => {
      setValue(`orders.${orderIndex}.payload.deliveryTime`, orderTime)
      setValue('isOrderRequestConfirmed', true)
    },
    onQuantityChange: (orderIndex: number, quantity: number) => {
      if (quantity <= 0) return
      setValue(`orders.${orderIndex}.payload.capacity.quantity`, quantity)
      setValue('isOrderRequestConfirmed', true)
    },
    onTimeSelectorTrackApply: (
      orderIndex: number,
      editorActivationTimeStamp: string | undefined,
      isSlotsManagementEnabled: boolean,
      deliveryId: string,
      events: Dictionary<number>
    ) => {
      const formData = watch()
      const orderPath = `orders.${orderIndex}` as const
      const order = methods.getValues(orderPath)
      const defaultMaterialOption = first(
        formData.selectedSite.materials[order.payload.materialEnteredNumber]
      )
      const timeZone = defaultMaterialOption?.businessHours.timeZone

      const timeTrackingData = order.timeTrackingData
        ? order.timeTrackingData
        : ([] as TimeSelectorTrackingOrderEvent[])

      if (editorActivationTimeStamp && events) {
        const timeTrackingDataItem = {} as TimeSelectorTrackingOrderEvent
        timeTrackingDataItem.cardId = deliveryId + '_' + (timeTrackingData.length + 1)
        timeTrackingDataItem.startEditingTimeStamp = editorActivationTimeStamp
        timeTrackingDataItem.applyChangesTimeStamp = getCurrentTimeStamp(
          checkEmptyTimeZone(timeZone)
        )
        timeTrackingDataItem.events = events
        timeTrackingDataItem.slotManaged = isSlotsManagementEnabled
        timeTrackingData.push(timeTrackingDataItem)
      }

      setValue(`orders.${orderIndex}.timeTrackingData`, timeTrackingData)
    }
  }
}

export const useBulkOrderFormChangeHandler = () => {
  const methods = useFormContext<BulkOrderFormValues>()
  return useBulkOrderFormChangeHandlerWithMethods(methods)
}
