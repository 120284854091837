import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography
} from '@material-ui/core'
import React from 'react'
import {useWatch, useController} from 'react-hook-form'

export type CheckboxGroupConfig = {
  value: string
  label: string
}

type Props = {
  control: any
  label: string
  name: string
  options: any
  row: boolean
  config: CheckboxGroupConfig
  'data-test-id'?: string
}

export const CheckboxGroup: React.FC<Props> = ({
  config,
  control,
  label,
  name,
  options,
  row,
  'data-test-id': dataTestId
}) => {
  const {
    field: {ref, value, onChange, ...inputProps},
    formState: {errors}
  } = useController({
    name,
    control,
    defaultValue: []
  })

  const checkboxIds = useWatch({control, name: name}) || []

  const handleChange = (value) => {
    const newArray = [...checkboxIds]
    const item = value

    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item)

      if (index === -1) {
        newArray.push(item)
      } else {
        newArray.splice(index, 1)
      }
    } else {
      newArray.push(item)
    }

    onChange(newArray)
  }

  return (
    <div>
      <FormControl>
        {label && <Typography>{label}</Typography>}
        <FormGroup row={row}>
          {options.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  data-test-id={`${dataTestId}-${option[config.value]}`}
                  checked={value?.some((checked) => checked === option[config.value])}
                  {...inputProps}
                  inputRef={ref}
                  onChange={() => handleChange(option[config.value])}
                />
              }
              label={<p className="body2">{option[config.label]}</p>}
              key={option[config.value]}
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormHelperText error variant="outlined">
        <>{errors?.[name]?.message || ' '}</>
      </FormHelperText>
    </div>
  )
}

export default CheckboxGroup
