import {Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HaulerInfo} from '../../declarations/types'

interface HaulerInfoFieldProps {
  haulerInfo: HaulerInfo
}

export const HaulerInfoSummaryItem: React.FC<HaulerInfoFieldProps> = ({haulerInfo}) => {
  const {t} = useTranslation()

  return (
    <Box data-test-id="haulier-info" display="flex" flexDirection="column" maxWidth="100%">
      <Typography variant="body2" data-test-id="haulier-info-name">
        {haulerInfo.driverName}
      </Typography>
      <Typography variant="body2" data-test-id="haulier-info-phone">
        {haulerInfo.driverPhoneNumber}
      </Typography>
      {haulerInfo.truckLicensePlate && (
        <Typography variant="body2" data-test-id="haulier-info-plate">{`${t(
          'orderIntake.haulerInfo.truck'
        )}: ${haulerInfo.truckLicensePlate}`}</Typography>
      )}
      {haulerInfo.trailerLicensePlate && (
        <Typography variant="body2" data-test-id="haulier-info-trailer-plate">{`${t(
          'orderIntake.haulerInfo.trailer'
        )}: ${haulerInfo.trailerLicensePlate}`}</Typography>
      )}
    </Box>
  )
}
