import {Typography, ActionButton} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import {Close, Check} from '@material-ui/icons'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import {Box, Grid, IconButton, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const useStyles = makeStyles((theme: Theme) => ({
  materialPersistencyBanner: {
    position: 'relative',
    background: '#D8F1F1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px'
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: '20px'
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    [theme.breakpoints.up('md')]: {
      top: '10px'
    },
    top: '6px'
  }
}))

export enum PersistedMaterialsAction {
  ShowMaterials = 'ShowMaterials',
  ApplyMaterials = 'ApplyMaterials',
  NoAction = 'NoAction'
}

interface Props {
  existsValidMaterials: boolean
  onClose: (action: PersistedMaterialsAction) => void
}

export const PersistedMaterialsBanner: React.FC<Props> = ({existsValidMaterials, onClose}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Grid item xs={12} marginTop={3} data-test-id="persisted-materials-banner">
      <Grid container className={classes.materialPersistencyBanner}>
        <Grid item xs={12} md={6} marginRight={2}>
          <Typography>{t('orderIntake.materialPersistency.reorder')}</Typography>
        </Grid>

        <Grid item xs={12} md={5}>
          <Box className={classes.actionButtons}>
            {existsValidMaterials && (
              <ActionButton
                data-test-id="persisted-materials-banner-yes-button"
                onClick={() => onClose(PersistedMaterialsAction.ApplyMaterials)}
                style={{border: 'none', boxShadow: 'none'}}
                icon={<Check color="primary" />}
              >
                {t('orderIntake.materialPersistency.yes')}
              </ActionButton>
            )}

            <ActionButton
              data-test-id="persisted-materials-banner-view-last-order-button"
              onClick={() => onClose(PersistedMaterialsAction.ShowMaterials)}
              style={{border: 'none', boxShadow: 'none'}}
              icon={<FormatListBulleted color="primary" />}
            >
              {t('orderIntake.materialPersistency.viewLastOrder')}
            </ActionButton>
          </Box>
        </Grid>

        <Box className={classes.closeButton}>
          <IconButton
            data-test-id="persisted-materials-banner-close-button"
            onClick={() => onClose(PersistedMaterialsAction.NoAction)}
            style={{border: 'none', boxShadow: 'none'}}
          >
            <Close />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}
