import {DataScope, FeatureConstraint} from '../../Organisms/Features'
import {Role} from '../../Roles/Roles.types'
import {getRolesForCustomer} from '../../Roles/Roles.utils'

const isEnabled = (c: FeatureConstraint) => !!c.enabled
const isCorrectBusinessLine = (d: DataScope, r: Role) => d.businessLine === r.dataScope.businessLine
const isCorrectCountry = (d: DataScope, roles: Role[]) =>
  d.countryId ? roles.some((r) => r.dataScope.countryId === d.countryId) : true
const isCorrectRole = (d: DataScope, roles: Role[]) =>
  d.roleType ? roles.some((r) => r.roleType === d.roleType && isCorrectBusinessLine(d, r)) : true

export const evaluateFeatureFlag = (
  roles: Role[],
  featureConstraints: FeatureConstraint[],
  customerId: string
) => {
  if (!customerId) return false

  const rolesToEvaluate = getRolesForCustomer(roles, customerId)
  return featureConstraints.some((c) => {
    let dataScope: DataScope
    if (typeof c.dataScope === 'string') {
      dataScope = JSON.parse(c.dataScope) as DataScope
    } else {
      dataScope = c.dataScope
    }
    return (
      isEnabled(c) &&
      isCorrectCountry(dataScope, rolesToEvaluate) &&
      isCorrectRole(dataScope, rolesToEvaluate)
    )
  })
}
