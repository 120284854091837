import {SingleDatePicker} from '@hconnect/uikit'
import moment, {Moment} from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BusinessHours, DateRange} from '../../../OrderIntake/declarations/types'
import {
  checkCutOffTimeDates,
  getTodaysCutOffTime,
  isCurrentTimeAfterCutOff
} from '../../../Organisms/OrderIntake/utils'

export const getDateExceptions = (businessHours: BusinessHours, dateRange?: DateRange) => {
  const businessDays = dateRange?.businessDays ? dateRange?.businessDays : []
  if (businessDays.length > 0) {
    const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
    const cutOffTimeStamp = todaysCutOffTime?.timestamp

    const isAfterCutoffTime = isCurrentTimeAfterCutOff(cutOffTimeStamp, businessHours.timeZone)
    const cutOffDates = checkCutOffTimeDates(dateRange, businessHours.timeZone)

    const publicHolidayDates = dateRange?.exceptions?.publicHolidays?.map(
      (publicHoliday) => publicHoliday.date
    )
    const weekends = dateRange?.exceptions?.weekends
    const excludedDates = dateRange?.exceptions?.excludedDates

    const dateExceptions = [
      ...(publicHolidayDates ?? []),
      ...(weekends ?? []),
      ...(excludedDates ?? []),
      ...(isAfterCutoffTime ? cutOffDates ?? [] : [])
    ].map((exception) => moment(exception))

    return dateExceptions
  }
  return []
}

interface DateFilterType {
  isDateChange: boolean
  value: string
  setFilter?: (date: Moment) => void
  businessHours: BusinessHours
  dateRange?: DateRange
  customMessage?: string | null
  labelClass?: string
  iconClass?: string
  open?: boolean
}

export const DateFilter: React.FC<DateFilterType> = ({
  isDateChange,
  value,
  setFilter,
  businessHours,
  dateRange,
  customMessage,
  labelClass,
  iconClass,
  ...props
}) => {
  const {t} = useTranslation()
  const dateExceptions = getDateExceptions(businessHours, dateRange)

  if (!value) return null

  return (
    <SingleDatePicker
      onLight
      showDatePickerButton
      noQuickSelection
      showTooltip={isDateChange}
      tooltipLabel={t('orderIntake.dateChanged')}
      data-test-id="order-intake-filter-datepicker"
      {...(customMessage && {customMessage})}
      handleDateChange={({startDate}) => {
        if (!startDate) return
        setFilter && setFilter(startDate)
      }}
      date={moment(value)}
      labelClass={labelClass}
      iconClass={iconClass}
      {...(dateRange && {
        availableDateRange: {
          from: moment(dateRange.from),
          to: moment(dateRange.to)
        },
        dateExceptions
      })}
      {...props}
    />
  )
}
