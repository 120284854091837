import {styled} from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import React, {ReactNode} from 'react'
import {useSelector} from 'react-redux'

import {User} from '../../../../apiclient'
import {AppState} from '../../Root.store'
import {getUserProfile} from '../../UserProfile/UserProfile.selectors'
import useCountryCoordinates from '../hooks/useCountryCoordinates'

interface GoogleMapProps {
  center?: {lat: number; lng: number}
  zoom?: number
  children?: ReactNode | ReactNode[]
  yesIWantToUseGoogleMapApiInternals?: boolean
  onGoogleApiLoaded?: (args: any) => void
  onClick?: (args: {lat: number; lng: number}) => void
}

const Wrapper = styled('div')({width: '100%', height: '500px'})

export const GoogleMap: React.FC<GoogleMapProps> = ({children, ...props}) => {
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )
  const coordinates = useCountryCoordinates(userProfile?.country || 'DE')

  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          // For local development please use the "REACT_APP_GOOGLE_MAPS_API_KEY_HUB_DEV" local dev api key which can found in the 1password shared vault.
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_HUB,
          libraries: ['places'],
          language: 'en',
          region: 'us'
        }}
        options={(maps) => ({
          panControl: false,
          mapTypeControl: true,
          scrollwheel: true,
          fullscreenControl: false,
          mapTypeControlOptions: {
            position: maps.ControlPosition.LEFT_BOTTOM,
            style: maps.MapTypeControlStyle.DEFAULT,
            mapTypeIds: ['roadmap', 'hybrid']
          }
        })}
        defaultCenter={{lat: coordinates?.latitude, lng: coordinates?.longitude}}
        defaultZoom={6}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </Wrapper>
  )
}
