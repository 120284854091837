import {Typography} from '@hconnect/uikit'
import MemoryIcon from '@mui/icons-material/Memory'
import {Box, Card, CardContent, Stack, Button, alpha} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  onClick: () => void
}

export const OrderPlacementDisclaimer: React.FC<Props> = ({onClick}) => {
  const {t} = useTranslation()

  return (
    <Card
      sx={{
        borderColor: ({palette}) => palette.warning.main,
        borderLeftColor: ({palette}) => palette.warning.main,
        backgroundColor: ({palette}) => alpha(palette.warning.main, 0.02),
        borderLeftWidth: 10
      }}
      variant="outlined"
      data-test-id="order-placement-disclaimer"
    >
      <CardContent component={Stack} spacing={{xs: 1, sm: 2}}>
        <Box display="flex" alignItems="center">
          <MemoryIcon color="action" />
          <Typography customVariant="captionBold" customColor="textPrimarySoft" component="span">
            {t('orderIntake.confirmOrder')}
          </Typography>
        </Box>

        <Typography variant="caption" component="span">
          {t('orderIntake.orderPlacementDisclaimer')}
        </Typography>
        <Button
          size="small"
          color="warning"
          sx={{
            color: 'white'
          }}
          variant="contained"
          fullWidth
          onClick={onClick}
          data-test-id="data-correct-confirmation-button"
        >
          {t('orderIntake.orderPlacementConfirmationButton')}
        </Button>
      </CardContent>
    </Card>
  )
}
