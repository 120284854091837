import {Typography} from '@hconnect/uikit'
import {Box, CircularProgress} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

import {useTimeLeftToCancelOrder} from '../../Hooks/OrderIntake/useTimeLeftToCancelOrder'
import {Order, ShippingType} from '../../Pages/Order/Order.types'
import {OrderCancellationOptions} from '../declarations/types'

import {Timer} from './TimeLeftToOrder'

interface OrderCancellationCutOffTimeProps {
  order: Order
  onCancelCutOffTimeReached: (isTimeToCancelOver: boolean) => void
  cancellationOptions: OrderCancellationOptions | undefined
  isLoadingCancellationOptions: boolean
  isCancellationButtonDisabled: boolean
}

interface Timer {
  hours: number
  minutes: number
  seconds: number
}

export const OrderCancellationCutOffTime: React.FC<OrderCancellationCutOffTimeProps> = ({
  order,
  onCancelCutOffTimeReached,
  cancellationOptions,
  isLoadingCancellationOptions,
  isCancellationButtonDisabled
}) => {
  const {t} = useTranslation()
  const {
    formattedCutOffDay,
    cutOffExactTime,
    isTimerActive,
    timer,
    isTimeToCancelOver,
    formattedDeliveryDate,
    deliveryExactTime
  } = useTimeLeftToCancelOrder(order, cancellationOptions, onCancelCutOffTimeReached)

  const isShippingTypeCollect = order.shippingType === ShippingType.Collect

  if (isLoadingCancellationOptions) {
    return <CircularProgress />
  }

  return (
    <>
      {!isCancellationButtonDisabled && (
        <>
          {!isTimeToCancelOver && (
            <>
              <Typography
                variant="body2"
                customColor="textPrimarySoft"
                style={{fontWeight: 700, fontSize: 14}}
              >
                {t('orderIntake.cancellationCutOff')}
              </Typography>

              <Typography
                variant="subtitle1"
                style={{fontWeight: 700, fontSize: 12}}
                data-test-id="cancellation-cut-off-time"
              >{`${isShippingTypeCollect ? formattedDeliveryDate : formattedCutOffDay}, ${
                isShippingTypeCollect ? deliveryExactTime : cutOffExactTime
              }`}</Typography>
              <Box mt={1} />
            </>
          )}

          {isTimerActive ? (
            <Typography variant="caption" color="error">
              {t('orderIntake.cancellationTimeLeft')}
              <Timer hours={timer.hours} minutes={timer.minutes} seconds={timer.seconds} />
            </Typography>
          ) : null}
        </>
      )}
    </>
  )
}
