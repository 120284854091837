import {DataScope, FeatureConstraint, useFeaturesState} from '../../Organisms/Features'

export const useIsOrderIntakeAvailableInCurrentCountry = (
  countryId: string | null | undefined,
  featureFlagName = 'OrderIntake'
) => {
  const {normalized} = useFeaturesState()
  const featureFlag = normalized[featureFlagName]

  const isOrderIntakeAvailableInCurrentCountry = (
    countryId: string | null | undefined,
    featureConstraint: FeatureConstraint[]
  ) =>
    featureConstraint.some((f) => {
      let dataScope: DataScope
      if (typeof f.dataScope === 'string') {
        dataScope = JSON.parse(f.dataScope) as DataScope
      } else {
        dataScope = f.dataScope
      }
      return dataScope.countryId === countryId
    })

  return isOrderIntakeAvailableInCurrentCountry(countryId, featureFlag?.constraints ?? [])
}
