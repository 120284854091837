import {styled} from '@material-ui/core'
import {useEffect, useRef} from 'react'

const Wrapper = styled('div')({
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '10px'
})

const StyledInput = styled('input')({
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  fontSize: '12px',
  outline: 'none',
  width: '100%',
  height: '30px',
  boxSizing: 'border-box'
})

export const SearchBox = ({map, mapApi}) => {
  const searchInput = useRef(null) as any
  const searchBox = useRef(null) as any

  useEffect(() => {
    searchBox.current = new mapApi.places.SearchBox(searchInput.current)
    const currentSearchInput = searchInput.current
    searchBox.current.addListener('places_changed', onPlacesChanged)
    searchBox.current.bindTo('bounds', map)

    return () => {
      mapApi.event.clearInstanceListeners(currentSearchInput)
    }
  }, [map, mapApi])

  const onPlacesChanged = () => {
    const selected = searchBox.current.getPlaces()
    const {0: place} = selected
    if (!place.geometry) return
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport)
    } else {
      map.setCenter(place.geometry.location)
      map.setZoom(17)
    }
  }

  const clearSearchBox = () => {
    searchInput.current.value = ''
  }

  return (
    <Wrapper>
      <StyledInput
        ref={searchInput}
        type="text"
        onFocus={clearSearchBox}
        placeholder="Search .."
        id="searchBox-google-map"
      />
    </Wrapper>
  )
}
