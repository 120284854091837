import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
const smartRockColor = '#106594'

export const useRequestForQuoteStyles = makeStyles((theme: Theme) => ({
  tabs: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '7px'
  },
  tab: {
    minWidth: '50px',
    marginTop: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  panel: {
    padding: 0,
    paddingTop: theme.spacing(2)
  },
  buttonTab: {
    minWidth: '50px',
    fontSize: '12px',
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      borderRadius: '7px'
    }
  },
  addAnotherMaterial: {
    width: '100%',
    border: 'none',
    padding: theme.spacing(2),
    justifyContent: 'left',
    textTransform: 'none',
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(1, 125, 209, 0.02)',
    '&:hover': {
      boxShadow: theme.shadows[2],
      backgroundColor: 'rgba(1, 125, 209, 0.02)'
    }
  },
  submitButtonContainer: {
    width: '100%'
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`
    },
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`
  },
  stickyContainer: {
    position: 'sticky',
    top: '40px',
    display: 'block'
  },
  tooltip: {
    backgroundColor: 'transparent'
  },
  attachmentContainer: {
    color: '#29aaff',
    cursor: 'pointer'
  },
  submitIcon: {
    marginRight: theme.spacing(1),
    color: 'white'
  },
  isLoading: {
    pointerEvents: 'none',
    opacity: 0.8
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    cursor: 'pointer'
  },
  projectType: {
    width: '100%',
    maxWidth: 'initial',
    height: '66px'
  },
  projectTypeContainer: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '7px',
    height: '66px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  smartRockSection: {
    border: `2px dashed ${smartRockColor}`,
    paddingTop: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '20px',
    marginBottom: '20px'
  },
  smartRockTag: {
    backgroundColor: smartRockColor,
    color: 'white',
    border: `1px solid ${smartRockColor}`,
    borderRadius: '7px',
    width: 'fit-content',
    padding: '0px 10px',
    fontSize: 'small'
  },
  smartRockHeadlinePart1: {
    color: smartRockColor,
    fontWeight: 500,
    margin: '10px 0px'
  },
  smartRockHeadlinePart2: {
    color: smartRockColor,
    fontSize: 'large',
    fontWeight: 700
  },
  smartRockTextPart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '15px',
    marginTop: '10px'
  },
  smartRockText: {
    color: smartRockColor
  },
  switchStyle: {
    '&.Mui-checked': {
      color: smartRockColor
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: smartRockColor
    }
  },
  singleCustomer: {
    fontSize: 'large',
    fontWeight: 500
  }
}))
