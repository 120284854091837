import {Typography} from '@hconnect/uikit'
import {searchInContent, SelectDropdown as SelectDropdownNew} from '@hconnect/uikit/src/lib2'
import {Typography as TypographyMui, MenuItem, MenuItemProps, styled, useTheme} from '@mui/material'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import {useOrderIntakeStyles} from '../../Hooks/OrderIntake/useOrderIntakeStyles'
import {OrderIntakeOption} from '../../OrderIntake/declarations/types'
import {ShippingType} from '../declarations/OrderIntake.enums'

const StyledSubheading = styled(TypographyMui)`
  color: ${({theme}) => theme.palette.text.primarySoft};
  text-align: left;
  font-size: ${({theme}) => theme.typography.caption.fontSize};
  margin-top: -2px;
`

const selectIdentity = (o?: OrderIntakeOption) =>
  `${o?.shippingAddress.siteName} - ${o?.shippingAddress.siteNumber} - ${o?.shippingAddress.street} - ${o?.shippingAddress.city}`
interface DropdownItemProps {
  title: string
  description?: string
  ariaLabel: string
  dataTestId?: string
  topMessage?: string
  hasDivider?: boolean
  body?: ReactElement
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  title,
  description,
  ariaLabel,
  dataTestId,
  topMessage,
  body
}) => (
  <div aria-label={ariaLabel} data-test-id={dataTestId}>
    {topMessage && (
      <Typography component="div" customColor="textPrimarySoft" variant="caption">
        {topMessage}
      </Typography>
    )}
    <Typography component="div" variant="body1" color="textPrimary">
      {title}
    </Typography>
    {body ? body : null}
    <Typography variant="caption" customColor="textPrimarySoft" component="div">
      {description}
    </Typography>
  </div>
)

type MaterialDropdownItem = DropdownItemProps & Omit<MenuItemProps, 'title'>
export const MaterialDropdownItem: React.FC<MaterialDropdownItem> = ({
  title,
  description,
  ariaLabel,
  body,
  topMessage,
  hasDivider,
  ...props
}) => {
  const classes = useOrderIntakeStyles()
  const theme = useTheme()

  return (
    <MenuItem
      {...props}
      className={classes.dropdownItem}
      style={{borderBottom: hasDivider ? `1px solid ${theme.palette.grey[300]}` : 'none'}}
    >
      <DropdownItem
        description={description}
        title={title}
        ariaLabel={ariaLabel}
        body={body}
        topMessage={topMessage}
      />
    </MenuItem>
  )
}

type SiteDropdownItemProps = Omit<MenuItemProps, 'title'> & {
  item: OrderIntakeOption
  hasDivider?: boolean
}
export const SiteDropdownItem: React.FC<SiteDropdownItemProps> = ({item, hasDivider, ...props}) => {
  const classes = useOrderIntakeStyles()
  const theme = useTheme()

  return (
    <MenuItem
      {...props}
      className={classes.dropdownItem}
      style={{borderBottom: hasDivider ? `1px solid ${theme.palette.grey[300]}` : 'none'}}
    >
      <DropdownItem
        title={item.shippingAddress.siteName.toUpperCase()}
        ariaLabel={`Site filter option ${item.shippingAddress.siteName}`}
        body={
          <>
            <Typography component="div" variant="caption" color="textPrimary">
              {item.shippingAddress.street.toUpperCase()}, {item.shippingAddress.city.toUpperCase()}
              , {item.shippingAddress.postalCode.toUpperCase()}
            </Typography>
          </>
        }
      />
    </MenuItem>
  )
}

interface SiteDropdownProps {
  selectedSite?: OrderIntakeOption
  options: OrderIntakeOption[]
  shippingType: ShippingType
  disabled?: boolean
  onChange: (siteNumber: string) => void
  fetchOrderIntakeOptions: () => void
  'data-test-id'?: string
}

export const SiteDropdown: React.FC<SiteDropdownProps> = ({
  selectedSite,
  shippingType,
  options = [],
  disabled = false,
  onChange
}) => {
  const {t} = useTranslation()
  const classes = useOrderIntakeStyles()

  if (options.length === 1) {
    return (
      <DropdownItem
        title={options[0].shippingAddress.siteName.toUpperCase()}
        ariaLabel={`Site filter option ${options[0].shippingAddress.siteName}`}
        dataTestId={`site-dropdown-item-0--destination-id-${options[0].shippingAddress.siteNumber}`}
        body={
          <>
            <Typography component="div" variant="caption" color="textPrimary">
              {options[0].shippingAddress.street.toUpperCase()}
            </Typography>
            <Typography component="div" variant="caption" color="textPrimary">
              {options[0].shippingAddress.city.toUpperCase()},{' '}
              {options[0].shippingAddress.postalCode.toUpperCase()}
            </Typography>
          </>
        }
      />
    )
  }

  return (
    <div title={selectIdentity(selectedSite)}>
      <SelectDropdownNew<OrderIntakeOption>
        data-test-id="order-intake-site-dropdown"
        label={shippingType === ShippingType.DELIVER ? t('orderIntake.destination') : t('orderIntake.location')}
        options={options}
        value={selectedSite}
        onChange={(o) => o?.shippingAddress?.siteNumber && onChange(o?.shippingAddress.siteNumber)}
        className={classes.dropdownContainer}
        innerButtonClassName={classes.dropdownButton}
        searchFieldClassName={classes.fullWidthFormControl}
        menuListMaxWidth={100}
        isSearchable
        disabled={disabled}
        aria-disabled={disabled}
        renderValue={(o) => (
          <>
            {selectIdentity(o)}
            <StyledSubheading>{o?.shippingAddress.city}</StyledSubheading>
          </>
        )}
        filterOption={(t, o) => searchInContent(t, selectIdentity(o))}
      >
        {(options, props) =>
          options.map((i, idx) => (
            <SiteDropdownItem
              item={i}
              data-test-id={`site-dropdown-item-${idx}--destination-id-${i.shippingAddress.siteNumber}`}
              key={selectIdentity(i)}
              onClick={() => props.onChange(i)}
              hasDivider={idx + 1 < options.length}
            ></SiteDropdownItem>
          ))
        }
      </SelectDropdownNew>
    </div>
  )
}
