import {ActionButton, BorderedTextField} from '@hconnect/uikit'
import {Box, InputAdornment, Tooltip} from '@material-ui/core'
import {AddCircle, Close} from '@material-ui/icons'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface SiteContactNameFieldProps {
  label: string
  addButtonLabel: string
  siteNameRef: React.Ref<HTMLInputElement>
  name: string
  value: any
  onChange: () => void
  setInputVisible: (visible: boolean, labelText: string) => void
  hideCleanButton?: boolean
  required?: boolean
  hasError?: boolean
  inputVisible: boolean
}

export const SiteContactNameField: React.FC<SiteContactNameFieldProps> = ({
  label,
  addButtonLabel,
  siteNameRef,
  name,
  value,
  onChange,
  inputVisible,
  setInputVisible,
  hideCleanButton,
  required = false,
  hasError = false
}) => {
  const {t} = useTranslation()
  const toolTip = t('orderIntake.siteContactNameRemoveTooltip')
  const MAX_CHARS = 35

  if (!inputVisible) {
    return (
      <Box
        data-test-id="site-contact-name-add-button"
        display="flex"
        alignItems="center"
        alignSelf="center"
        minWidth="50%"
        height="100%"
        pb={3}
      >
        <Box p={0.25} />
        <ActionButton
          onClick={() => setInputVisible(true, value)}
          icon={<AddCircle color="primary" />}
        >
          {addButtonLabel}
        </ActionButton>
      </Box>
    )
  }
  return (
    <BorderedTextField
      id={name}
      ref={siteNameRef}
      name={name}
      data-test-id="order-intake-site-contact-nane-input"
      value={value}
      required={required}
      label={label}
      error={hasError}
      onChange={onChange}
      onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
      inputProps={{maxLength: MAX_CHARS}}
      helperText={`${value?.length ?? 0} / ${MAX_CHARS}`}
      InputProps={
        hideCleanButton
          ? undefined
          : {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={toolTip} placement="bottom" arrow>
                    <Close
                      style={{cursor: 'pointer'}}
                      color="secondary"
                      onClick={() => setInputVisible(false, value)}
                    />
                  </Tooltip>
                </InputAdornment>
              )
            }
      }
    />
  )
}
