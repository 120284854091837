import {HCThemeType} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'

export const useTimePickerStyles = makeStyles((theme: HCThemeType) => {
  return {
    tabs: {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '7px'
    },
    tab: {
      minWidth: '50px',
      marginTop: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    panel: {
      padding: 0,
      paddingTop: theme.spacing(2)
    },
    buttonTab: {
      minWidth: '50px',
      fontSize: '12px',
      textTransform: 'none',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        borderRadius: '7px'
      }
    },
    notAvailableSlotsWrapper: {
      marginTop: '10px',
      height: '30px'
    },
    notAvailableSlots: {
      backgroundColor: '#F9D1D1',
      border: '1px solid #9D1D1D',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      padding: '5px'
    },
    limitedSlotsAvailability: {
      backgroundColor: '#ffedcc',
      border: '1px solid #FFA500',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      padding: '5px'
    },
    notAvailableSlotsText: {
      color: '#9D1D1D',
      fontSize: '12px',
      margin: '0px 10px'
    },
    limitedSlotsAvailabilityText: {
      color: '#cc8400',
      fontSize: '12px',
      margin: '0px 10px'
    },
    notAvailableSlotsAdjust: {
      color: '##016AD4',
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: 'bolder'
    },
    tooltipContent: {
      minWidth: '335px',
      background: 'white',
      border: '1px solid silver'
    }
  }
})

export const useSpecificTimePickerStyles = makeStyles((theme: HCThemeType) => {
  return {
    hourViewBoxContainer: {
      display: 'flex',
      overflow: 'auto',
      alignItems: 'center',
      paddingLeft: theme.spacing(4) + 3,
      paddingRight: theme.spacing(4) + 3,
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      }
    },
    hourViewBox: {
      width: 'auto',
      display: 'flex',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      margin: theme.spacing(0.5),
      border: '1px solid transparent',
      alignContent: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      cursor: 'pointer',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      transition: 'border 0.3s ease-out',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.light}`,
        transition: 'border 0.3s ease-in'
      },
      '&.active': {
        border: `1px solid ${theme.palette.primary.light}`,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
      }
    },
    hourScrollButton: {
      position: 'absolute',
      backgroundColor: 'white',
      border: 'none',
      padding: 0,
      boxShadow: theme.shadows[6],
      height: '28px',
      borderRadius: '28px',
      minWidth: '28px',
      width: '28px',
      minHeight: '28px',
      '&:hover': {
        backgroundColor: 'white',
        boxShadow: theme.shadows[4]
      },
      '&.active': {
        boxShadow: 'none'
      },
      '&.left': {
        left: theme.spacing(1) + 4,
        [theme.breakpoints.down('xs')]: {
          left: theme.spacing(-1)
        }
      },
      '&.right': {
        right: theme.spacing(2) - 2,
        [theme.breakpoints.down('xs')]: {
          right: theme.spacing(-1)
        }
      }
    },
    hourGradient: {
      position: 'absolute',
      height: '50px',
      width: theme.spacing(9),
      '&.left': {
        left: 1,
        [theme.breakpoints.down('xs')]: {
          left: theme.spacing(-2),
          width: theme.spacing(7)
        },
        backgroundImage: 'linear-gradient(to right, white 60%,rgba(255,255,255,0) 100%)'
      },
      '&.right': {
        right: 1,
        [theme.breakpoints.down('xs')]: {
          right: theme.spacing(-2),
          width: theme.spacing(7)
        },
        backgroundImage: 'linear-gradient(to left, white 60%,rgba(255,255,255,0) 100%)'
      }
    },
    slotViewContainer: {
      display: 'Grid',
      gap: theme.spacing(2),
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    slotViewBox: {
      display: 'flex',
      border: `1px solid ${theme.palette.grey[100]}`,
      color: theme.palette.secondary.light,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      height: '55px',
      cursor: 'pointer',
      '&:hover, &:focus': {
        transition: 'border 0.3s ease-in, color 0.3s ease-in',
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.grey[400]
      },
      '&.active.active': {
        border: `2px solid ${theme.palette.primary.light}`,
        color: theme.palette.secondary.dark
      }
    }
  }
})
