import {BorderedTextField, PhoneNumberTextField} from '@hconnect/uikit'
import {Box, useMediaQuery, useTheme} from '@mui/material'
import {CountryCode, isValidPhoneNumber} from 'libphonenumber-js'
import {isEmpty} from 'lodash'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {BackupPhoneField} from '../../../OrderIntake/components/Deliver/BackupPhoneField'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {SiteContactNameField} from '../../components/Collect/SiteContactNameField'
import {ShippingType} from '../../declarations/OrderIntake.enums'
import {useOrderIntakeData} from '../../hooks'
import {MaterialSectionHeader} from '../../components/VisualComponents'

type Props = {
  dataTestPrefix: string
  shippingType: ShippingType
}
export const ContactSection: React.FC<Props> = ({dataTestPrefix, shippingType}) => {
  const {t} = useTranslation()
  const {userProfileCountry: countryCode, features} = useOrderIntakeData()
  const {watch, setValue, getValues, control, trigger, resetField} =
    useFormContext<BulkOrderFormValues>()

  const hasBackupPhoneFeature =
    shippingType === ShippingType.DELIVER || features.hasSiteContactNameAndPhone

  const isSiteContactRequired =
    shippingType === ShippingType.DELIVER && features.hasSiteContactNameAndPhone

  const isBackupPhoneVisible = watch('isBackupPhoneVisible') || isSiteContactRequired

  const [isSiteContactNameVisible, setIsSiteContactNameVisible] =
    React.useState<boolean>(isSiteContactRequired)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onSetBackupPhoneVisible = (visible: boolean) => {
    // this is used here because for russia we do not want copy automatically mainPhone to backupPhone, name of the FT does not fit the purpose
    if (features.isOrderIntakeBackupPhoneValidation) {
      setValue('contact.backupPhone', getValues('contact.mainPhone'), {
        shouldValidate: true
      })
    }
    setValue('isBackupPhoneVisible', visible)

    if (!visible) {
      resetField('contact.backupPhone')
      setValue('contact.backupPhone', '')
    }
  }

  const onSetSiteContactNameVisible = (visible: boolean, labelText: string) => {
    setIsSiteContactNameVisible(visible)

    if (!visible) {
      setValue('contact.siteContactName', labelText)
    }

    void trigger('contact')
  }

  // re-trigger validation after component gains focus again to avoid form being stuck in the "isValidating" state. See HCP-69804.
  void trigger('contact')
  return (
    <>
      <MaterialSectionHeader data-test-id={`${dataTestPrefix}-contact-section-header`}>
        {t('orderIntake.contactDetails')}
      </MaterialSectionHeader>

      <Box
        display="flex"
        sx={isMobile ? {flexDirection: 'column', rowGap: 2} : {columnGap: 2, flexWrap: 'wrap'}}
      >
        <Box pb={1} sx={isMobile ? {width: '100%'} : {width: '45%'}}>
          <Controller
            control={control}
            name={'contact.fullName'}
            rules={{
              required: true
            }}
            render={({field, fieldState}) => (
              <BorderedTextField
                required
                name={field.name}
                value={field.value ?? ''}
                label={t('orderIntake.fullName')}
                inputProps={{'data-test-id': 'order-intake-contact-full-name'}}
                onChange={field.onChange}
                onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                error={!!fieldState.error}
                InputLabelProps={{shrink: !isEmpty(field.value)}}
              />
            )}
          />
        </Box>

        <Box pb={1} sx={isMobile ? {width: '100%'} : {width: '45%'}}>
          <Controller
            control={control}
            rules={{
              required: true,
              minLength: 1,
              validate: (value) => value && isValidPhoneNumber(value)
            }}
            name={'contact.mainPhone'}
            render={({field, fieldState}) => (
              <PhoneNumberTextField
                bordered
                id={field.name}
                fullWidth
                ref={field.ref}
                name={field.name}
                data-test-id="order-intake-mainPhone-input"
                value={field.value ?? ''}
                onPhoneChange={field.onChange}
                onBlur={() => {
                  if (!hasBackupPhoneFeature || watch('contact.backupPhone')) return
                  setValue('contact.backupPhone', watch('contact.mainPhone'))
                  void trigger('contact.backupPhone')
                }}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                InputLabelProps={{shrink: !isEmpty(field.value)}}
                options={{
                  initialCountry: field.value ? '' : (countryCode as CountryCode) ?? '',
                  nationalMode: false,
                  formatOnDisplay: false
                }}
                required
                label={t('orderIntake.mainPhone')}
                error={!!fieldState.error || (!!field.value && !isValidPhoneNumber(field.value))}
              />
            )}
          />
        </Box>

        {features.hasSiteContactNameAndPhone ? (
          <Box pb={1} sx={isMobile ? {width: '100%'} : {width: '45%'}}>
            <Controller
              control={control}
              name={'contact.siteContactName'}
              rules={{
                required: isSiteContactNameVisible
              }}
              render={({field, fieldState}) => (
                <SiteContactNameField
                  label={t('orderIntake.siteContactName')}
                  addButtonLabel={t('orderIntake.addSiteContactName')}
                  name={field.name}
                  siteNameRef={field.ref}
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  required={isSiteContactRequired}
                  setInputVisible={onSetSiteContactNameVisible}
                  inputVisible={isSiteContactNameVisible}
                  hasError={!!fieldState.error || !field.value}
                  hideCleanButton={isSiteContactRequired}
                />
              )}
            />
          </Box>
        ) : null}

        {hasBackupPhoneFeature && (
          <Box pb={1} sx={isMobile ? {width: '100%'} : {width: '45%'}}>
            <Controller
              control={control}
              name={'contact.backupPhone'}
              rules={{
                validate: (value) => {
                  if (isBackupPhoneVisible) return isValidPhoneNumber(value ?? '')
                }
              }}
              render={({field, fieldState}) => (
                <BackupPhoneField
                  label={
                    features.hasSiteContactNameAndPhone
                      ? t('orderIntake.siteContactPhone')
                      : t('orderIntake.backupPhone')
                  }
                  addButtonLabel={
                    features.hasSiteContactNameAndPhone
                      ? t('orderIntake.addSiteContactPhone')
                      : t('orderIntake.addBackupPhone')
                  }
                  testId={
                    features.hasSiteContactNameAndPhone
                      ? 'order-intake-siteContactPhone-input'
                      : 'order-intake-backupPhone-input'
                  }
                  name={field.name}
                  phoneNumberRef={field.ref}
                  userProfileCountry={countryCode}
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  required={isBackupPhoneVisible}
                  setInputVisible={onSetBackupPhoneVisible}
                  inputVisible={isBackupPhoneVisible}
                  hasError={
                    !!fieldState.error || (!!field.value && !isValidPhoneNumber(field.value))
                  }
                  hideCleanButton={isSiteContactRequired}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
