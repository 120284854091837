import {range} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {MINUTE_STEP} from '../../../OrderIntake/declarations/constants'
import {ShippingType} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {
  BusinessHours,
  ConfigurableTimeSlot,
  DefaultDeliveryWindow,
  DeliveryTime,
  SlotConfiguration,
  TimeFormatValues
} from '../../../OrderIntake/declarations/types'
import {formatTime, isDateToday, isIntervalWithinInterval} from '../../../util/time'

import {ScrollableListItem, TimeOffset} from './types'

const DEFAULT_TZ = moment.tz.guess(true)
const NOON_TIME = '12:00:00'

export const nearestTimeFromInterval = (start: Moment, interval: number): number => {
  return interval - (start.minute() % interval)
}

export const getScrollPositionFromIndex = (
  itemIndex: number,
  amountOfVisibleItems: number
): number => {
  return Math.trunc(itemIndex / amountOfVisibleItems)
}

export const getHourDifference = (
  startTime: string,
  endTime: string,
  timeFormatting: string
): number => {
  const duration = moment.duration(
    moment(endTime, timeFormatting).diff(moment(startTime, timeFormatting))
  )
  return duration.asHours()
}

export const getNextAvailableItemIndex = (minIndex: number, offSet: number): number => {
  return minIndex + offSet
}

export const getItemsToDisable = (selectedIndex: number, intervalOffset: number) => {
  return range(selectedIndex + intervalOffset)
}

export const getOffsetFromInterval = (interval: string): TimeOffset => {
  const hours = parseInt(interval.split(':')[0], 10)
  const minutes = parseInt(interval.split(':')[1], 10)

  return {hours, minutes}
}

export const latestMinimumTimeWithIntervalOffset = (
  businessEnd: string,
  interval: string,
  format: string,
  timezone = DEFAULT_TZ
): moment.Moment => {
  const {hours, minutes} = getOffsetFromInterval(interval)

  return moment
    .tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${businessEnd}`, timezone)
    .subtract({
      hours,
      minutes
    })
}

export const isCutOffTime = (
  timeStamp: Moment,
  timeFormatting: string,
  businessStart: string,
  businessEnd: string,
  interval: string,
  isMinimum: boolean,
  shippingType: ShippingType,
  currentDate: string,
  timezone = DEFAULT_TZ
) => {
  const minimumEnd = latestMinimumTimeWithIntervalOffset(
    businessEnd,
    interval,
    timeFormatting,
    timezone
  )

  if (shippingType === ShippingType.COLLECT) {
    const nextAvailableSlot = moment()
      .subtract(MINUTES_FROM_AVAILABLE_TIME_SLOT + 1, 'minutes')
      .tz(timezone)

    if (timeStamp.isBefore(nextAvailableSlot) && isDateToday(currentDate, timezone)) {
      return true
    }
  }

  if (
    timeStamp.isBetween(
      moment.tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${businessStart}`, timezone),
      minimumEnd,
      'minutes',
      '[]'
    ) &&
    isMinimum
  ) {
    return false
  }

  return !(
    timeStamp.isBetween(
      moment.tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${businessStart}`, timezone),
      moment.tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${businessEnd}`, timezone),
      'minutes',
      '[]'
    ) && !isMinimum
  )
}

const getMinimumInitialValue = (
  remainder: number,
  format: string,
  timezone = DEFAULT_TZ
): string => {
  return moment().tz(timezone).add(remainder, 'minutes').startOf('minute').format(format)
}

const getMaximumInitialValue = (
  remainder: number,
  format: string,
  interval: string,
  timezone = DEFAULT_TZ
): string => {
  const {hours, minutes} = getOffsetFromInterval(interval)
  const roundedTime = moment()
    .tz(timezone)
    .add(remainder, 'minutes')
    .startOf('minute')
    .format(format)
  return moment(roundedTime, format)
    .add({
      hours,
      minutes
    })
    .format(format)
}

export const existsNonFullSlots = (
  from: string,
  to: string,
  overlapInterval: string,
  slotsConfiguration: SlotConfiguration[],
  slots: ConfigurableTimeSlot[]
) => {
  let existsSlots = false
  const freeSlots: DeliveryTime[] = []

  // merge free slots
  for (let index = 0; index < slotsConfiguration.length; index++) {
    const slotConfig = slotsConfiguration[index]
    if (!getSlotBlockingDelivery(slots, slotConfig.id)) {
      const freeSlotFrom = slotConfig.slotStart
      let freeSlotTo = slotConfig.slotEnd

      if (index < slotsConfiguration.length - 1) {
        for (let j = index + 1; j < slotsConfiguration.length; j++) {
          const nextSlotConfig = slotsConfiguration[j]
          const slotStatusBlockingDelivery = getSlotBlockingDelivery(slots, nextSlotConfig.id)
          if (!slotStatusBlockingDelivery) {
            freeSlotTo = nextSlotConfig.slotEnd
            index++
          } else break
        }
      }

      freeSlots.push({earliest: freeSlotFrom, latest: freeSlotTo})
    }
  }

  freeSlots.forEach((freeSlot) => {
    if (isIntervalWithinInterval(from, to, freeSlot.earliest, freeSlot.latest, overlapInterval)) {
      existsSlots = true
    }
  })

  return existsSlots
}

export const onlyFullSlots = (
  from: string,
  to: string,
  overlapInterval: string,
  slotsConfiguration: SlotConfiguration[],
  slots: ConfigurableTimeSlot[]
) => {
  let existsSlots = false
  slotsConfiguration.map((slotConfig) => {
    if (!getSlotBlockingDelivery(slots, slotConfig.id)) {
      if (
        isIntervalWithinInterval(
          slotConfig.slotStart,
          slotConfig.slotEnd,
          from,
          to,
          overlapInterval
        )
      ) {
        existsSlots = true
      }
    }
  })
  return !existsSlots
}

export const existsNonFreeSlots = (
  slotsConfiguration: SlotConfiguration[],
  slots: ConfigurableTimeSlot[]
) => {
  let existsSlots = false
  slotsConfiguration.map((slotConfig) => {
    if (getSlotBlockingDelivery(slots, slotConfig.id)) {
      existsSlots = true
    }
  })
  return existsSlots
}

export const getSlotBlockingDelivery = (slots: ConfigurableTimeSlot[], slotConfigId: string) => {
  const slot = slots.find((slot) => slot.slotConfig.id === slotConfigId)
  if (slot) return slot.slotStatus.isBlockingDelivery

  return false
}

export const evaluateDeliveryTime = (
  interval: string,
  valueFormatting: string,
  currentDate: string,
  defaultDeliveryWindow: DefaultDeliveryWindow,
  businessHours: BusinessHours,
  timezone = DEFAULT_TZ,
  isWholeDay: boolean,
  slotsConfiguration?: SlotConfiguration[],
  slots?: ConfigurableTimeSlot[]
): DeliveryTime => {
  if (slots && slotsConfiguration) {
    const existsNonFullSlotsWithinDefaultDeliveryTime = existsNonFullSlots(
      defaultDeliveryWindow.defaultEarliestLoadTime,
      defaultDeliveryWindow.defaultLatestLoadTime,
      interval,
      slotsConfiguration,
      slots
    )

    if (!existsNonFullSlotsWithinDefaultDeliveryTime) {
      const checkLatestPossible = existsNonFullSlots(
        defaultDeliveryWindow.defaultEarliestLoadTime,
        businessHours.latestPossible,
        interval,
        slotsConfiguration,
        slots
      )
      if (checkLatestPossible) {
        return {
          earliest: formatTime(
            defaultDeliveryWindow.defaultEarliestLoadTime,
            timezone,
            valueFormatting
          ),
          latest: formatTime(businessHours.latestPossible, timezone, valueFormatting)
        }
      } else {
        const checkEarliestPossible = existsNonFullSlots(
          businessHours.earliestPossible,
          businessHours.latestPossible,
          interval,
          slotsConfiguration,
          slots
        )
        if (checkEarliestPossible) {
          return {
            earliest: formatTime(businessHours.earliestPossible, timezone, valueFormatting),
            latest: formatTime(businessHours.latestPossible, timezone, valueFormatting)
          }
        }
      }
    }
  }

  if (!isDateToday(currentDate, timezone)) {
    if (!isWholeDay) {
      return {
        earliest: formatTime(
          defaultDeliveryWindow.defaultEarliestLoadTime,
          timezone,
          valueFormatting
        ),
        latest: formatTime(defaultDeliveryWindow.defaultLatestLoadTime, timezone, valueFormatting)
      }
    }
    return {
      earliest: formatTime(businessHours.earliestPossible, timezone, valueFormatting),
      latest: formatTime(businessHours.latestPossible, timezone, valueFormatting)
    }
  }

  return {
    earliest: getMinimumInitialValue(
      nearestTimeFromInterval(moment(), MINUTE_STEP),
      valueFormatting,
      timezone
    ),
    latest: formatTime(businessHours.latestPossible, timezone, valueFormatting)
  }
}

export const evaluateEarliestTime = (
  interval: string,
  valueFormatting: string,
  currentDate: string,
  defaultDeliveryWindow: DefaultDeliveryWindow,
  businessHours: BusinessHours,
  timezone = DEFAULT_TZ,
  isWholeDay: boolean
) => {
  if (!isDateToday(currentDate, timezone)) {
    if (!isWholeDay) {
      return moment
        .tz(
          `${moment().tz(timezone).format('YYYY-MM-DD')} ${
            defaultDeliveryWindow.defaultEarliestLoadTime
          }`,
          timezone
        )
        .format(valueFormatting)
    }
    return moment
      .tz(
        `${moment().tz(timezone).format('YYYY-MM-DD')} ${businessHours.earliestPossible}`,
        timezone
      )
      .format(valueFormatting)
  }

  return getMinimumInitialValue(
    nearestTimeFromInterval(moment(), MINUTE_STEP),
    valueFormatting,
    timezone
  )
}

export const evaluateLatestTime = (
  interval: string,
  valueFormatting: string,
  currentDate: string,
  defaultDeliveryWindow: DefaultDeliveryWindow,
  businessHours: BusinessHours,
  timezone = DEFAULT_TZ,
  isWholeDay: boolean
) => {
  if (!isDateToday(currentDate, timezone)) {
    if (!isWholeDay) {
      return moment
        .tz(
          `${moment().tz(timezone).format('YYYY-MM-DD')} ${
            defaultDeliveryWindow.defaultLatestLoadTime
          }`,
          timezone
        )
        .format(valueFormatting)
    }
    return moment
      .tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${businessHours.latestPossible}`, timezone)
      .format(valueFormatting)
  }

  if (!isWholeDay) {
    return getMaximumInitialValue(
      nearestTimeFromInterval(moment(), MINUTE_STEP),
      valueFormatting,
      interval,
      timezone
    )
  }

  return moment
    .tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${businessHours.latestPossible}`, timezone)
    .format(valueFormatting)
}

export const getNoonTime = (timezone = DEFAULT_TZ, valueFormatting: string) => {
  return moment
    .tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${NOON_TIME}`, timezone)
    .format(valueFormatting)
}

export const getNoonDateTime = (timezone = DEFAULT_TZ) => {
  return moment.tz(`${moment().tz(timezone).format('YYYY-MM-DD')} ${NOON_TIME}`, timezone)
}

export const createTimeItems = (
  isMinimumList: boolean,
  initialValue: string,
  miniMumValue: string,
  labelHourFormatting: string,
  valueHourFormatting: string,
  businessStart: string,
  businessEnd: string,
  interval: string,
  minuteStep: number,
  shippingType: ShippingType,
  currentDate: string,
  timezone = DEFAULT_TZ
) => {
  const period = 'minutes'
  const items: ScrollableListItem[] = []
  const businessHourPeriodsInADay = moment
    .duration(
      moment(businessEnd, valueHourFormatting).diff(moment(businessStart, valueHourFormatting))
    )
    .as(period)

  const startTimeMoment = moment.tz(
    `${moment().tz(timezone).format('YYYY-MM-DD')} ${businessStart}`,
    timezone
  )

  const defaultMinimumTime = moment.tz(
    `${moment().tz(timezone).format('YYYY-MM-DD')} ${miniMumValue}`,
    timezone
  )

  if (minuteStep > 0) {
    for (let timeItem = 0; timeItem <= businessHourPeriodsInADay; timeItem += minuteStep) {
      const startTime = startTimeMoment.add(timeItem === 0 ? 0 : minuteStep, period)
      const startTimeLocal = startTime.clone().local().format(labelHourFormatting)

      const labelFormatted = startTime.format(labelHourFormatting)

      const isCutOff = isCutOffTime(
        startTimeMoment,
        valueHourFormatting,
        businessStart,
        businessEnd,
        interval,
        isMinimumList,
        shippingType,
        currentDate,
        timezone
      )

      items.push({
        label: labelFormatted,
        value: startTime.format(valueHourFormatting),
        localTime: startTimeLocal,
        id: `${labelFormatted}-${isMinimumList ? 'earliest' : 'latest'}-${timeItem}`,
        isDisabled: !isMinimumList
          ? startTime.isSameOrBefore(defaultMinimumTime) ||
            (startTime.isAfter(defaultMinimumTime) &&
              startTime.isBefore(defaultMinimumTime.clone().add(interval, period)))
          : false,
        isSelected: startTime.format(valueHourFormatting) === initialValue,
        isUnavailable: isCutOff
      })
    }
  }

  return items
}

export const getIndexOfItem = (
  value: string,
  timeStampCollection: ScrollableListItem[] | undefined
) => {
  if (!timeStampCollection) {
    return 0
  }
  const index = timeStampCollection.findIndex(
    (element: ScrollableListItem) => element.value === value
  )
  if (index === -1) return 0

  return index
}

export const getTimeFormatValue = (value: string, language: string): TimeFormatValues | null => {
  const formattedTime = timeFormatter(moment(value?.toString(), 'HH:mm:ss'), language)

  if (formattedTime.endsWith('AM')) return TimeFormatValues.AM
  else if (formattedTime.endsWith('PM')) return TimeFormatValues.PM

  return null
}

export const timeFormatter = (
  date: string | Date | Moment,
  locale = getLocale(),
  utcOffset?: number
): string =>
  _dateStringToUtc(date, utcOffset !== undefined ? utcOffset : getUtcOffset(date))
    .locale(locale)
    .format('LT')

const getLocale = () => {
  if (window.navigator.languages) {
    return window.navigator.languages[0]
  }
  return window.navigator.language
}

const _dateStringToUtc = (date: string | Date | Moment, utcOffset: number): Moment => {
  if (typeof date === 'string' && date.length === 10) {
    return moment(date)
  }
  return moment.utc(date).utcOffset(utcOffset)
}

const getUtcOffset = (date: string | Date | Moment = moment()): number => moment(date).utcOffset()

export const isTwelveHoursFormat = (language: string) => {
  const formattedCurrentTime = timeFormatter(moment(), language)
  return formattedCurrentTime.includes('AM') || formattedCurrentTime.includes('PM')
}

export const formatValueToTwelveHoursFormat = (value: string, language: string): string => {
  const formattedTime = timeFormatter(moment(value?.toString(), 'HH:mm:ss'), language)
  return formattedTime.replace('AM', '').replace('PM', '').replace(' ', '')
}

export const VALUE_TWELVE_HOUR_FORMAT = 'hh:mm A'
export const LABEL_TWELVE_HOUR_FORMAT = 'hh:mm'
export const TWENTY_FOUR_HOUR_FORMAT = 'HH:mm'
export const DATA_BASE_TIME_FORMAT = 'HH:mm:ss'
export const MINUTES_FROM_AVAILABLE_TIME_SLOT = moment().minutes() % MINUTE_STEP
