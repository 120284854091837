import {styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTimeLeftToOrder} from '../../Hooks/OrderIntake/useTimeLeftToOrder'
import {DateRange} from '../../OrderIntake/declarations/types'

const WrapperTypography = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.palette.text.primarySoft};
`

interface Props {
  onTimerChange: (isTimeOver: boolean) => void
  deliveryDateRange: DateRange
  cutOffTime: string | undefined
  cutOffTimeThreshold: string
  deliveryDate: string
  timeZone?: string
  onTimerActive?: (isTimerActive: boolean) => void
}

interface TimerProps {
  hours: number
  minutes: number
  seconds: number
}

export const Timer: React.FC<TimerProps> = ({hours, minutes, seconds}) => {
  const {t} = useTranslation()
  return (
    <>
      {hours === 0
        ? null
        : `${t('orderIntake.timeLeftToOrderHours', {
            count: hours
          })}`}
      {minutes === 0 || hours > 0
        ? null
        : `${t('orderIntake.timeLeftToOrderMinutes', {
            count: minutes
          })}`}
      {seconds === 0 || minutes > 0 || hours > 0
        ? null
        : `${t('orderIntake.timeLeftToOrderSeconds', {
            count: seconds
          })}`}
    </>
  )
}

export const TimeLeftToOrder: React.FC<Props> = (p) => {
  const {t} = useTranslation()
  const {isTimerActive, timer} = useTimeLeftToOrder(
    p.cutOffTime,
    p.cutOffTimeThreshold,
    p.onTimerChange,
    p.deliveryDate,
    p.deliveryDateRange,
    p.timeZone
  )
  p.onTimerActive?.(isTimerActive)

  if (!isTimerActive) return null
  return (
    <WrapperTypography data-test-id="order-intake-time-left-to-order" variant="caption">
      {t('orderIntake.timeLeftToOrder')}&nbsp;
      <Typography variant="inherit" color="error">
        <Timer hours={timer.hours} minutes={timer.minutes} seconds={timer.seconds} />
      </Typography>
    </WrapperTypography>
  )
}
