import {Typography, HCThemeType} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {OrderRequestStatus} from '../../OrderIntake/declarations/OrderIntake.enums'

const useStyles = makeStyles((theme: HCThemeType) => {
  return {
    root: {
      border: `1px ${theme.palette.grey[100]} solid`,
      borderRadius: '15px',
      padding: '3px 15px',
      color: '#5B8DAF',
      fontWeight: 'bold',
      backgroundColor: theme.palette.grey[100]
    }
  }
})

interface Props {
  status: OrderRequestStatus
}

const OrderLegacyStatus: FC<Props> = ({status}) => {
  const {t} = useTranslation()
  const c = useStyles()

  return (
    <Box display="flex" alignItems="center" data-test-id="order-status-with-icon">
      <Typography className={c.root} variant="caption">
        {t(`orderIntake.status.${status}`).toUpperCase()}
      </Typography>
    </Box>
  )
}

export {OrderLegacyStatus}
