import {Typography} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ShippingType} from '../../OrderIntake/declarations/OrderIntake.enums'
import {
  GroupedMaterialDeliveries,
  MaterialDelivery,
  QuantityType
} from '../../OrderIntake/declarations/types'
import {QuantityUomToSymbolComponent} from '../../Pages/Order/Order.components'

import {SummaryItem} from './SummaryItem'

interface SummaryGroupProps {
  groupedMaterial: GroupedMaterialDeliveries[]
  shippingType: ShippingType
  quantityType: QuantityType
  onEditItem: (index: number) => void
}

export const SummaryGroup: React.FC<SummaryGroupProps> = ({
  groupedMaterial,
  quantityType,
  onEditItem
}) => {
  const {t} = useTranslation()

  const renderSummaryGroup = (group: GroupedMaterialDeliveries, index: number) => {
    return (
      <React.Fragment key={`Material group ${group?.headline?.description || ''}-${index}`}>
        <Typography variant="h4" style={{marginTop: 8}}>
          {`${group.headline.quantity}`}
          {quantityType === QuantityType.Load ? (
            ` ${t('orderIntake.loads', {count: group.headline.quantity})} `
          ) : (
            <QuantityUomToSymbolComponent uom={group.headline.capacityUom} />
          )}
          {` ${group.headline.description}`}
        </Typography>
        {group.summaryItems.map((materialDelivery: MaterialDelivery, index: number) =>
          renderSummaryItem(materialDelivery,group.headline.capacityUom, group.summaryItems.length > 1, index)
        )}
      </React.Fragment>
    )
  }

  const renderSummaryItem = (materialDelivery: MaterialDelivery, capacity: string | undefined, showQuantity:boolean, index: number) => {
    return (
      <SummaryItem
        key={`Delivery Summary Item ${materialDelivery.id}-${index}`}
        id={materialDelivery.id}
        index={materialDelivery.index}
        dateAndTime={materialDelivery.dateAndTime}
        quantity={materialDelivery.quantity}
        quantityType={quantityType}
        capacityUom={capacity}
        showQuantity={showQuantity}
        onEditItem={onEditItem}
      />
    )
  }

  return (
    <>
      {groupedMaterial.map((group: GroupedMaterialDeliveries, index: number) =>
        renderSummaryGroup(group, index)
      )}
    </>
  )
}
