import {Typography, ActionButton} from '@hconnect/uikit'
import {Box, Button, makeStyles, Theme} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  OrderIntakeMaterialOptionPayload,
  PersistedOrderRequest,
  QuantityType
} from '../../declarations/types'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(
        1
      )}px`
    },
    padding: `${theme.spacing(1)}px`
  },
  contentContainer: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  buttonsGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end'
  },
  actionButtonBox: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end'
    },
    justifyContent: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    marginTop: '20px'
  },
  disabled: {
    opacity: 0.4
  },
  plantName: {
    opacity: 0.4,
    fontSize: 12,
    float: 'right'
  },
  invalidMaterialsBanner: {
    border: '1px solid #CCD7DB',
    padding: '10px',
    marginTop: '15px',
    borderRadius: '4px',
    borderLeftColor: '#EE8B00',
    borderLeftWidth: '4px'
  },
  materialItem: {
    marginBottom: '30px'
  }
}))

interface Props {
  persistedMaterials: PersistedOrderRequest[]
  validMaterials: OrderIntakeMaterialOptionPayload[]
  existsValidMaterials: boolean
  onClose: (apply: boolean) => void
}

export const PersistedMaterialsDialog: React.FC<Props> = ({
  onClose,
  persistedMaterials,
  validMaterials,
  existsValidMaterials
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const [showInvalidMaterialsBanner, setShowInvalidMaterialsBanner] = useState<boolean>(false)

  const isMaterialValid = (material: PersistedOrderRequest) => {
    return validMaterials.some(
      (item) => item.material.materialEnteredNumber === material.materialEnteredNumber
    )
  }

  const getPersistedMaterial = (material: PersistedOrderRequest, index: number) => {
    const measureOfUnit =
      material.capacity.quantityType === QuantityType.TruckCapacity
        ? 't'
        : t('orderIntake.loads', {count: material.capacity.quantity})

    const isValid = isMaterialValid(material)

    if (!isValid && !showInvalidMaterialsBanner) setShowInvalidMaterialsBanner(true)

    return (
      <Box marginTop={2} className={classes.materialItem}>
        <Typography className={!isValid ? classes.disabled : ''}>{`${index + 1} - ${t(
          'orderIntake.materialPersistency.total'
        )}
        ${material.capacity.quantity} ${measureOfUnit} ${
          material.materialDescription
        }`}</Typography>

        {material.plantName && (
          <Typography className={classes.plantName}>
            {`${t('orderIntake.plant')} ${material.plantName}`}
          </Typography>
        )}

        {!isValid && (
          <Typography variant="caption" customColor="textError">
            {t('orderIntake.materialPersistency.noValidMaterial')}
          </Typography>
        )}
      </Box>
    )
  }

  return (
    <Box data-test-id="persisted-materials-dialog" className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box>
          <Typography variant="h3">
            {t('orderIntake.materialPersistency.lastOrderDetails')}
          </Typography>
        </Box>

        {showInvalidMaterialsBanner && (
          <Box className={classes.invalidMaterialsBanner}>
            <Typography variant="body2">
              {t('orderIntake.materialPersistency.someMaterialsNotAvailable')}
            </Typography>
          </Box>
        )}

        <Box data-test-id="persisted-materials-list">
          {persistedMaterials.map((material, index) => getPersistedMaterial(material, index))}
        </Box>
      </Box>

      <Box className={classes.buttonsGrid}>
        <Box className={classes.actionButtonBox}>
          <ActionButton
            data-test-id="persisted-materials-dialog-close-button"
            onClick={() => onClose(false)}
            style={{border: 'none', boxShadow: 'none', justifyContent: 'center'}}
            icon={<Close color="primary" />}
          >
            <Typography variant="body2">{t('orderIntake.close')}</Typography>
          </ActionButton>

          <Button
            data-test-id="persisted-materials-dialog-continue-button"
            color="primary"
            disabled={!existsValidMaterials}
            onClick={() => {
              onClose(true)
            }}
          >
            {t('orderIntake.materialPersistency.continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
