import {DeliveryTime, HaulerInfo, OrderIntakeMaterialOptionPayload} from '../../../../OrderIntake/declarations/types'

export enum MaterialEditorTabs {
  Date = 'Date',
  Haulier = 'Haulier',
  Po = 'Po',
  Details = 'Details'
}

export type MaterialOrderForm = {
  dateTime: {
    deliveryDate: string
    deliveryTime: DeliveryTime
  }
  haulerInfo: HaulerInfo | undefined
  details: {
    quantity: number
    isTimeForOrderOver: boolean
    selectedMaterialOption: OrderIntakeMaterialOptionPayload
    driverInstructions: string
    customerReference: string
  }
}

export type MaterialOrderLegacyForm = {
  dateTime: {
    deliveryDate: string
    deliveryTime: DeliveryTime
  }
  haulerInfo: HaulerInfo | undefined
  customerReference: string
  details: {
    quantity: number
    isTimeForOrderOver: boolean
    selectedMaterialOption: OrderIntakeMaterialOptionPayload
    driverInstructions: string
  }
}
