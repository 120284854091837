import {useTranslation} from '@hconnect/uikit'
import {useIsMobile} from '@hconnect/uikit/src/lib2'
import {Box, Grid, Typography} from '@material-ui/core'
import {sub as subtractDuration, add as addDuration, isBefore, isAfter, closestTo} from 'date-fns'
import {format as formatDate} from 'date-fns/fp'
import {identity} from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'

import {
  DeliveryTime,
  OptionSelectInputChangeType,
  TimePickerType,
  TimeSelectorTrackingEventType
} from '../../../OrderIntake/declarations/types'
import {formatOutputTime, getTimeSlots, setTime} from '../../../util/time'
import {isTwelveHoursFormat, timeFormatter} from '../TimeScroller/TimeScroller.utils'

import {OptionSelectInput} from './TimeSelectInput'

type Props = {
  value: DeliveryTime
  interval: Interval
  minDuration: Duration
  onChange: (value: DeliveryTime) => void
  onError: (isError: boolean) => void
  timeChangeInterval: number
  filterEarliest?: (d: Date, index: number, dates: Date[]) => boolean
  filterLatest?: (d: Date, index: number, dates: Date[]) => boolean
  filterTimeSlots?: (d: Date, index: number, dates: Date[]) => boolean
  onTimeSelectorTrack?: (eventType: TimeSelectorTrackingEventType) => void
  showError: boolean
  highlightEarliest: boolean
  highlightLatest: boolean
  'data-test-id'?: string
}

export const TimeRangePicker: React.FC<Props> = ({
  interval,
  value,
  minDuration,
  onChange,
  onError,
  filterTimeSlots,
  filterEarliest,
  filterLatest,
  timeChangeInterval,
  onTimeSelectorTrack,
  highlightEarliest,
  highlightLatest,
  showError,
  'data-test-id': dataTestId = 'timeRangePicker'
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const isTwelveHours = isTwelveHoursFormat(language)

  const [showErrorEarliest, setShowErrorEarliest] = useState(showError)
  const [showErrorLatest, setShowErrorLatest] = useState(showError)

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setShowErrorEarliest(showError)
    setShowErrorLatest(showError)
  }, [showError])

  useEffect(() => {
    onError(errorMessage ? true : false)
  }, [errorMessage])

  const earliest = setTime(interval.start, value.earliest)
  const latest = setTime(interval.start, value.latest)

  const options = getTimeSlots(interval, timeChangeInterval)
  const formatTime = formatDate('HH:mm')

  const earliestPossibleDates = options
    .filter((d) => !isAfter(d, subtractDuration(interval.end, minDuration)))
    .filter(filterTimeSlots ?? identity)
    .filter(filterEarliest ?? identity)

  const latestPossibleDates = options
    .filter((d) => !isBefore(d, addDuration(interval.start, minDuration)))
    .filter((d) => !isBefore(d, addDuration(earliest, minDuration)))
    .filter(filterTimeSlots ?? identity)
    .filter(filterLatest ?? identity)

  const onChangeEarliest = (newValue: string) => {
    const newTime = setTime(earliest, newValue)

    if (newTime >= interval.end) {
      setShowErrorEarliest(true)
      setErrorMessage(
        t('orderIntake.timePicker.maximumLatest', {
          time: timeFormatter(moment(interval.end, 'HH:mm'), language)
        })
      )
      return
    }

    const earliestFormatted = formatOutputTime(newTime)
    if (earliestFormatted === value.earliest) {
      validateTime(newTime, latest, TimePickerType.Earliest)
      return
    }

    const latestMinPossible = addDuration(newTime, minDuration)
    const latestTime = isBefore(latest, latestMinPossible) ? latestMinPossible : latest
    onChange({
      earliest: formatOutputTime(newTime),
      latest: formatOutputTime(latestTime)
    })

    validateTime(newTime, latestTime, TimePickerType.Earliest)
  }

  const onChangeLatest = (newValue: string) => {
    const newTime = setTime(earliest, newValue)

    if (newTime < interval.start) {
      setShowErrorLatest(true)
      setErrorMessage(
        t('orderIntake.timePicker.minimumEarliest', {
          time: timeFormatter(moment(interval.start, 'HH:mm'), language)
        })
      )
      return
    }

    const latestFormatted = formatOutputTime(newTime)
    if (latestFormatted === value.latest) {
      validateTime(earliest, newTime, TimePickerType.Latest)
      return
    }

    onChange({
      earliest: value.earliest,
      latest: latestFormatted
    })

    validateTime(earliest, newTime, TimePickerType.Latest)
  }

  const validateTime = (earliestTime: Date, latestTime: Date, sender: TimePickerType) => {
    let latestError = false
    let earliestError = false
    let errorMessage = ''

    const latestMinPossible = addDuration(earliestTime, minDuration)

    if (latestTime < latestMinPossible) {
      errorMessage = t('orderIntake.timePicker.earliestMustPrecedeLatest', {
        duration: formattedDuration
      })

      if (sender === TimePickerType.Earliest) {
        earliestError = true
      }

      if (sender === TimePickerType.Latest) {
        latestError = true
      }
    }

    if (earliestTime < interval.start) {
      errorMessage = t('orderIntake.timePicker.minimumEarliest', {
        time: timeFormatter(moment(interval.start, 'HH:mm'), language)
      })
      earliestError = true
    }

    if (latestTime > interval.end) {
      latestError = true

      errorMessage = t('orderIntake.timePicker.maximumLatest', {
        time: timeFormatter(moment(interval.end, 'HH:mm'), language)
      })
    }

    setShowErrorEarliest(showError || earliestError)
    setShowErrorLatest(showError || latestError)
    setErrorMessage(errorMessage)
  }

  const formattedDuration = minDuration.hours?.toString() + ':' + minDuration.minutes?.toString()

  const transformOption = (date: Date, possibleDates: Date[]) => (timeValue: string) => {
    // we need to change value, when user writes random value
    const newTime = setTime(date, timeValue)
    const closestTime = closestTo(newTime, possibleDates)
    return formatTime(closestTime ?? date)
  }

  const getEventType = (changeType: OptionSelectInputChangeType, inputType: string) => {
    switch (changeType) {
      case 'decrease':
        return '<' + inputType
      case 'increase':
        return '>' + inputType
      case 'inputField':
        return inputType + 'InputField'
    }
  }

  const earliestTrackChange = (changeType: OptionSelectInputChangeType) => {
    const eventType = getEventType(changeType, 'earliest')
    onTimeSelectorTrack && onTimeSelectorTrack(eventType as TimeSelectorTrackingEventType)
  }

  const latestTrackChange = (changeType: OptionSelectInputChangeType) => {
    const eventType = getEventType(changeType, 'latest')
    onTimeSelectorTrack && onTimeSelectorTrack(eventType as TimeSelectorTrackingEventType)
  }

  const isMobile = useIsMobile()
  return (
    <Grid container spacing={isMobile ? 1 : 3} direction="row" alignItems="stretch">
      <Grid item xs={12} lg={isTwelveHours ? 12 : 6} style={{minWidth: '150px'}}>
        <OptionSelectInput
          data-test-id={`${dataTestId}-earliest`}
          title={t('orderIntake.earliest')}
          name="earliest"
          value={formatTime(earliest)}
          options={earliestPossibleDates.map(formatTime)}
          transformOption={transformOption(earliest, earliestPossibleDates)}
          onTrackChange={earliestTrackChange}
          onChange={onChangeEarliest}
          showError={showErrorEarliest}
          highlight={highlightEarliest}
          isTwelveHours={isTwelveHours}
        />
      </Grid>
      <Grid item xs={12} lg={isTwelveHours ? 12 : 6} style={{minWidth: '150px'}}>
        <OptionSelectInput
          data-test-id={`${dataTestId}-latest`}
          title={t('orderIntake.latest')}
          name="latest"
          value={formatTime(latest)}
          options={latestPossibleDates.map(formatTime)}
          transformOption={transformOption(latest, latestPossibleDates)}
          onChange={onChangeLatest}
          onTrackChange={latestTrackChange}
          showError={showErrorLatest}
          highlight={highlightLatest}
          isTwelveHours={isTwelveHours}
          getIncrementState={(value, incrementState) => {
            // in current real data, this is sufficient, if there will be change, we will need to improve heuristics
            if (value !== '23:59') return incrementState
            return {
              ...incrementState,
              nextIndex: undefined,
              previousIndex: latestPossibleDates.length - 1
            }
          }}
        />
      </Grid>

      {(showErrorEarliest || showErrorLatest) && (
        <Box paddingLeft={2} paddingRight={2}>
          <Typography variant="caption" color="error" data-test-id={`${dataTestId}-error-message`}>
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Grid>
  )
}
