import {Typography, dateFormatter, timeFormatter, Grid, ActionButton} from '@hconnect/uikit'
import {Box, Dialog, DialogTitle} from '@material-ui/core'
import {some} from 'lodash'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OrderRequestStatus} from '../../declarations/OrderIntake.enums'
import {
  DeliveryTime,
  HaulerInfo,
  Plant,
  QuantityType,
  ShippingAddress as ShippingAddressType
} from '../../declarations/types'
import {OrderStatus} from '../OrderStatus'

import {HaulerInfoSummaryItem} from './HaulerInfoSummaryItem'

interface CollectOrderSummaryItemProps {
  itemIndex: string
  plant: Plant
  shippingAddress: ShippingAddressType
  orderRequestStatus: OrderRequestStatus
  quantity: number
  quantityType: QuantityType
  quantityUom: string
  collectionDate: string
  collectionTime: DeliveryTime
  materialDescription: string
  haulerInfo?: HaulerInfo
}

export const CollectOrderSummaryLegacyItem: React.FC<CollectOrderSummaryItemProps> = ({
  itemIndex,
  plant,
  shippingAddress,
  orderRequestStatus,
  quantity,
  quantityType,
  quantityUom,
  collectionDate,
  collectionTime,
  materialDescription,
  haulerInfo
}) => {
  const [showHaulerInfo, setShowHaulerInfo] = React.useState(false)
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const handleShowHaulerInfo = () => {
    setShowHaulerInfo(true)
  }

  const handleHideHaulerInfo = () => {
    setShowHaulerInfo(false)
  }

  return (
    <Box data-test-id="collect-order-summary-item">
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="h4">{itemIndex}</Typography>
        <OrderStatus status={orderRequestStatus} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="caption" color="secondary">
            {t('orderIntake.confirmationScreen.typeCollectTime')}
          </Typography>
          <Typography variant="h4">{materialDescription}</Typography>
          {quantityType !== QuantityType.Load && (
            <Typography variant="body2">1 {t('orderIntake.truck')}</Typography>
          )}
          <Typography variant="body2">
            {quantity}
            {quantityType === QuantityType.Load
              ? ` ${t('orderIntake.loads', {count: quantity})} `
              : ` ${quantityUom}`}
          </Typography>
          <Typography variant="h4">{dateFormatter(collectionDate, language)}</Typography>
          <Typography variant="body2">{`${timeFormatter(
            moment(collectionTime.earliest, 'HH:mm:ss'),
            language
          )} -
              ${timeFormatter(moment(collectionTime.latest, 'HH:mm:ss'), language)}`}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="caption" color="secondary">
            {t('orderIntake.deliveryAddress')}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="100%"
            data-test-id="shipping-address"
          >
            <Typography variant="h4" breakWords>
              {shippingAddress.siteName}
            </Typography>
            <Typography variant="h4" breakWords>
              {shippingAddress.street}
            </Typography>
            <Typography variant="h4" breakWords>
              {shippingAddress.city}, {shippingAddress.postalCode}
            </Typography>
          </Box>
          {haulerInfo && some(Object.values(haulerInfo)) ? (
            <Box mt={2}>
              <ActionButton onClick={handleShowHaulerInfo} data-test-id="confirmation-hauler-btn">
                {t('orderIntake.confirmationScreen.showHaulerInfo')}
              </ActionButton>
              <Dialog onClose={handleHideHaulerInfo} open={showHaulerInfo}>
                <div data-test-id="confirmation-hauler-dialog">
                  <DialogTitle>{t('orderIntake.haulerInfo.hauler')}</DialogTitle>
                  <Box paddingLeft={4} paddingRight={4} paddingBottom={2}>
                    <HaulerInfoSummaryItem haulerInfo={haulerInfo} />
                  </Box>
                </div>
              </Dialog>
            </Box>
          ) : null}
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="caption" color="secondary">
            {t('orderIntake.collectionAt')}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="100%"
            data-test-id="shipping-address"
          >
            <Typography variant="h4" breakWords>
              {plant.plantName}
            </Typography>
            <Typography variant="h4" breakWords>
              {plant.street}
            </Typography>
            <Typography variant="h4" breakWords>
              {plant.city}, {plant.postalCode}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
