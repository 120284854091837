import {useCallback, useEffect, useState} from 'react'

import {Dictionary} from '../../../common/types'
import {MaterialFormStatus} from '../../declarations/OrderIntake.enums'
import {ActiveMaterialDeliveryItem, ActiveMaterialEditorMode} from '../../declarations/types'

// TODO: unit test
export const getOrderBoxState = (
  currentMaterialForm: string,
  activeMaterialForm: string | undefined,
  successStatuses: Dictionary<number | undefined>
) => {
  let status = MaterialFormStatus.DISABLED
  if (activeMaterialForm === currentMaterialForm) status = MaterialFormStatus.ACTIVE
  if (activeMaterialForm === undefined) status = MaterialFormStatus.DEFAULT

  return {status, isSuccess: successStatuses[currentMaterialForm] !== undefined}
}

export const useMaterialEditorController = () => {
  const [activeMaterialForm, activateMaterialForm] = useState<string | undefined>()
  const [hasActiveMaterialFormChanges, setHasActiveMaterialFormChanges] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<ActiveMaterialDeliveryItem | undefined>()
  const [activeMode, setActiveMode] = useState<ActiveMaterialEditorMode | undefined>('edit')
  const [timeoutIndices, setTimeoutIndices] = useState<Dictionary<number | undefined>>({})
  useEffect(() => {
    return () => Object.values(timeoutIndices).forEach(clearTimeout)
  }, [timeoutIndices])

  const notifySuccessfullySaved = (boxIndex: string) => {
    const currentIndex = timeoutIndices[boxIndex]
    clearTimeout(currentIndex)
    const newTimeoutIndex = window.setTimeout(() => {
      setTimeoutIndices({...timeoutIndices, [boxIndex]: undefined})
      // 2 seconds, may be configurable in future?
    }, 2 * 1000)
    setTimeoutIndices({...timeoutIndices, [boxIndex]: newTimeoutIndex})
  }
  return {
    setActiveOrderBox: (activeFormId?: string, activeSection?: ActiveMaterialDeliveryItem, activeMode?: ActiveMaterialEditorMode) => {
      setActiveSection(activeSection)
      activateMaterialForm(activeFormId)
      setActiveMode(activeMode)
      setHasActiveMaterialFormChanges(false)
    },
    setChanges: (hasChanges:boolean) => {
      setHasActiveMaterialFormChanges(hasChanges)
    },
    notifySuccessfullySaved,
    activeMaterialForm,
    activeSection,
    activeMode,
    hasActiveMaterialFormChanges,
    getMaterialFormProps: useCallback(
      (currentOrderBox: string) =>
        getOrderBoxState(currentOrderBox, activeMaterialForm, timeoutIndices),
      [activeMaterialForm, timeoutIndices]
    )
  }
}
